import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IMAGE_URL, NO_STORE_IMAGE } from '../../constants/Images';
import "./Logo.css";

function Logo({ color }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;
    const storeUrl = site?.store_details?.url;
    const isLoading = storeSelector.appLoading;

    return (
        <>
            <div className='logo'>
                <Link to={`/${storeUrl}`} style={{
                    color: color
                }}>
                    <Box className="site-logo-wrap">
                        <Box className="site-logo">
                            {isLoading ? (
                                <Skeleton height={80} width={50} style={{
                                    marginTop: '-10px'
                                }}>
                                </Skeleton>
                            ) : (
                                <Avatar
                                    variant="square"
                                    alt={site?.store_details?.store_name}
                                    src={site?.store_details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${site?.store_details.logo}` : NO_STORE_IMAGE}
                                    style={{
                                        height: 56,
                                        width: 60,
                                        borderRadius: 10
                                    }}
                                />
                            )}
                        </Box>
                        <Box className="site-name">
                            {isLoading && (
                                <Skeleton width={120} height={40}></Skeleton>
                            )}
                            <Typography>{site?.store_details?.store_name}</Typography>
                        </Box>
                    </Box>
                </Link>
            </div>
        </>
    );
}

export default Logo;