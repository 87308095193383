import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import Iconify from '../../compoments/iconify/Iconify';

function ItemTableDataV1({ variant, allUnits, index, handleFormValueChange }) {
    return (
        <>
            <Box>
                <Box className='item-data-v1'>
                    <TextField
                        fullWidth
                        value={variant?.original_price}
                        autoComplete='off'
                        type='number'
                        name="original_price"
                        label="Price *"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                    <TextField
                        fullWidth
                        value={variant?.discounted_price}
                        autoComplete='off'
                        type='number'
                        name="discounted_price"
                        label="Discounted Price"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className='item-data-v1'>
                    <TextField
                        fullWidth
                        value={variant?.sku_code}
                        autoComplete='off'
                        type='text'
                        name="sku_code"
                        label="SKU ID"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                    <TextField
                        fullWidth
                        value={variant?.combo_quantity}
                        autoComplete='off'
                        type='number'
                        name="combo_quantity"
                        label="Quantity"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className='item-data-v1'>
                    <TextField
                        className='item-unit-txtfield'
                        value={variant?.unit_value}
                        fullWidth
                        autoComplete='off'
                        type='number'
                        name="unit_value"
                        label="Item Unit *"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                    <Autocomplete
                        fullWidth
                        value={variant?.selected_unit}
                        options={allUnits}
                        getOptionLabel={(option) => option.name ? option.name : ''}
                        renderInput={(params) => <TextField {...params} label="Unit *" />}
                        onChange={(event, newInputValue) => handleFormValueChange(event, index, newInputValue)}
                    />
                </Box>
            </Box>
        </>
    );
}

export default ItemTableDataV1;