import { IconButton, MenuItem, Popover } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGING_DIALOG } from '../../../reduxStore/actions/site/AuthTypes';
import { flagReview } from '../../../reduxStore/actions/site/StoreActions';
import Iconify from '../iconify/Iconify';

function ReviewPopover({ review }) {
    const authSelector = useSelector((state) => state.sAuth);
    const accessToken = authSelector?.userDetails?.access_token;
    const storeSelector = useSelector((state) => state.sStore);
    const configs = storeSelector.configs;
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const flagInap = async () => {
        if (accessToken) {
            try {
                setIsLoading(true);
                const obj = JSON.stringify({
                    store_id: storeId,
                    uid: review?.uid
                });
                const res = await dispatch(flagReview(obj));
                setIsLoading(false);
                enqueueSnackbar(res.data.message, { variant: 'success' });
            } catch (err) {
                setIsLoading(false);
                if (err?.errors) {
                    enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
                }
            }

        } else {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: true
            });
        }
    };


    return (
        <>
            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); flagInap() }}>
                    <Iconify icon={'ic:baseline-outlined-flag'} sx={{ mr: 1 }} />
                    Flag inappropriate
                </MenuItem>
            </Popover>
        </>
    );
}

export default ReviewPopover;