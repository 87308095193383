import { Box, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ABOUT } from '../../constants/Site';
import { getWebConfig } from '../../reduxStore/actions/StoreActions';
import "./AboutUs.css";

function AboutUs(props) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const storeSelector = useSelector((state) => state.store);
    const configData = storeSelector.siteConfig ? storeSelector.siteConfig : [];
    const [about, setAbout] = useState("");

    const getAllConfig = async () => {
        try {
            const obj = { store_id: 0 }
            const res = await dispatch(getWebConfig(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        getAllConfig();
    }, []);

    useEffect(() => {
        if (configData.length > 0) {
            configData?.forEach(element => {
                if (element.key_name === ABOUT) {
                    setAbout(element);
                }
            });
        }
    }, [configData]);

    return (
        <>
            <Helmet>
                <title>About Us | TrueMenu</title>
            </Helmet>
            <Box>
                <Box className='about-banner'>
                    <Box className='about-overlay'>
                    </Box>
                    <Typography className='about-banner-text'>
                        About Us
                    </Typography>
                </Box>

                <Container>
                    <Box className='about-txt'>
                        <Box>
                            <Box dangerouslySetInnerHTML={{ __html: about?.value_data }} />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default AboutUs;