import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { account, storeAccount } from '../../_mock/account';
import useResponsive from '../../hooks/useResponsive';
//import Logo from '../logo';
import "./Nav.css";
import Scrollbar from '../scrollbar';
import NavSection from '../nav-section';
import { useSelector } from 'react-redux';
import config from './config';
import { IMAGE_URL } from '../../../constants/Images';
import { SITE_CONTACT, WHATSAPP_URL } from '../../../constants/Site';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
    color: 'rgb(0, 171, 85)',
    backgroundColor: 'rgba(0, 171, 85, 0.08)',
}));

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const auth = useSelector((state) => state.auth);
    const userRole = auth?.userDetails?.user_role_code;
    const storeSelector = useSelector((state) => state.store);
    const storeDetails = storeSelector?.details;
    const storeId = storeSelector?.details?.uid;
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'lg');
    const navConfig = config(userRole);

    const sayHi = () => {
        window.open(`${WHATSAPP_URL}?phone=${SITE_CONTACT}&text=Hi`, '_blank');
    }

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{
                mt: 1.5, mb: 5, mx: 2.5
            }}>
                {userRole === 'owner' &&
                    <Link underline="none">
                        <StyledAccount>
                            <Avatar variant='square' sx={{
                                borderRadius: 1
                            }}
                                src={storeDetails?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${storeDetails?.logo}` : ''}
                                alt={storeDetails?.store_name} />

                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {storeDetails?.store_name}
                                </Typography>

                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>

                                </Typography>
                            </Box>
                        </StyledAccount>
                    </Link>
                }
            </Box>

            <NavSection data={navConfig} />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                    <Box
                        component="img"
                        src="/assets/images/illustration_docs.svg"
                        sx={{ width: 100, position: 'absolute', top: -50 }}
                    />

                    <Box sx={{ textAlign: 'center' }}>
                        <Stack direction='row' spacing={1} sx={{
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Powered by
                            </Typography>
                            <Typography gutterBottom variant="h6">
                                TrueMenu
                            </Typography>
                        </Stack>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Need help?
                        </Typography>
                    </Box>

                    <Button onClick={sayHi} target="_blank" variant="contained" style={{
                        backgroundColor: 'rgb(0, 171, 85)'
                    }}>
                        Say, Hi
                    </Button>
                </Stack>
            </Box>
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'white',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
