import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppButton from '../../../components/app-button/AppButton';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import { getAllPosts, postAction, postDelete } from '../../../reduxStore/actions/StoreActions';
import AppTable from '../../compoments/app-table/AppTable';
import Iconify from '../../compoments/iconify/Iconify';

function Posts(props) {
    const tableHead = [
        { id: 'title', label: 'Title', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'createdAt', label: 'Created At', alignRight: false },
        { id: '' },
    ];
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const storeSelector = useSelector((state) => state.store);
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const tableRows = storeSelector.posts;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const getPosts = async () => {
        try {
            const res = await dispatch(getAllPosts(storeId));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const toggleStatus = async (event, row) => {
        const obj = {
            store_id: storeId,
            uid: row.uid,
            record_status: event.target.value === 'A' ? 'D' : 'A'
        }
        try {
            const res = await dispatch(postAction(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const editActionClick = async (event, row) => {
        navigate(`/portal/store/posts/edit/${row.uid}`, { state: row });
    }

    const deleteActionClick = async (event, row) => {
        setIsDeleteDialogOpen(true);
        setSelectedRecord(row);
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = {
            store_id: storeId,
            uid: selectedRecord.uid,
            record_status: 'R'
        }
        try {
            const res = await dispatch(postDelete(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }

    }
    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <>
            <Helmet>
                <title> Posts | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Posts
                    </Typography>
                    <Link to='/portal/store/posts/add' style={{ textDecoration: 'none' }}>
                        <AppButton variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            New Post
                        </AppButton>
                    </Link>
                </Stack>
                <Box>
                    <AppTable
                        tableHead={tableHead}
                        tableRows={tableRows}
                        listType='posts'
                        searchPlaceholder="Search Post ..."
                        toggleSwitchStatus={toggleStatus}
                        editActionClick={editActionClick}
                        deleteActionClick={deleteActionClick}
                        storeId={storeId}
                        searchField="title"
                    />
                </Box>
                {isDeleteDialogOpen &&
                    <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_post_dialog'} title={<>Delete Post</>}
                        footer={
                            <>
                                <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                    Delete
                                </Button>
                                <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                    Cancel
                                </Button>
                            </>}
                    >
                        <Typography style={{
                            fontSize: 14,
                            color: '#000'
                        }}>Are you sure want to delete record?</Typography>
                    </AppDialogDelete>
                }
            </Container>
        </>
    );
}

export default Posts;