import React, { useRef, useState, useEffect } from 'react';
import {
    Card,
    Box,
    Stack,
    Container,
    Typography,
    IconButton,
    TextField,
    Button
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import "./Categories.css";
import AppButton from '../../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addCategory, updateCategory } from '../../../reduxStore/actions/StoreActions';
import { IMAGE_URL } from '../../../constants/Images';
import { Helmet } from 'react-helmet';

function AddCategory() {
    const inputFile = useRef(null);
    const [file, setFile] = useState('');
    const [fileData, setFileData] = useState('');
    const [fileStatus, setFileStatus] = useState('');
    const [uid, setUid] = useState('');
    const [row, setRow] = useState({});
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { state } = useLocation();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;

    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileData(e.target.files[0]);
        setFileStatus('removed');
    }
    const handleFileRemove = () => {
        setFile('');
        setFileData('');
        inputFile.current.value = "";
        setFileStatus('removed');
    }
    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('user_file', fileData);
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                name: name,
                description: desc
            }));
            const res = await dispatch(addCategory(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/categories');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            if (fileData) {
                formData.append('user_file', fileData);
            }
            formData.append('form_data', JSON.stringify({
                file_status: fileStatus ? fileStatus : 'no_changes',
                uid: uid,
                store_id: storeId,
                name: name,
                description: desc
            }));
            const res = await dispatch(updateCategory(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/categories');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        setName(state?.name);
        setDesc(state?.description);
        setFile(state?.image_url);
        setUid(state?.uid);
        setRow(state);
    }, []);

    return (
        <>
            <Helmet>
                <title> Add Category | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        <Link style={{ textDecoration: 'none' }} to="/portal/store/categories">
                            <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                        </Link>  {uid ? "Edit Category" : "Add Category"}
                    </Typography>
                </Stack>

                <Box className='category-add-card'>
                    <Card className='category-add-card-wrap'>
                        <input onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Category Image
                        </Typography>
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box className='c-image-wrap'>
                                    {!file
                                        ? <Link className='c-image' onClick={handleFileClick}>
                                            <Iconify sx={{
                                                width: 40
                                            }} icon="mdi:camera-plus" />
                                            <Typography style={{
                                                fontSize: 14
                                            }}>
                                                Upload image
                                            </Typography>
                                        </Link>
                                        :
                                        <>
                                            <img src={row?.image_url ? `${IMAGE_URL}itemcategory/thumbnail?id=${storeId}&img_id=${row.image_url}` : file}
                                                alt="img"
                                                style={{
                                                    height: "100%",
                                                    width: "100%"
                                                }} />
                                            <IconButton onClick={handleFileRemove} className='close-image-ico'><Iconify width={15} icon="material-symbols:close" /></IconButton>
                                        </>
                                    }
                                </Box>
                                <Typography className='c-image-caption'>
                                    Allowed *.jpeg, *.jpg, *.png
                                    max size of 1 MB
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card className='category-add-card-wrap'>
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Category Info
                        </Typography>
                        <Stack spacing={3}>
                            <TextField
                                name="category_name"
                                label="Category Name *"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                            <TextField
                                name="category_desc"
                                label="Category Description (Optional)"
                                value={desc}
                                multiline rows={2}
                                onChange={(event) => setDesc(event.target.value)}
                            />
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={uid ? handleUpdateClick : handleSaveClick}
                                fullWidth size='large'
                                variant="contained">
                                {uid ? "Update" : "Save"}
                            </AppButton>
                        </Stack>
                    </Card>
                </Box>
            </Container >

        </>
    );
}

export default AddCategory;