import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Stack,
    Button,
    Container,
    Typography,
    Box
} from '@mui/material';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useNavigate } from 'react-router-dom';
import AppTable from '../../compoments/app-table/AppTable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { categoryAction, categoryDelete, getAllCategories } from '../../../reduxStore/actions/StoreActions';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import AppButton from '../../../components/app-button/AppButton';
import { Helmet } from 'react-helmet';

function Categories(props) {

    const tableHead = [
        { id: 'name', label: 'Category Name', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'createdAt', label: 'Created At', alignRight: false },
        { id: '' },
    ];

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const storeSelector = useSelector((state) => state.store);
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const tableRows = storeSelector.categories ? storeSelector.categories : [];
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const getCategories = async () => {
        try {
            const res = await dispatch(getAllCategories(storeId));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const toggleStatus = async (event, row) => {
        const obj = {
            store_id: storeId,
            uid: row.uid,
            record_status: event.target.value === 'A' ? 'D' : 'A'
        }
        try {
            const res = await dispatch(categoryAction(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const editActionClick = async (event, row) => {
        navigate(`/portal/store/categories/edit/${row.uid}`, { state: row });
    }
    const deleteActionClick = async (event, row) => {
        setIsDeleteDialogOpen(true);
        setSelectedRecord(row);
    }
    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = {
            store_id: storeId,
            uid: selectedRecord.uid,
            record_status: 'R'
        }
        try {
            const res = await dispatch(categoryDelete(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }

    }
    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            <Helmet>
                <title> Categories | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Categories
                    </Typography>
                    <Link to='/portal/store/categories/add' style={{ textDecoration: 'none' }}>
                        <AppButton variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            New Category
                        </AppButton>
                    </Link>
                </Stack>
                <Box>
                    <AppTable
                        tableHead={tableHead}
                        tableRows={tableRows}
                        listType='categories'
                        searchPlaceholder="Search Category ..."
                        toggleSwitchStatus={toggleStatus}
                        editActionClick={editActionClick}
                        deleteActionClick={deleteActionClick}
                        storeId={storeId}
                    />
                </Box>

            </Container>
            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_category_dialog'} title={<>Delete Category</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }
        </>
    );
}

export default Categories;