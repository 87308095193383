import { Box, Button, Typography, Container } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Iconify from '../../portal/compoments/iconify/Iconify';
import TypeWriterEffect from 'react-typewriter-effect';
import "./Home.css";
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function Home(props) {
    const auth = useSelector((state) => state.auth);
    const accessToken = auth?.userDetails?.access_token;
    return (
        <>
            <Helmet>
                <title>TrueMenu : QR for every business</title>
            </Helmet>
            <Box className='home-page'>
                <Container>
                    <Box className='h-banner-wrap'>
                        <Box>
                            <Typography className='h-banner-title'>
                                Create your online store & get a QR Code for it.
                            </Typography>
                            <Box>
                                <TypeWriterEffect
                                    textStyle={{
                                        fontFamily: 'Inter',
                                        fontWeight: 700,
                                        fontSize: 55,
                                        color: '#00ab55'
                                    }}
                                    startDelay={100}
                                    cursorColor="#00ab55"
                                    multiText={["1. Create a store ✔", "2. Get QR Code", "3. Scan QR Code 🔥"]}
                                    typeSpeed={50}
                                    multiTextLoop
                                />
                            </Box>
                            <Typography style={{
                                fontSize: 14
                            }}>
                                Scan store QR Code to see the store items and details. Manage your enquiries and bookings / orders at one place.
                            </Typography>
                            <Box className='h-banner-action-btns'>
                                <Box>
                                    <Link to="book-a-demo">
                                        <Button variant="contained" style={{
                                            backgroundColor: 'rgb(0, 171, 85)'
                                        }}>
                                            BOOK A DEMO
                                        </Button>
                                    </Link>
                                </Box>
                                <Box>
                                    {!accessToken &&
                                        <Link to="login">
                                            <Button variant="outlined" style={{
                                                backgroundColor: 'rgb(255, 255, 255)',
                                                color: 'rgb(0, 171, 85)',
                                                borderColor: 'rgb(0, 171, 85)'
                                            }}>
                                                Login
                                            </Button>
                                        </Link>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box className='h-header-banner'> </Box>
                        </Box>
                    </Box>
                </Container>
                <Box className='h-features-info'>
                    <Container>
                        <Box className='h-features-info-content'>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="material-symbols:local-convenience-store" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Create Store
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Create your e-commerce store page with one click, add your store details so that your customers can find you easily.
                                </Typography>
                            </Box>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="ic:round-publish" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Publish Store
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Publish your store and get QR Code for it.
                                </Typography>
                            </Box>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="fluent-mdl2:work-item" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Add Store Items
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Add your store items along with categories, so that your customers can find it easily.
                                </Typography>
                            </Box>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="mdi:coupon" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Add Store Offers
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Add any offers you are availing for your customers. Customers can easily see it on the store page.
                                </Typography>
                            </Box>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="material-symbols:payments-outline" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Add Payment Details
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Add your payment details securely, so your customers can pay you with one click, at the time of purchasing or booking items.
                                </Typography>
                            </Box>
                            <Box className='h-features-wrap'>
                                <Box className='h-features-ico-wrap'>
                                    <Iconify icon="material-symbols:qr-code" className='h-features-ico' />
                                </Box>
                                <Typography className='h-features-title'>
                                    Scan It
                                </Typography>
                                <Typography className='h-features-desc'>
                                    Share your QR Code or store link with customers so they can see your store and easily book or purchase items online.
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box style={{
                    textAlign: 'center'
                }}>
                    <Container>
                        <Typography style={{
                            color: 'rgb(245, 124, 0)',
                            fontSize: 16,
                            fontWeight: 700
                        }}>GET STARTED</Typography>
                        <Typography className='home-get-started-title'>
                            Create your store's digital <span>TrueMenu</span> in less than a minute
                        </Typography>
                        <Typography style={{
                            fontSize: 15,
                            marginBottom: 20
                        }}>Manage your orders or bookings efficiently.</Typography>
                        <Link to="/book-a-demo">
                            <Button variant="contained" endIcon={<Iconify icon="ic:outline-arrow-right-alt" />} style={{
                                backgroundColor: 'rgb(0, 171, 85)'
                            }}>
                                GENERATE QR FOR BUSINESS
                            </Button>
                        </Link>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default Home;