import { Box, Button, Card, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
    IoReaderOutline,
    IoCallOutline,
} from "react-icons/io5";
import { Editor } from '@tinymce/tinymce-react';
import AppButton from '../../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addConfig, getConfig, updateConfig } from '../../../reduxStore/actions/StoreActions';
import { TERMS, TINY_CLOUD_KEY } from '../../../constants/Site';
import Iconify from '../../compoments/iconify/Iconify';
import PrivacyConfig from './PrivacyConfig';
import AboutConfig from './AboutConfig';

function Config(props) {
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [terms, setTerms] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const authSelector = useSelector((state) => state.auth);
    const storeSelector = useSelector((state) => state.store);
    const configData = storeSelector.siteConfig ? storeSelector.siteConfig : [];
    const editorRef = useRef(null);

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let content = "";
            if (editorRef.current) {
                content = editorRef.current.getContent();
            }
            const obj = {
                store_id: 0,
                key: TERMS,
                value: content
            }
            const res = await dispatch(addConfig(obj));
            setIsLoading(false);
            getAllConfig();
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setIsEdit(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            let content = "";
            if (editorRef.current) {
                content = editorRef.current.getContent();
            }
            const obj = {
                uid: terms?.id,
                store_id: 0,
                key: TERMS,
                value: content
            }
            const res = await dispatch(updateConfig(obj));
            setIsLoading(false);
            getAllConfig();
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setIsEdit(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleEditClick = async () => {
        setIsEdit(true);
    }

    const getAllConfig = async () => {
        try {
            const obj = { store_id: 0 }
            const res = await dispatch(getConfig(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    useEffect(() => {
        getAllConfig();
    }, []);

    useEffect(() => {
        if (configData.length > 0) {
            configData?.forEach(element => {
                if (element.key_name === TERMS) {
                    setTerms(element);
                }
            });
        }
    }, [configData]);

    //For tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsEdit(false);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="tab-setting">
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    return (
        <>
            <Helmet>
                <title> Config | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Config
                    </Typography>
                </Stack>
                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                                value={value} onChange={handleChange}>
                                <Tab className="s-tabs" iconPosition="start" label="Terms" {...a11yProps(0)} />
                                <Tab className="s-tabs" iconPosition="start" label="Privacy" {...a11yProps(1)} />
                                <Tab className="s-tabs" iconPosition="start" label="About" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Card>
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Terms
                                        </Typography>
                                        {!isEdit &&
                                            <Button
                                                onClick={handleEditClick}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:edit-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Edit
                                            </Button>
                                        }
                                    </Stack>
                                    {!isEdit ?
                                        <Box>
                                            <Box dangerouslySetInnerHTML={{ __html: terms?.value_data }} />
                                        </Box>
                                        :
                                        <Box>
                                            <Editor
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                apiKey={TINY_CLOUD_KEY}
                                                initialValue={terms?.value_data}
                                                init={{
                                                    branding: false,
                                                    height: 300,

                                                    plugins: 'link image code lists table',
                                                    toolbar: 'backcolor forecolor | fontselect | lists formatselect | ' +
                                                        'bold italic | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent '
                                                }}
                                            />
                                            <Box style={{
                                                marginTop: 20
                                            }}>
                                                <AppButton
                                                    disabled={isLoading}
                                                    isLoading={isLoading}
                                                    onClick={terms?.value_data ? handleUpdateClick : handleSaveClick}
                                                    fullWidth size='large'
                                                    variant="contained">
                                                    {terms?.value_data ? "Update" : "Save"}
                                                </AppButton>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Card>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PrivacyConfig />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AboutConfig />
                        </TabPanel>
                    </Box>
                </Box>
            </Container>

        </>
    );
}

export default Config;