import React, { useState } from 'react';
import {
    Box,
    Card,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    Button,
    TextField,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreDetails, updateStoreByField } from '../../../reduxStore/actions/StoreActions';
import FaqList from '../../compoments/faq-list/FaqList';
import Iconify from '../../compoments/iconify/Iconify';
import AppButton from '../../../components/app-button/AppButton';
import AppDialog from '../../../components/app-dialog/AppDialog';
import { Link } from 'react-router-dom';
function SocialLinks(props) {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const [faqDialogOpen, setFaqDialogOpen] = useState(false);
    const details = storeSelector.details;
    const [isLoading, setIsLoading] = useState(false);
    const [instaLink, setInstaLink] = useState('');
    const [fbLink, setFbLink] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const [linkedInLink, setLinkedInLink] = useState('');
    const [open, setOpen] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [socialDialogOpen, setSocialDialogOpen] = useState(false);
    const allSocials = details?.social_media_links ? JSON.parse(details?.social_media_links) : {};

    console.log("allSocials", allSocials);

    function validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const handleDialogCancel = () => {
        setSocialDialogOpen(false);
    }

    const handleSocialAddClick = async () => {
        setSocialDialogOpen(true);
        setInstaLink(allSocials?.instagram);
        setFbLink(allSocials?.facebook);
        setTwitterLink(allSocials?.twitter);
        setLinkedInLink(allSocials?.linkedin);
    };

    const saveSocialClick = async () => {
        if (instaLink) {
            if (!validateUrl(instaLink)) {
                enqueueSnackbar('Instagram url is not valid.', { variant: 'error' });
                return;
            }
        }
        if (fbLink) {
            if (!validateUrl(fbLink)) {
                enqueueSnackbar('Facebook url is not valid.', { variant: 'error' });
                return;
            }
        }
        if (twitterLink) {
            if (!validateUrl(twitterLink)) {
                enqueueSnackbar('Twitter url is not valid.', { variant: 'error' });
                return;
            }
        }
        if (linkedInLink) {
            if (!validateUrl(linkedInLink)) {
                enqueueSnackbar('Linkedin url is not valid.', { variant: 'error' });
                return;
            }
        }
        const social = {};
        social['instagram'] = instaLink;
        social['facebook'] = fbLink;
        social['twitter'] = twitterLink;
        social['linkedin'] = linkedInLink;

        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'social',
                name: JSON.stringify(social)
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const dialogSocialFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={saveSocialClick}
                    variant="contained">
                    Save
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Card>
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Social Links
                        </Typography>
                        <Button
                            size="small"
                            variant="text"
                            startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}
                            onClick={handleSocialAddClick}
                        >
                            Edit
                        </Button>
                    </Stack>
                    <Box>
                        {true ?
                            <Box style={{
                                fontSize: 14
                            }}>
                                <Box style={{
                                    marginBottom: 2
                                }}>
                                    <span style={{
                                        color: 'rgb(99, 115, 129)',
                                        width: 80,
                                        display: 'inline-block'
                                    }}>Instagram: </span>
                                    <a
                                        style={{
                                            color: '#000',
                                            textDecoration: 'none',
                                            fontSize: 15
                                        }}
                                        rel="noopener noreferrer"
                                        href={allSocials?.instagram}
                                        target="_blank">
                                        {allSocials?.instagram}
                                    </a>
                                </Box>
                                <Box style={{
                                    marginBottom: 2
                                }}>
                                    <span style={{
                                        color: 'rgb(99, 115, 129)',
                                        width: 80,
                                        display: 'inline-block'
                                    }}>Facebook: </span>
                                    <a
                                        style={{
                                            color: '#000',
                                            textDecoration: 'none',
                                            fontSize: 15
                                        }}
                                        rel="noopener noreferrer"
                                        href={allSocials?.facebook}
                                        target="_blank">
                                        {allSocials?.facebook}
                                    </a>
                                </Box>
                                <Box style={{
                                    marginBottom: 2
                                }}>
                                    <span style={{
                                        color: 'rgb(99, 115, 129)',
                                        width: 80,
                                        display: 'inline-block'
                                    }}>Twitter: </span>
                                    <a
                                        style={{
                                            color: '#000',
                                            textDecoration: 'none',
                                            fontSize: 15
                                        }}
                                        rel="noopener noreferrer"
                                        href={allSocials?.twitter}
                                        target="_blank">
                                        {allSocials?.twitter}
                                    </a>
                                </Box>
                                <Box style={{
                                    marginBottom: 2
                                }}>
                                    <span style={{
                                        color: 'rgb(99, 115, 129)',
                                        width: 80,
                                        display: 'inline-block'
                                    }}>Linkedin: </span>
                                    <a
                                        style={{
                                            color: '#000',
                                            textDecoration: 'none',
                                            fontSize: 15
                                        }}
                                        rel="noopener noreferrer"
                                        href={allSocials?.linkedin}
                                        target="_blank">
                                        {allSocials?.linkedin}
                                    </a>
                                </Box>
                            </Box>
                            :
                            <Button size="small" variant="text" startIcon={<Iconify icon="eva:plus-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Social Links
                            </Button>
                        }
                    </Box>
                </Box>
            </Card>

            {socialDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={socialDialogOpen}
                    id={'social_dialog'}
                    title={<>{"Social Links"}</>}
                    footer={dialogSocialFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Instagram"
                                fullWidth
                                placeholder='Ex. https://www.instagram.com/my-page'
                                autoComplete='off'
                                name="insta_link"
                                value={instaLink}
                                onChange={(event) => setInstaLink(event.target.value)}
                            />
                            <TextField
                                label="Facebook"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="facebook_link"
                                value={fbLink}
                                onChange={(event) => setFbLink(event.target.value)}
                            />
                            <TextField
                                label="Twitter"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="twitter_link"
                                value={twitterLink}
                                onChange={(event) => setTwitterLink(event.target.value)}
                            />
                            <TextField
                                label="LinkedIn"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="linkedin_link"
                                value={linkedInLink}
                                onChange={(event) => setLinkedInLink(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default SocialLinks;