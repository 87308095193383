import React, { useState } from 'react';
import { Box, Typography, Container, Card, FormControl, TextField, Button } from '@mui/material';
import "./BookDemo.css";
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../components/app-button/AppButton';
import { bookDemo } from '../../reduxStore/actions/StoreActions';

function BookDemo(props) {

    const [businessName, setBusinessName] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_name: businessName,
                name: name,
                mobile_no: mobile,
                city: city,
                email: email
            });
            const res = await dispatch(bookDemo(obj));
            setIsLoading(false);
            clearFields();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const clearFields = () => {
        setBusinessName('');
        setName('');
        setMobile('');
        setCity('');
        setEmail('');
    }


    return (
        <>
            <Helmet>
                <title> Book A Demo | TrueMenu</title>
            </Helmet>
            <Box className='book-demo-page'>
                <Box className='book-demo-container'>
                    <Card>
                        <Box className='book-demo-form'>
                            <Typography style={{
                                fontSize: 15,
                                fontWeight: 600
                            }}>Book a Demo</Typography>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    name="business_name"
                                    label="Enter Business name / Store name *"
                                    value={businessName}
                                    onChange={(event) => setBusinessName(event.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Enter your full name *"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    name="mobile"
                                    label="Enter Mobile No *"
                                    value={mobile}
                                    onChange={(event) => setMobile(event.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Enter your Email (Optional)"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    name="city"
                                    label="Enter your City *"
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                />
                            </FormControl>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={handleSaveClick}
                                size='medium'
                                variant="contained">
                                Send
                            </AppButton>
                        </Box>
                    </Card>
                </Box>

            </Box>
        </>

    );
}

export default BookDemo;