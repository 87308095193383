import React from 'react';
import Rating from '@mui/material/Rating';
import "./Ratings.css";
import { useSelector } from 'react-redux';

function Ratings({ size = 'medium' }) {
    const storeSelector = useSelector((state) => state.store);
    const allReviews = storeSelector.reviews;

    return (
        <div>
            <div className='ratingWrap'>
                <div className='ratingBox'>
                    <div className='rateCount'>{allReviews?.rating ? allReviews?.rating : 0}</div>
                    <Rating size={size} name="read-only" precision={0.5} value={allReviews?.rating ? allReviews?.rating : 0} readOnly />
                    <div className='rateCaption'> {allReviews?.reviewCount ? allReviews?.reviewCount : 0} reviews</div>
                </div>
                <div className='reviewBox'>
                    <div className='reviewBar'>
                        <div className="reviewCount">5</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(allReviews?.count?.fiveRatingCount / allReviews?.reviewCount) * 100}%`
                                }}
                                title={allReviews?.count?.fiveRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">4</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(allReviews?.count?.fourRatingCount / allReviews?.reviewCount) * 100}%`
                                }}
                                title={allReviews?.count?.fourRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">3</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(allReviews?.count?.threeRatingCount / allReviews?.reviewCount) * 100}%`
                                }}
                                title={allReviews?.count?.threeRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">2</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(allReviews?.count?.twoRatingCount / allReviews?.reviewCount) * 100}%`
                                }}
                                title={allReviews?.count?.twoRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">1</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(allReviews?.count?.oneRatingCount / allReviews?.reviewCount) * 100}%`
                                }}
                                title={allReviews?.count?.oneRatingCount}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ratings;