import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AppButton from '../../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addConfig, getConfig, updateConfig } from '../../../reduxStore/actions/StoreActions';
import { PRIVACY, TINY_CLOUD_KEY } from '../../../constants/Site';
import Iconify from '../../compoments/iconify/Iconify';

function PrivacyConfig(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [privacy, setPrivacy] = useState("");
    const [isPrivacyEdit, setIsPrivacyEdit] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const authSelector = useSelector((state) => state.auth);
    const storeSelector = useSelector((state) => state.store);
    const configData = storeSelector.siteConfig ? storeSelector.siteConfig : [];
    const editorRef = useRef(null);

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let content = "";
            if (editorRef.current) {
                content = editorRef.current.getContent();
            }
            const obj = {
                store_id: 0,
                key: PRIVACY,
                value: content
            }
            const res = await dispatch(addConfig(obj));
            setIsLoading(false);
            getAllConfig();
            setIsPrivacyEdit(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            let content = "";
            if (editorRef.current) {
                content = editorRef.current.getContent();
            }
            const obj = {
                uid: privacy?.id,
                store_id: 0,
                key: PRIVACY,
                value: content
            }
            const res = await dispatch(updateConfig(obj));
            setIsLoading(false);
            getAllConfig();
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setIsPrivacyEdit(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handlePrivacyEditClick = async () => {
        setIsPrivacyEdit(true);
    }

    const getAllConfig = async () => {
        try {
            const obj = { store_id: 0 }
            const res = await dispatch(getConfig(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        if (configData.length > 0) {
            configData?.forEach(element => {
                if (element.key_name === PRIVACY) {
                    setPrivacy(element);
                }
            });
        }
    }, [configData]);

    return (
        <>
            <Card>
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Privacy
                        </Typography>
                        {!isPrivacyEdit &&
                            <Button
                                onClick={handlePrivacyEditClick}
                                size="small"
                                variant="text"
                                startIcon={<Iconify icon="eva:edit-fill" />}
                                style={{
                                    color: 'rgb(0, 171, 85)'
                                }}>
                                Edit
                            </Button>
                        }
                    </Stack>
                    {!isPrivacyEdit ?
                        <Box>
                            <Box dangerouslySetInnerHTML={{ __html: privacy?.value_data }} />
                        </Box>
                        :
                        <Box>
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey={TINY_CLOUD_KEY}
                                initialValue={privacy?.value_data}
                                init={{
                                    branding: false,
                                    height: 300,

                                    plugins: 'link image code lists table',
                                    toolbar: 'backcolor forecolor | fontselect | lists formatselect | ' +
                                        'bold italic | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent '
                                }}
                            />
                            <Box style={{
                                marginTop: 20
                            }}>
                                <AppButton
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    onClick={privacy?.value_data ? handleUpdateClick : handleSaveClick}
                                    fullWidth size='large'
                                    variant="contained">
                                    {privacy?.value_data ? "Update" : "Save"}
                                </AppButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </Card>
        </>
    );
}

export default PrivacyConfig;