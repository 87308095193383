import { Box, Card, Button, Typography, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import Qr from '../../../components/qr/Qr';
import domtoimage from 'dom-to-image';
import "./Settings.css";
import { useDispatch, useSelector } from 'react-redux';
import { SITE_NAME } from '../../../constants/Site';
import { IMAGE_URL, WEB_DOMAIN } from '../../../constants/Images';
import { Link } from 'react-router-dom';
import { CirclePicker, TwitterPicker } from 'react-color';
import AppButton from '../../../components/app-button/AppButton';
import Iconify from '../../compoments/iconify/Iconify';
import { bgcolor } from '@mui/system';
import { useSnackbar } from 'notistack';
import { getStoreDetails, updateStoreByField } from '../../../reduxStore/actions/StoreActions';

function QrConfig(props) {
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const storeDetails = storeSelector?.details;
    const url = `${SITE_NAME}${storeDetails?.url}`;
    const address = `${storeDetails?.address}, ${storeDetails?.area}, ${storeDetails?.city} ${storeDetails?.pincode}`;
    const allQrConfigs = storeDetails?.qr_code_setting ? JSON.parse(storeDetails?.qr_code_setting) : {};
    const [bgColor, setBgcolor] = useState('#F78DA7');
    const [color, setColor] = useState('#FFFFFF');
    const [headingTxt, setHeadingTxt] = useState('Scan QR code for menu');
    const [addressTxt, setAddressTxt] = useState(address);
    const [mobileTxt, setMobileTxt] = useState(`Call Now: ${storeDetails?.contact_no} `);
    const [isLoading, setIsLoading] = useState(false);
    const portal_link = storeDetails?.portal_link ? storeDetails?.portal_link : url;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();


    const twColors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#F78DA7", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000", "#ffffff"];

    const downloadQr = () => {
        domtoimage.toJpeg(document.getElementById('qr_card'),
            {
                quality: 1.0,
                height: 720,
                width: 480
            })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = `${storeDetails?.url}.jpeg`;
                link.href = dataUrl;
                link.click();
            });
    }

    const handleBgColorChange = (color) => {
        setBgcolor(color.hex);
    }
    const handleColorChange = (color) => {
        setColor(color.hex);
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const configs = {
                headingTxt: headingTxt,
                addressTxt: addressTxt,
                mobileTxt: mobileTxt,
                bgColor: bgColor,
                mobileTxt: mobileTxt
            }
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'qr_code_setting',
                name: JSON.stringify(configs),
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    React.useEffect(() => {
        if (Object.keys(allQrConfigs).length !== 0) {
            setBgcolor(allQrConfigs?.bgColor);
            setColor(allQrConfigs?.color);
            setHeadingTxt(allQrConfigs?.headingTxt);
            setAddressTxt(allQrConfigs?.addressTxt);
            setMobileTxt(allQrConfigs?.mobileTxt);
        }
    }, []);

    return (
        <>
            <Box className="qr-setting-wrap">
                <Box className="qr-setting-left">
                    <Card>
                        <Box className='qr-setting-left-wrap'>
                            <Typography style={{
                                fontSize: 14,
                                color: '#000',
                                marginBottom: 30
                            }}>
                                Configure QR Code
                            </Typography>
                            <Stack spacing={3}>
                                <TextField
                                    autoComplete='off'
                                    name="heading"
                                    label="Heading Text *"
                                    value={headingTxt}
                                    onChange={(event) => setHeadingTxt(event.target.value)}
                                />
                                <TextField
                                    name="address"
                                    label="Address Text (Optional)"
                                    multiline rows={2}
                                    value={addressTxt}
                                    onChange={(event) => setAddressTxt(event.target.value)}
                                />
                                <TextField
                                    autoComplete='off'
                                    name="mobile_no"
                                    label="Mobile Text (Optional)"
                                    value={mobileTxt}
                                    onChange={(event) => setMobileTxt(event.target.value)}
                                />
                                <Box>
                                    <Typography className='qr-setting-label-txt'>Background Color *</Typography>
                                    <TwitterPicker
                                        onChangeComplete={handleBgColorChange}
                                        width='320px'
                                        colors={twColors}
                                        color={bgColor} />
                                </Box>
                                <Box>
                                    <Typography className='qr-setting-label-txt'>Text Color *</Typography>
                                    <TwitterPicker
                                        onChangeComplete={handleColorChange}
                                        width='320px'
                                        colors={twColors}
                                        color={color} />
                                </Box>
                                <Box className='qr-settings-btn-wrap'>
                                    <AppButton
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                        fullWidth size='large'
                                        variant="contained"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </AppButton>
                                    <AppButton
                                        onClick={downloadQr}
                                        fullWidth size='large'
                                        variant="text"
                                        startIcon={<Iconify icon="ic:baseline-download" />}
                                    >
                                        Download
                                    </AppButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Card>
                </Box>
                <Box className="qr-setting-right">
                    <Card className='qr-setting-card'>
                        <Box className='qr-card-main-wrap'>
                            <Box id="qr_card" className='qr-card-prev' style={{
                                background: bgColor
                            }}>
                                <Box>
                                    <Box className='qr-setting-logo-wrap'>
                                        {storeDetails?.logo &&
                                            <Box className="qr-setting-logo">
                                                <img src={`${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${storeDetails.logo}`}
                                                    alt="img" />
                                            </Box>
                                        }
                                        <Box className="qr-setting-name-wrap">
                                            <Box className="qr-setting-name qr-txt-color" style={{
                                                color: color
                                            }}>
                                                {storeDetails?.store_name}
                                            </Box>
                                            <Box className="qr-setting-name-area qr-txt-color" style={{
                                                color: color
                                            }}>
                                                {storeDetails?.area}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className='qr-scan-for-menu qr-txt-color' style={{
                                        color: color
                                    }}>
                                        {headingTxt}
                                    </Box>
                                    <Box className='qr-setting-qr'>
                                        <Qr size={300} url={url} />
                                    </Box>
                                    {portal_link &&
                                        <Box className='website-qr qr-txt-color' style={{
                                            color: color
                                        }}>
                                            {portal_link}
                                        </Box>
                                    }
                                    <Box className='qr-setting-addr qr-txt-color' style={{
                                        color: color
                                    }}>
                                        {addressTxt}
                                    </Box>
                                    <Box className='qr-setting-mobile qr-txt-color' style={{
                                        color: color
                                    }}>
                                        {mobileTxt}
                                    </Box>
                                    <Box className='qr-setting-power'>
                                        <Typography className='qr-txt-color'
                                            style={{
                                                fontSize: 14,
                                                color: color
                                            }}><span style={{ fontSize: 13 }}>Powered by </span>
                                            <a href={WEB_DOMAIN} target='_blank'
                                                className='qr-txt-color'
                                                style={{
                                                    fontSize: 15,
                                                    fontWeight: 600,
                                                    color: color
                                                }}>
                                                <span>T</span>
                                                rue
                                                <span style={{
                                                    color: 'rgb(245, 124, 0)'
                                                }}>M</span>
                                                enu
                                            </a><sup>&reg;</sup> &copy; {(new Date().getFullYear())}.</Typography>
                                        <Typography style={{ fontSize: 13, color: color }} className='qr-txt-color'>{WEB_DOMAIN}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

export default QrConfig;