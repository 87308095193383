import React, { useRef, useState, useEffect } from 'react';
import {
    Card,
    Box,
    Stack,
    Container,
    Typography,
    IconButton,
    TextField,
    Button,
    Autocomplete,
    Chip,
    Divider,
    Popover
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./Items.css"
import AppButton from '../../../components/app-button/AppButton';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addItem, getAllBrands, getAllCategories, getAllUnits, updateItem, uploadItemImages } from '../../../reduxStore/actions/StoreActions';
import { IMAGE_URL } from '../../../constants/Images';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';
import AppDialog from '../../../components/app-dialog/AppDialog';
import { MuiChipsInput } from 'mui-chips-input'
import { SketchPicker } from 'react-color';
import ItemOptions from './ItemOptions';
import ItemTableMeta from './ItemTableMeta';
import ItemTableHeader from './ItemTableHeader';
import ItemTableData from './ItemTableData';
import cartesianProduct from '../../../utils/common';
import ItemVariantImageSelectionIcon from './ItemVariantImageSelectionIcon';
import { TINY_CLOUD_KEY } from '../../../constants/Site';
import ItemTableDataV1 from './ItemTableDataV1';
import ItemTableHeaderV1 from './ItemTableMetaV1';
import ItemTableMetaV1 from './ItemTableMetaV1';

function AddItem(props) {
    const inputFile = useRef([]);
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemCategory, setItemCategory] = useState({});
    const [itemBrand, setItemBrand] = useState({});
    const [price, setPrice] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState('');
    const [itemUnit, setItemUnit] = useState(1);
    const [unit, setUnit] = useState({});
    const [shortDesc, setShortDesc] = useState('');
    const [desc, setDesc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const authSelector = useSelector((state) => state.auth);
    const storeSelector = useSelector((state) => state.store);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [allUnits, setAllUnits] = useState([]);
    const { state } = useLocation();
    const [uid, setUid] = useState('');
    const [variantUid, setVariantUid] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [brandId, setBrandId] = useState('');
    const [isCategoryRender, setIsCategoryRender] = useState(false);
    const [isBrandRender, setIsBrandRender] = useState(false);
    const [isUnitRender, setIsUnitRender] = useState(false);
    const [itemUnitId, setItemUnitId] = useState('');
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [row, setRow] = useState({});

    const [isAddAttributeValid, setIsAddAttributeValid] = useState(true);
    const [itemVariantDialog, setItemVariantDialog] = useState(false);
    const [colorPickerShow, setColorPickerShow] = useState(false);
    const [chips, setChips] = React.useState([]);
    const [anchorColorEl, setAnchorColorEl] = React.useState(null);
    const [variantOptions, setVariantOptions] = useState([{ id: 1, name: 'size' }, { id: 2, name: 'color picker' }]);
    const [optionsArr, setOptionsArr] = useState([]);
    const [allItemVariants, setAllItemVariants] = useState([]);
    const [pickedVariants, setPickedVariants] = useState([]);
    const [pickedVariantsArr, setPickedVariantsArr] = useState([]);
    const [selectImageDialog, setSelectImageDialog] = useState(false);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState('');
    const [selectedVariantImages, setSelectedVariantImages] = useState([]);
    const [uploadedVariantImages, setUploadedVariantImages] = useState([]);
    const [isVariantUpdate, setIsVariantUpdate] = useState(0);
    const editorRef = useRef(null);
    const [deletedVariants, setDeletedVariants] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [addedImages, setAddedImages] = useState([]);
    const [isBrandAvail, setIsBrandAvail] = useState(false);

    const optionsObj = {
        options: variantOptions,
        selectedOption: {},
        optionValues: []
    }

    const itemAttrs = {
        original_price: '',
        discounted_price: '',
        sku_code: '',
        in_stock: 1,
        combo_quantity: '',
        attributes: [],
        unit_value: '',
        unit_id: '',
        selected_images: [],
        uploaded_images: [],
        selected_unit: {}
    }

    // {
    //     master_attribute: 'size',
    //     value: 'S'
    // },
    // {
    //     master_attribute: 'color picker',
    //     value: 'red'
    // }

    const addItemVariant = async () => {
        if (optionsArr.length < 2) {
            await setOptionsArr([...optionsArr, optionsObj]);
        }
    }

    const deleteVariantClick = async (event, option, index) => {
        var array = [...optionsArr];
        if (index !== -1) {
            array.splice(index, 1);
            await setOptionsArr(array);
        }
    }

    //Variant option change like size, color
    const onOptionChange = (event, newValue, index) => {
        let isInvalid = false;
        optionsArr?.map((option, i) => {
            if (newValue !== null && newValue?.name === option?.selectedOption?.name) {
                isInvalid = true;
            }
            if (newValue?.name !== option?.selectedOption?.name && i === index) {
                option.selectedOption = newValue;
            }
        });
        if (isInvalid) {
            optionsArr[index].selectedOption = null;
            enqueueSnackbar('Option already exits. Please select different option.', { variant: 'error' });
        }
        setOptionsArr([...optionsArr]);
    }

    //Variant value change
    const handleChipChange = (newChips, index) => {
        const newUniqueChips = newChips?.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        });
        optionsArr?.map((option, i) => {
            if (i === index) {
                option.optionValues = newUniqueChips;
            }
        });
        setOptionsArr([...optionsArr]);
    }

    //Check variant options are valid
    const checkVariantOptions = () => {
        let isAttrBtnDisabled = false;
        optionsArr?.map((option, i) => {
            if (option?.optionValues?.length === 0 || option.selectedOption === null || option?.selectedOption?.name === null) {
                isAttrBtnDisabled = true;
            }
        });
        setIsAddAttributeValid(isAttrBtnDisabled);
    }

    const addVariants = async () => {
        const pickVariants = [];
        const pickVariantsArr = [];
        const inputs = [];
        await optionsArr?.map((option) => {
            pickVariants.push(option?.selectedOption?.name);
            pickVariantsArr.push(...option?.optionValues);
            const attrObj = {
                [option?.selectedOption?.name]: option.optionValues
            };
            inputs.push(attrObj);
        });
        setPickedVariants([...pickVariants]);
        setPickedVariantsArr([...pickVariantsArr]);
        setItemVariantDialog(false);
        const allAttribute = cartesianProduct(inputs);
        const allItemVariantsWithAttr = [];
        await allAttribute?.map((option) => {
            const attrArr = [];
            const optionKeys = Object.keys(option);
            optionKeys.map((key) => {
                const attrObj = {
                    master_attribute: key,
                    value: option[key]
                }
                attrArr.push(attrObj);
            });
            const itemObj = cloneDeep(itemAttrs);
            itemObj.attributes = attrArr;
            allItemVariantsWithAttr.push(itemObj);
        });
        setAllItemVariants(allItemVariantsWithAttr);
        setIsVariantUpdate(1);
    }

    const handleFormValueChange = (event, index, selected_unit = {}) => {
        const { name, value } = event.target;
        const currentVariant = allItemVariants[index];
        let modifiedVariant = { ...currentVariant, [name]: value };
        if (Object.keys(selected_unit).length !== 0) {
            modifiedVariant = { ...modifiedVariant, ['selected_unit']: selected_unit };
        }
        allItemVariants[index] = modifiedVariant;
        setAllItemVariants([...allItemVariants]);
    };

    const deleteVariantRowClick = async (event, row, index) => {
        var array = [...allItemVariants];
        if (index !== -1) {
            array.splice(index, 1);
            setAllItemVariants(array);
        }
        const deletedVars = deletedVariants;
        setDeletedVariants([...deletedVars, { id: row.uid }]);
    }

    const selectVariantImages = async (event, row, index) => {
        setSelectedVariantIndex(index);
        setSelectedVariantImages([...allItemVariants[index]?.selected_images]);
        setUploadedVariantImages([...allItemVariants[index]?.uploaded_images]);
        setSelectImageDialog(true);
    }

    const selectImageCloseDialog = async () => {
        setSelectImageDialog(false);
    }

    const chooseImage = async (event, file) => {
        const selectedImagesArr = cloneDeep(selectedVariantImages);
        const isAvail = selectedImagesArr?.length > 0 ? selectedImagesArr.some(o => file.item_image_id === o.item_image_id) : false;
        if (isAvail) {
            const filterData = selectedImagesArr?.filter(o => file.item_image_id !== o.item_image_id);
            setSelectedVariantImages([...filterData]);
        } else {
            selectedImagesArr?.push(file);
            setSelectedVariantImages([...selectedImagesArr]);
        }
    }
    const chooseUploadedImage = async (event, file) => {
        file.status = 'Added';
        const uploadedImagesArr = cloneDeep(uploadedVariantImages);
        const isAvail = uploadedImagesArr?.length > 0 ? uploadedImagesArr.some(o => file.id === o.id) : false;
        if (isAvail) {
            const filterData = uploadedImagesArr?.filter(o => file.id !== o.id);
            setUploadedVariantImages([...filterData]);
        } else {
            uploadedImagesArr?.push(file);
            setUploadedVariantImages([...uploadedImagesArr]);
        }
    }

    const selectItemImageClick = async () => {
        allItemVariants[selectedVariantIndex].uploaded_images = uploadedVariantImages;
        allItemVariants[selectedVariantIndex].selected_images = selectedVariantImages;
        setAllItemVariants([...allItemVariants]);
        setSelectImageDialog(false);
    }


    const removeVariantImage = async (event, row, index, imageIndex) => {
        event.stopPropagation();
        const selImg = allItemVariants[index].uploaded_images;
        if (imageIndex > -1) {
            selImg.splice(imageIndex, 1);
        }
        allItemVariants[index].uploaded_images = selImg;
        setAllItemVariants([...allItemVariants]);
    }

    const removeSelectedImage = async (event, row, index, imageIndex) => {
        event.stopPropagation();
        const selImg = allItemVariants[index].selected_images;
        if (imageIndex > -1) {
            selImg.splice(imageIndex, 1);
        }
        allItemVariants[index].selected_images = selImg;
        setAllItemVariants([...allItemVariants]);
    }



    const handleChange = (newChips) => {
        setChips(newChips)
    }

    const openVariantDialog = () => {
        setItemVariantDialog(true);
    }
    const itemVariantCloseDialog = () => {
        setItemVariantDialog(false);
    }
    const handleColorPopover = (event) => {
        setAnchorColorEl(event.currentTarget);
        setColorPickerShow(true);
    }

    const handleColorPopoverClose = () => {
        setAnchorColorEl(null);
        setColorPickerShow(false);
    };
    const colorOpen = Boolean(anchorColorEl);
    const id = colorOpen ? 'color-popover' : undefined;



    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = async (e) => {
        const filesArray = filesData;
        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            filesArray.push(file);
            return URL.createObjectURL(file);
        });
        //setFiles([...imagesArray, ...files]);
        setFilesData(filesArray);
        try {
            setIsLoading(true);
            let formData = new FormData();
            if (filesData.length) {
                filesData.forEach((image_file) => {
                    formData.append('user_file', image_file);
                });
            }
            const res = await dispatch(uploadItemImages(formData));
            const uploadedFilesArray = [];
            if (res?.data?.length > 0) {
                res?.data?.forEach((image) => {
                    uploadedFilesArray.push(image);
                });
            }
            const addedImg = addedImages;
            setAddedImages([...addedImg, ...uploadedFilesArray]);
            const uploadedImg = uploadedFiles;
            setUploadedFiles([...uploadedImg, ...uploadedFilesArray]);
            setIsLoading(false);
            setFilesData([]);
        } catch (err) {
            setFilesData([]);
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleFileRemove = (e, file, index) => {
        setFiles(files.filter((f) => f != file));
        setFilesData(filesData.splice(index, 1));
        const deletedImg = deletedImages;
        setDeletedImages([...deletedImg, { id: file.uid }]);
    }

    const handleItemFileRemove = (file) => {
        setDeletedFiles([...deletedFiles, file.id]);
        setUploadedFiles(uploadedFiles.filter(f => f.id !== file.id));
    }

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            const formValuesVariant = cloneDeep(allItemVariants);
            await formValuesVariant?.map(value => {
                value.unit_id = value?.selected_unit?.id;
                value.unit_value = value.unit_value.toString();
                value.selected_images = value.uploaded_images;
                delete value?.selected_unit;
                delete value?.undefined;
                delete value?.uploaded_images;
            });
            let desc = "";
            if (editorRef.current) {
                desc = editorRef.current.getContent();
            }
            //without variant
            let formValuesWithoutVariant = [];
            if (allItemVariants === undefined || allItemVariants.length == 0) {
                const formValuesWithoutVariantObj = cloneDeep(itemAttrs);
                formValuesWithoutVariantObj.original_price = price;
                formValuesWithoutVariantObj.discounted_price = discountedPrice;
                formValuesWithoutVariantObj.unit_value = itemUnit.toString();
                formValuesWithoutVariantObj.unit_id = unit?.id;
                formValuesWithoutVariantObj.sku_code = '';
                formValuesWithoutVariantObj.in_stock = 1;
                formValuesWithoutVariantObj.combo_quantity = '';
                formValuesWithoutVariantObj.selected_images = [];
                formValuesWithoutVariantObj.attributes = [];
                delete formValuesWithoutVariantObj.selected_unit;
                delete formValuesWithoutVariantObj.uploaded_images;
                formValuesWithoutVariant.push(formValuesWithoutVariantObj);
            }
            let formData = new FormData();
            formData.append('form_data', JSON.stringify(
                {
                    store_id: storeId,
                    short_description: shortDesc,
                    description: desc,
                    name: itemName,
                    item_category_id: itemCategory?.uid,
                    brand_id: itemBrand?.uid,
                    all_images: uploadedFiles,
                    variants: allItemVariants === undefined || allItemVariants.length == 0 ? formValuesWithoutVariant : formValuesVariant
                }
            ));
            const res = await dispatch(addItem(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/items');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            const formValuesVariant = cloneDeep(allItemVariants);
            await formValuesVariant?.map(value => {
                value.unit_id = value?.selected_unit?.id;
                value.unit_value = value.unit_value.toString();
                let ids = [];
                value?.selected_images?.map(img => {
                    ids.push(img.item_image_id);
                });
                const deleted_images = [];
                const original_images_ids = [];
                value?.original_images?.map(item => {
                    original_images_ids.push(item.item_image_id);
                    if (!ids?.includes(item.item_image_id)) {
                        deleted_images.push({ id: item.id });
                    }
                });
                const added_images = [];
                value?.selected_images?.map(img => {
                    if (!original_images_ids?.includes(img.item_image_id)) {
                        added_images.push(img);
                    }
                });
                value.selected_deleted_images = deleted_images;
                value.selected_added_images = [...value.uploaded_images, ...added_images];
                delete value?.original_images;
                delete value?.selected_unit;
                delete value?.undefined;
                delete value?.uploaded_images;
            });
            let desc = "";
            if (editorRef.current) {
                desc = editorRef.current.getContent();
            }
            //without variant
            let formValuesWithoutVariant = [];
            let isSingleVariant = false;
            if (allItemVariants === undefined || allItemVariants.length == 0) {
                const formValuesWithoutVariantObj = cloneDeep(itemAttrs);
                formValuesWithoutVariantObj.uid = variantUid;
                formValuesWithoutVariantObj.original_price = price;
                formValuesWithoutVariantObj.discounted_price = discountedPrice;
                formValuesWithoutVariantObj.unit_value = itemUnit.toString();
                formValuesWithoutVariantObj.unit_id = unit?.id;
                formValuesWithoutVariantObj.sku_code = '';
                formValuesWithoutVariantObj.in_stock = 1;
                formValuesWithoutVariantObj.combo_quantity = '';
                formValuesWithoutVariantObj.selected_images = [];
                formValuesWithoutVariantObj.attributes = [];
                delete formValuesWithoutVariantObj.selected_unit;
                delete formValuesWithoutVariantObj.uploaded_images;
                isSingleVariant = true;
                formValuesWithoutVariant.push(formValuesWithoutVariantObj);
            }

            let formData = new FormData();
            formData.append('form_data', JSON.stringify(
                {
                    store_id: storeId,
                    uid: uid,
                    short_description: shortDesc,
                    description: desc,
                    name: itemName,
                    item_category_id: itemCategory?.uid,
                    brand_id: itemBrand?.uid,
                    all_images: uploadedFiles,
                    deleted_images: deletedImages,
                    added_images: addedImages,
                    is_variant_update: isSingleVariant ? 1 : isVariantUpdate,
                    deleted_variants: isSingleVariant ? [] : deletedVariants,
                    variants: allItemVariants === undefined || allItemVariants.length == 0 ? formValuesWithoutVariant : formValuesVariant
                }
            ));
            if (deletedFiles.length > 0) {
                const dFiles = [];
                deletedFiles.forEach((d) => {
                    if (d.length > 0) {
                        dFiles.push(d);
                    }
                });
                formData.append('deleted_files', JSON.stringify(dFiles));
            }
            const res = await dispatch(updateItem(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/items');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getCategories = async () => {
        try {
            const res = await dispatch(getAllCategories(storeId));
            setCategories(res.data);
            setIsCategoryRender(true);
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getBrands = async () => {
        try {
            const res = await dispatch(getAllBrands(storeId));
            setBrands(res.data);
            setIsBrandRender(true);
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getUnits = async () => {
        try {
            const res = await dispatch(getAllUnits());
            setAllUnits(res.data);
            setIsUnitRender(true);
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleVariant = async (state) => {
        const variants = state?.variants?.[0];
        setUid(state?.uid);
        setVariantUid(variants?.uid);
        setItemName(state?.name);
        setPrice(variants?.original_price);
        setDiscountedPrice(variants?.discounted_price);
        setItemUnit(variants?.variant_value);
        setItemUnitId(variants?.variant_unit);
        setShortDesc(state?.short_description);
        setDesc(state?.description);
        setCategoryId(state?.cat_id);
        setBrandId(state?.brand_id);
    }

    const handleVariantUnits = async (state) => {
        const variants = state?.variants?.[0];
        const allSavedVariants = state?.variants;
        // create option array
        if (variants?.attributes?.length > 0) {
            const allSavedAttributes = [];
            const allVariants = [];
            allSavedVariants?.map(item => {
                const selected_unit = allUnits.find((unit) => unit.id == item?.variant_unit);
                const selected_images = cloneDeep(item?.selected_images);
                const savedVariantObj = {
                    uid: item?.uid,
                    original_price: item?.original_price,
                    discounted_price: item?.discounted_price,
                    combo_quantity: item?.combo_quantity,
                    unit_value: item?.variant_value,
                    sku_code: item?.sku_code,
                    in_stock: item?.in_stock,
                    attributes: item?.attributes,
                    selected_images: item?.selected_images,
                    original_images: selected_images,
                    uploaded_images: [],
                    selected_added_images: [],
                    selected_deleted_images: [],
                    selected_unit: selected_unit
                };
                allVariants.push(savedVariantObj);
                allSavedAttributes.push(...item?.attributes);
            });
            setAllItemVariants([...allVariants]);
            if (allSavedAttributes?.length > 0) {
                const pickVariants = [];
                const pickVariantsArr = [];
                const variantKeyArr = [];
                variantOptions?.map((option) => {
                    let variantKeyObj = {};
                    let variantKeyValues = [];
                    allSavedAttributes?.map((item) => {
                        if (option?.name == item?.master_attribute) {
                            if (!variantKeyValues.includes(item?.value)) {
                                pickVariants.push(option?.name);
                                variantKeyValues.push(item?.value);
                                variantKeyObj['options'] = variantOptions;
                                variantKeyObj['selectedOption'] = option;
                                variantKeyObj['optionValues'] = variantKeyValues;
                            }
                        }
                    });
                    pickVariantsArr.push(...variantKeyValues);
                    if (Object.keys(variantKeyObj).length) {
                        variantKeyArr.push(variantKeyObj);
                    }
                })

                setOptionsArr([...variantKeyArr]);
                setPickedVariants([...[...new Set(pickVariants)]]);
                setPickedVariantsArr([...pickVariantsArr]);
            }
        }
    }

    useEffect(() => {
        getCategories();
        getUnits();
        if (state) {
            setRow(state);
            handleVariant(state);
        }
        setFiles(state?.all_iamges);
    }, []);


    useEffect(() => {
        checkVariantOptions();
    }, [optionsArr]);

    useEffect(() => {
        setIsBrandAvail(storeSelector?.details?.is_brand);
    }, [storeSelector]);

    useEffect(() => {
        if (isBrandAvail) {
            getBrands();
        }
    }, [isBrandAvail]);


    useEffect(() => {
        if (isCategoryRender && uid) {
            const cateArr = categories.find((category) => category.uid == categoryId);
            setItemCategory(cateArr);
        }
    }, [isCategoryRender]);

    useEffect(() => {
        if (isBrandRender && uid) {
            const brandArr = brands.find((brand) => brand.uid == brandId);
            setItemBrand(brandArr);
        }
    }, [isBrandRender]);

    useEffect(() => {
        if (isUnitRender && uid) {
            const unitArr = allUnits.find((unit) => unit.id == itemUnitId);
            handleVariantUnits(row);
            setUnit(unitArr);
        }
    }, [isUnitRender]);

    return (
        <>
            <Helmet>
                <title> Add Item | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        <Link style={{ textDecoration: 'none' }} to="/portal/store/items">
                            <IconButton><Iconify width="25" icon="eva:arrow-back-outline" /></IconButton>
                        </Link>  {uid ? 'Update Item' : 'Add Item'}
                    </Typography>
                </Stack>

                <Box className='item-add-card'>
                    <Card className='item-add-card-wrap'>
                        <input multiple onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Item Images (Maximum 10 images)
                        </Typography>
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box className='c-image-wrap c-item-image-wrap'>
                                    <Link className='c-image c-item-image' onClick={handleFileClick}>
                                        <Iconify sx={{
                                            width: 40
                                        }} icon="mdi:camera-plus" />
                                        <Typography style={{
                                            fontSize: 12
                                        }}>
                                            Upload image
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography className='c-image-caption'>
                                    Allowed *.jpeg, *.jpg, *.png
                                    max size of 1 MB
                                </Typography>
                            </Box>
                            <Box className='item-images'>
                                <ul>
                                    {files && files.map((file, index) => {
                                        return (
                                            <li key={file}>
                                                <img
                                                    src={`${IMAGE_URL}item/thumbnail?id=${storeId}&img_id=${file.file_path}`}
                                                    loading="lazy"
                                                />
                                                <Typography className='item-images-d'>
                                                    <Iconify icon={'eva:trash-2-outline'} onClick={(e) => handleFileRemove(e, file, index)} />
                                                </Typography>
                                            </li>
                                        );
                                    })
                                    }
                                    {uploadedFiles && uploadedFiles.map((file, index) => {
                                        return (
                                            <li key={file?.uid}>
                                                <img
                                                    src={`${IMAGE_URL}store/image?img_id=${file.file_path}`}
                                                    loading="lazy"
                                                />
                                                <Typography className='item-images-d'>
                                                    <Iconify icon={'eva:trash-2-outline'} onClick={(e) => handleItemFileRemove(file)} />
                                                </Typography>
                                            </li>
                                        );
                                    })
                                    }
                                </ul>
                            </Box>
                        </Box>
                    </Card>
                    <Card className='item-add-card-wrap'>
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Item Info
                        </Typography>
                        <Stack spacing={3}>
                            <TextField
                                value={itemName}
                                autoFocus
                                autoComplete='off'
                                name="item_name"
                                label="Item Name *"
                                onChange={(event) => setItemName(event.target.value)}
                            />
                            <Autocomplete
                                value={itemCategory}
                                options={categories}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderInput={(params) => <TextField fullWidth {...params} label="Item Category *" />}
                                onChange={(event, newInputValue) => {
                                    setItemCategory(newInputValue);
                                }}
                            />
                            {isBrandAvail &&
                                <Autocomplete
                                    value={itemBrand}
                                    options={brands}
                                    getOptionLabel={(option) => option.name ? option.name : ''}
                                    renderInput={(params) => <TextField fullWidth {...params} label="Item Brand" />}
                                    onChange={(event, newInputValue) => {
                                        setItemBrand(newInputValue);
                                    }}
                                />
                            }
                            <Stack direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}>
                                <TextField
                                    value={price}
                                    autoComplete='off'
                                    name="item_price"
                                    label="Price *"
                                    onChange={(event) => setPrice(event.target.value)}
                                />
                                <TextField
                                    value={discountedPrice}
                                    autoComplete='off'
                                    name="item_discounted_price"
                                    label="Discounted Price (Optional)"
                                    onChange={(event) => setDiscountedPrice(event.target.value)}
                                />
                            </Stack>
                            <Stack direction="row"
                                alignItems="center"
                                spacing={2}>
                                <TextField
                                    autoComplete='off'
                                    type='number'
                                    name="item_unit"
                                    label="Item Unit *"
                                    value={itemUnit}
                                    onChange={(event) => {
                                        setItemUnit(event.target.value)
                                    }}
                                />
                                <Box style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 15
                                }}>
                                    <Box style={{
                                        minWidth: 100
                                    }}>
                                        <Autocomplete
                                            value={unit}
                                            options={allUnits}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label="Unit *" />}
                                            onChange={(event, newInputValue) => {
                                                setUnit(newInputValue);
                                            }}
                                        />
                                    </Box>
                                    <Typography style={{
                                        fontSize: 14
                                    }}>{
                                            itemUnit && unit?.name && `per ${itemUnit} ${unit.name}`
                                        }</Typography>
                                </Box>
                            </Stack>
                            <TextField
                                helperText="Maximum 60 characters in length"
                                value={shortDesc}
                                autoComplete='off'
                                name="item_short_desc"
                                label="Short Description (Optional)"
                                multiline rows={2}
                                onChange={(event) => setShortDesc(event.target.value)}
                            />
                            <Box>
                                <Box>Description</Box>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    apiKey={TINY_CLOUD_KEY}
                                    initialValue={desc}
                                    init={{
                                        placeholder: "Description (Optional)",
                                        branding: false,
                                        height: 250,
                                        plugins: 'link image code lists table',
                                        toolbar: 'backcolor forecolor | fontselect | lists formatselect | ' +
                                            'bold italic | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent '
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Card>
                </Box>

                <Box style={{
                    marginTop: 20
                }}>
                    <Card>
                        <Box style={{
                            padding: 20,
                        }}>
                            <Typography style={{
                                fontSize: 15,
                                color: '#000',
                                fontWeight: 600,
                                marginBottom: 5
                            }}>Variants</Typography>
                            <Typography style={{
                                fontSize: 13
                            }}>Add variants like size, color, etc to the item</Typography>
                            <Box style={{
                                paddingTop: 10,
                                paddingBottom: 10
                            }}>
                                <Divider></Divider>
                            </Box>

                            {pickedVariantsArr?.length > 0 &&
                                <Box>
                                    <Typography style={{
                                        fontSize: 15,
                                        marginBottom: 5
                                    }}>{pickedVariants?.join(' & ')}</Typography>
                                    <Box style={{
                                        padding: 10,
                                    }}>
                                        {pickedVariantsArr && pickedVariantsArr.map((variant, index) => {
                                            return (
                                                < Chip label={variant} key={index} style={{ margin: 5 }} />
                                            );
                                        })
                                        }
                                    </Box>
                                </Box>
                            }

                            <Box>
                                <AppButton variant="outlined" onClick={openVariantDialog}>
                                    {
                                        pickedVariantsArr?.length > 0 ?
                                            'Edit or add variants' : 'Add variants'
                                    }
                                </AppButton>
                            </Box>

                            {/* <Box>

                                {allUnits?.length > 0 && allItemVariants?.length > 0 &&
                                    <Box className="item-variant-wrapper">
                                        <Box className="item-meta">
                                            <Box className="item-table-header-wrapper">
                                                <Box className="item-table-header">Variants</Box>
                                            </Box>
                                            <Box className="item-table-data-container">

                                                {allItemVariants?.length > 0 && allItemVariants.map((variant, index) => {
                                                    return (
                                                        <ItemTableMeta
                                                            key={index}
                                                            index={index}
                                                            variant={variant}
                                                            selectVariantImages={selectVariantImages}
                                                            removeVariantImage={removeVariantImage}
                                                        />
                                                    );
                                                })
                                                }

                                            </Box>
                                        </Box>
                                        <Box className="item-variant-data">
                                            <ItemTableHeader />
                                            <Box className="item-table-data-container">
                                                {allItemVariants.map((variant, index) => {
                                                    return (
                                                        <ItemTableData
                                                            variant={variant}
                                                            key={index}
                                                            index={index}
                                                            allUnits={allUnits}
                                                            handleFormValueChange={handleFormValueChange}
                                                            deleteVariantRowClick={deleteVariantRowClick}
                                                        />
                                                    );
                                                })
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                }

                            </Box> */}

                        </Box>
                    </Card>
                </Box>

                {/* Item variant card */}
                <Box className="item-variant-meta-new">
                    {allUnits?.length > 0 && allItemVariants?.length > 0 &&
                        allItemVariants.map((variant, index) => {
                            return (
                                <Box>
                                    <Card>
                                        <ItemTableMetaV1
                                            key={index}
                                            index={index}
                                            variant={variant}
                                            selectVariantImages={selectVariantImages}
                                            removeVariantImage={removeVariantImage}
                                            removeSelectedImage={removeSelectedImage}
                                            deleteVariantRowClick={deleteVariantRowClick}
                                        />
                                        <Box style={{
                                            margin: 20
                                        }}>
                                            <ItemTableDataV1
                                                variant={variant}
                                                key={index}
                                                index={index}
                                                allUnits={allUnits}
                                                handleFormValueChange={handleFormValueChange}
                                            />
                                        </Box>

                                    </Card>
                                </Box>
                            )
                        })
                    }
                </Box>

                <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Box style={{
                        marginTop: 20,
                        maxWidth: 250,
                        flex: '1 0 auto'
                    }}>
                        <AppButton
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={uid ? handleUpdateClick : handleSaveClick}
                            fullWidth size='large'
                            variant="contained">
                            {uid ? "Update" : "Save"}
                        </AppButton>
                    </Box>
                </Box>

            </Container >

            {itemVariantDialog &&
                <AppDialog maxWidth={'xs'} fullWidth={true}
                    handleClickClose={itemVariantCloseDialog}
                    isOpen={itemVariantDialog}
                    id={'item_variants_dialog'}
                    title={<>{"Add Variants"}</>}
                >
                    <Box>
                        <Box className="add-variant-dialog">
                            <Box>
                                {optionsArr && optionsArr.map((option, index) => {
                                    return (
                                        <ItemOptions key={index}
                                            index={index}
                                            itemOption={option}
                                            deleteVariantClick={deleteVariantClick}
                                            onOptionChange={onOptionChange}
                                            handleChipChange={handleChipChange}
                                        />
                                    );
                                })
                                }
                                {/* <Box className="add-variant-wrapper">
                                    <Box className="variant-option-group">
                                        <Autocomplete
                                            value={unit}
                                            options={allVariants}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label="Option name*" />}
                                            onChange={(event, newInputValue) => {
                                                setUnit(newInputValue);
                                            }}
                                        />
                                    </Box>
                                    <Box className="variant-form-group">
                                        <Box className="variant-form-control">
                                            <MuiChipsInput fullWidth value={chips} onChange={handleChange} hideClearAll />
                                        </Box>
                                        <Box className="variant-action-icon">
                                            <IconButton size="large" color="inherit">
                                                <Iconify icon={'ph:trash'} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box> */}

                                {/* <Box className="add-variant-wrapper">
                                    <Box className="variant-option-group">
                                        <Autocomplete
                                            value={unit}
                                            options={allVariants}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label="Unit *" />}
                                            onChange={(event, newInputValue) => {
                                                setUnit(newInputValue);
                                            }}
                                        />
                                    </Box>
                                    <Box className="variant-form-group">
                                        <Box className="variant-form-control">
                                            <Box className="item-form-color-select" >
                                                <Box className="item-color-popover" onClick={(event) => handleColorPopover(event)}>
                                                    <Chip label="Clickable" style={{ margin: 5 }} />
                                                    <Chip label="Clickable" style={{ margin: 5 }} />
                                                    <Chip label="Clickable" style={{ margin: 5 }} />
                                                </Box>
                                                <Box>
                                                    <Popover
                                                        id={id}
                                                        open={colorOpen}
                                                        anchorEl={anchorColorEl}
                                                        onClose={handleColorPopoverClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <SketchPicker />
                                                    </Popover>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="variant-action-icon">
                                            <IconButton size="large" color="inherit">
                                                <Iconify icon={'ph:trash'} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box> */}
                            </Box>
                            <Box className="add-variant-option-wrap" style={{
                                marginTop: 25
                            }}>
                                {optionsArr?.length < 2 &&
                                    <AppButton onClick={addItemVariant} variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} >
                                        {optionsArr?.length == 1 ? 'Add another option' : 'Add item variant'}   {/* Add another option */}
                                    </AppButton>
                                }
                            </Box>
                            <Box className="variant-btn-wrap">
                                <AppButton disabled={isAddAttributeValid} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={addVariants}>
                                    Add Variants
                                </AppButton>
                            </Box>
                        </Box>
                    </Box>
                </AppDialog>
            }

            {selectImageDialog &&
                <AppDialog maxWidth={'xs'} fullWidth={true}
                    handleClickClose={selectImageCloseDialog}
                    isOpen={selectImageDialog}
                    id={'select_image_dialog'}
                    title={<>{"Choose variant images"}</>}
                >
                    <Box>
                        <Box className="choose-image-dialog">
                            <Box>
                                <Typography className='select-images-text'>Upload media</Typography>
                                <Box className='c-image-wrap c-item-image-wrap'>
                                    <Link className='c-image c-item-image' onClick={handleFileClick}>
                                        <Iconify sx={{
                                            width: 40
                                        }} icon="mdi:camera-plus" />
                                        <Typography style={{
                                            fontSize: 12
                                        }}>
                                            Upload image
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Box>
                                <Typography className='select-images-text' style={{
                                    marginTop: 20
                                }}>Uploaded media</Typography>
                                <Box>
                                    <Box className='item-images-variant'>
                                        {files && files.map((file, index) => {
                                            return (
                                                <Box className="item-uploaded-images cur-p" key={file?.uid}>
                                                    <img
                                                        onClick={(event) => chooseImage(event, file)}
                                                        src={`${IMAGE_URL}item/thumbnail?id=${storeId}&img_id=${file.file_path}`}
                                                        loading="lazy"
                                                    />
                                                    <ItemVariantImageSelectionIcon file={file} selectedVariantImages={selectedVariantImages} />
                                                </Box>
                                            );
                                        })
                                        }

                                        {uploadedFiles && uploadedFiles.map((file, index) => {
                                            return (
                                                <Box className="item-uploaded-images cur-p" key={file?.uid}>
                                                    <img
                                                        onClick={(event) => chooseUploadedImage(event, file)}
                                                        src={`${IMAGE_URL}store/image?img_id=${file.file_path}`}
                                                        loading="lazy"
                                                    />
                                                    <ItemVariantImageSelectionIcon file={file} uploadedVariantImages={uploadedVariantImages} />
                                                </Box>
                                            )
                                        })
                                        }
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="variant-btn-wrap">
                                <AppButton variant="contained" onClick={selectItemImageClick}>
                                    Select
                                </AppButton>
                            </Box>

                        </Box>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default AddItem;