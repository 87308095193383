import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { FiMoreVertical } from "react-icons/fi";
import Rating from '@mui/material/Rating';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./Review.css";
import Iconify from '../../portal/compoments/iconify/Iconify';
import ReviewPopover from '../review-popover/ReviewPopover';
import { IMAGE_URL, NO_USER_IMAGE } from '../../constants/Images';
import AppButton from '../app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { reviewReply } from '../../reduxStore/actions/StoreActions';
import AppDialogDelete from '../app-dialog-delete/AppDialogDelete';
import { REVIEW_REPLY } from '../../reduxStore/actions/StoreActionTypes';

function Review({ review }) {
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedReviewUid, setSelectedReviewUid] = React.useState('');
    const [activeUid, setActiveUid] = React.useState('');
    const [activeEditUid, setActiveEditUid] = React.useState('');
    const [replyValue, setReplyValue] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const replyReviewClick = (uid) => {
        setSelectedReviewUid(uid);
    };

    const cancelReply = () => {
        setActiveEditUid('');
        setActiveUid('');
        setSelectedReviewUid('');
    };

    const handleReplyClick = async () => {
        try {
            setIsLoading(true);

            const obj = JSON.stringify({
                store_id: storeId,
                uid: selectedReviewUid,
                store_review: replyValue
            });
            const res = await dispatch(reviewReply(obj));
            cancelReply();
            dispatch({
                type: REVIEW_REPLY,
                payload: res.data,
            });
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = JSON.stringify({
            store_id: storeId,
            uid: activeUid,
            store_review: ''
        });
        try {
            const res = await dispatch(reviewReply(obj));
            dispatch({
                type: REVIEW_REPLY,
                payload: res.data,
            });
            cancelReply();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }

    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const handleDeleteClick = async (uid) => {
        setIsDeleteDialogOpen(true);
        setActiveUid(uid);
    }

    const handleEditClick = async (uid) => {
        setActiveEditUid(uid);
        setSelectedReviewUid(uid);
        setReplyValue(review?.store_review);
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <div className='sUserReview'>
                <div className='sUserReviewD'>
                    <div className='sUserReviewImg'>
                        <Avatar
                            variant="circular"
                            alt={review?.user_name}
                            src={review?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${review?.profile_pic}` : NO_USER_IMAGE}
                            style={{
                                height: 60,
                                width: 60
                            }} />
                    </div>
                    <div className='sUserReviewName'>{review?.user_name}</div>
                    <div>
                        <ReviewPopover review={review} />
                    </div>
                </div>
                <div className='sUserReviewRate'>
                    <div>
                        <Rating name="read-only" value={review?.rating} readOnly />
                    </div>
                    <div className='sUserReviewPosted'>
                        {review?.created_at}
                    </div>
                </div>
                <div className='sUserReviewComment'>
                    {review?.user_review}
                </div>
                <div style={{
                    marginTop: 10
                }}>
                    <Box style={{
                        display: selectedReviewUid !== review?.uid ? 'block' : 'none'
                    }}>
                        <Button
                            onClick={() => replyReviewClick(review?.uid)}
                            size="small"
                            variant="text"
                            startIcon={<Iconify icon="ri:reply-fill" />}
                            style={{
                                color: 'rgb(0, 171, 85)',
                                display: !review?.store_review ? 'inline-flex' : 'none'
                            }}>
                            Reply
                        </Button>
                    </Box>
                    <Box style={{
                        display: selectedReviewUid === review?.uid ? 'block' : 'none',
                        padding: 10
                    }}>
                        <TextField
                            fullWidth
                            value={replyValue}
                            autoFocus
                            autoComplete='off'
                            name="reply_review"
                            label="Reply *"
                            onChange={(event) => setReplyValue(event.target.value)}
                        />
                        <Box className='reply-review-btn-wrap'>
                            <Button onClick={cancelReply}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={handleReplyClick}
                                variant="contained">
                                Submit
                            </AppButton>
                        </Box>
                    </Box>
                </div>
                {(review?.store_review && !activeEditUid) &&
                    <div className='sOwnerReview'>
                        <div className='sOwnerReviewHead'>
                            <div className='sOwnerReviewHeadName'>{review?.store_name}</div>
                            <div className='sOwnerReviewPosted'>{review?.store_review_create_at}</div>
                        </div>
                        <div className='sOwnerReviewComment'>
                            {review?.store_review}
                        </div>
                        <Stack direction='row' spacing={2} style={{
                            marginTop: 10
                        }}>
                            <Button
                                size="small"
                                variant="text"
                                startIcon={<Iconify icon="eva:edit-fill" />}
                                style={{
                                    color: 'rgb(0, 171, 85)'
                                }}
                                onClick={() => handleEditClick(review?.uid)}
                            >
                                Edit
                            </Button>
                            <Button size="small"
                                variant="text"
                                startIcon={<Iconify icon="eva:trash-2-outline" />}
                                style={{
                                    color: '#FF4842'
                                }}
                                onClick={() => handleDeleteClick(review?.uid)}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </div>
                }
                <div className='clearfix'></div>
            </div>

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_review_reply'} title={<>Delete Reply</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete reply?</Typography>
                </AppDialogDelete>
            }
        </>
    );
}

export default Review;