import { IconButton, MenuItem, Popover } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../portal/compoments/iconify/Iconify';
import { flagReview } from '../../reduxStore/actions/StoreActions';

function ReviewPopover({ review }) {

    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;

    const [open, setOpen] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const flagInap = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                uid: review?.uid
            });
            const res = await dispatch(flagReview(obj));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };


    return (
        <>
            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); flagInap() }}>
                    <Iconify icon={'ic:baseline-outlined-flag'} sx={{ mr: 1 }} />
                    Flag inappropriate
                </MenuItem>
            </Popover>
        </>
    );
}

export default ReviewPopover;