import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Stack,
    Button,
    Container,
    Typography,
    Box
} from '@mui/material';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useNavigate } from 'react-router-dom';
import AppTable from '../../compoments/app-table/AppTable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSiteEnquiries } from '../../../reduxStore/actions/StoreActions';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import AppButton from '../../../components/app-button/AppButton';
import { Helmet } from 'react-helmet';

function SiteEnquiries(props) {

    const tableHead = [
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'mobile_no', label: 'Mobile No.', alignRight: false },
        { id: 'city', label: 'City', alignRight: false },
        { id: 'message', label: 'Message', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'createdAt', label: 'Created At', alignRight: false },
        { id: '' },
    ];

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const storeSelector = useSelector((state) => state.store);
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const tableRows = storeSelector.siteEnquiries ? storeSelector.siteEnquiries : [];
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const getEnquiries = async () => {
        try {
            const obj = JSON.stringify({
                page_number: 1,
                page_limit: 1
            });
            const res = await dispatch(getAllSiteEnquiries(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    
    const toggleStatus = async (event, row) => {
        console.log("Action");
    }

    const editActionClick = async (event, row) => {
        console.log("Edit");
    }

    const deleteActionClick = async (event, row) => {
        setIsDeleteDialogOpen(true);
        setSelectedRecord(row);
    }

    const handleDeleteConfirm = async () => {
        console.log("Confirm");
    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    useEffect(() => {
        getEnquiries();
    }, []);

    return (
        <>
            <Helmet>
                <title> Enquiries | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Enquiries
                    </Typography>
                </Stack>
                <Box>
                    <AppTable
                        tableHead={tableHead}
                        tableRows={tableRows}
                        listType='siteEnquiries'
                        searchPlaceholder="Search Enquiry..."
                        toggleSwitchStatus={toggleStatus}
                        editActionClick={editActionClick}
                        deleteActionClick={deleteActionClick}
                        storeId={storeId}
                    />
                </Box>

            </Container>
            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_book_demo_dialog'} title={<>Delete Enquiry</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }
        </>
    );
}

export default SiteEnquiries;