import axiosInstance from "../../../api/axios";
import { updateUserSessionProfile } from "./AuthActions";
import { ALL_REVIEWS, SITE_DATA, UPDATE_REVIEW_RECOMMENDATIONS, UPDATE_USER_PROFILE } from "./StoreActionTypes";

//Store data
export const getStoreData = (store_url) => async (dispatch) => {
    try {
        const res = await axiosInstance.get(`/store?url=${store_url}`);
        dispatch({
            type: SITE_DATA,
            payload: res.data,
        });
        dispatch({
            type: ALL_REVIEWS,
            payload: res?.data?.reviews?.reviews,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Add Review
export const addStoreReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/create`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const flagReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/flag`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const getReviewRecommendation = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/get-review-recommendation`, obj);
        dispatch({
            type: UPDATE_REVIEW_RECOMMENDATIONS,
            payload: res?.data
        });
        dispatch({
            type: ALL_REVIEWS,
            payload: res?.data?.reviews?.reviews,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const addRecommendation = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/recommendation`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteStoreReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/actions`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const updateStoreReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/update`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const filterStoreReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/filter`, obj);
        dispatch({
            type: ALL_REVIEWS,
            payload: res?.data?.reviews,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


//Send Enquiry
export const sendStoreEnquiry = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/enquire/create`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const sendEnquiryOtp = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/enquire/otp-send`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const sendStoreEnquiryWithOtp = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/enquire/verify-otp`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Update profile

export const updateProfile = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/profile/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getUserDetails = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user", obj);
        dispatch(updateUserSessionProfile(res?.data));
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Scan info
export const sendQrScanInfo = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/analtyics/scan-info", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Item details
export const getItemDetails = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance.get(`/store/get-item-by-id/${id}`);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};