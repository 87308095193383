import axiosInstance from "../../api/axios";
import {
    ALL_CATEGORIES,
    CATEGORY_ACTION,
    ALL_ITEMS,
    ITEM_ACTION,
    CATEGORY_DELETE,
    ITEM_DELETE,
    ALL_USERS,
    ALL_LABELS,
    LABEL_ACTION,
    ALL_MASTER_CATEGORIES,
    MASTER_CATEGORY_DELETE,
    MASTER_CATEGORY_ACTION,
    STORE_ACTION,
    STORE_DELETE,
    ALL_STORES,
    USER_ACTION,
    USER_DELETE,
    DETAILS,
    ALL_OFFERS,
    OFFER_ACTION,
    OFFER_DELETE,
    ALL_REVIEWS,
    REVIEW_REPLY,
    ALL_POSTS,
    POST_ACTION,
    POST_DELETE,
    ALL_REVIEWS_FILTER,
    ALL_ANALYTICS,
    ALL_ENQUIRIES,
    ALL_UNITS,
    UNIT_ACTION,
    UNIT_DELETE,
    ALL_BRANDS,
    BRAND_ACTION,
    BRAND_DELETE,
    SITE_ENQUIRIES,
    SITE_BOOK_DEMOS,
    SITE_CONFIG
} from "./StoreActionTypes";

//Store Details
export const getStoreDetails = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/details-by-id", { store_id: store_id });
        dispatch({
            type: DETAILS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateStoreByField = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/update-by-field", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateStoreAddress = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/update/address", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Brands
export const getAllBrands = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/brand", { store_id: store_id });
        dispatch({
            type: ALL_BRANDS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const brandAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/brand/actions", obj);
        dispatch({
            type: BRAND_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const brandDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/brand/actions", obj);
        dispatch({
            type: BRAND_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addBrand = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/brand/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateBrand = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/brand/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Categories
export const getAllCategories = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/categories", { store_id: store_id });
        dispatch({
            type: ALL_CATEGORIES,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const categoryAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/category/action", obj);
        dispatch({
            type: CATEGORY_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const categoryDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/category/action", obj);
        dispatch({
            type: CATEGORY_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addCategory = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/category/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateCategory = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/category/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Items
export const getAllItems = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/items",
            {
                store_id: store_id,
                page_number: 1,
                page_limit: 5
            });
        dispatch({
            type: ALL_ITEMS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const itemAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/item/actions", obj);
        dispatch({
            type: ITEM_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addItem = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/item/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateItem = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/item/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getAllUnits = () => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units", {});
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const itemDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/item/actions", obj);
        dispatch({
            type: ITEM_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const uploadItemImages = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/item/image/upload", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Stores

export const getAllStores = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/stores", obj);
        dispatch({
            type: ALL_STORES,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const storeAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/actions", obj);
        dispatch({
            type: STORE_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const storeDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/actions", obj);
        dispatch({
            type: STORE_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const addStore = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateStore = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const createStoreUrl = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/create/url", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


//Master Categories
export const getAllMasterCategories = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/categories", obj);
        dispatch({
            type: ALL_MASTER_CATEGORIES,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const masterCategoryAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/category/actions", obj);
        dispatch({
            type: MASTER_CATEGORY_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const MasterCategoryDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/category/actions", obj);
        dispatch({
            type: MASTER_CATEGORY_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addMasterCategory = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/category/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateMasterCategory = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/category/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


//Users
export const getAllUsers = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/users", obj);
        dispatch({
            type: ALL_USERS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const userAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/action", obj);
        dispatch({
            type: USER_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const userDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/action", obj);
        dispatch({
            type: USER_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addUser = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateUser = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const getAllUserRoles = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/roles", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Photos

export const addLabel = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/create", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateLabel = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/update", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const labelAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/actions", obj);
        dispatch({
            type: LABEL_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getAllLabels = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/get", obj);
        dispatch({
            type: ALL_LABELS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const createAlbum = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/photo/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteAlbumPhoto = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/photo/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const moveAlbumPhoto = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/album/move", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


//Offers

export const addOffer = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/offer/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateOffer = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/offer/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const offerAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/offer/actions", obj);
        dispatch({
            type: OFFER_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const offerDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/offer/actions", obj);
        dispatch({
            type: OFFER_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getAllOffers = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/offers", obj);
        dispatch({
            type: ALL_OFFERS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Reviews

export const getAllReviews = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/reviews", obj);
        dispatch({
            type: ALL_REVIEWS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const reviewReply = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/review/reply", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const filterStoreReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/filter`, obj);
        dispatch({
            type: ALL_REVIEWS_FILTER,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const flagReview = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post(`/store/review/flag`, obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


//Posts
export const getAllPosts = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/post/get",
            { store_id: store_id });
        dispatch({
            type: ALL_POSTS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const postAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/post/actions", obj);
        dispatch({
            type: POST_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addPost = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/post/create", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updatePost = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/post/update", obj, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const postDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/post/actions", obj);
        dispatch({
            type: POST_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Analytics

export const getAnalytics = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/analtyics", obj);
        dispatch({
            type: ALL_ANALYTICS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Categories
export const getAllEnquiries = (store_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/enquires", { store_id: store_id });
        dispatch({
            type: ALL_ENQUIRIES,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Book demo
export const bookDemo = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/book-demo", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Contact us
export const contactUs = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/contact-us", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Units
export const getUnits = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units", obj);
        dispatch({
            type: ALL_UNITS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const unitAction = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units/actions", obj);
        dispatch({
            type: UNIT_ACTION,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const unitDelete = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units/actions", obj);
        dispatch({
            type: UNIT_DELETE,
            payload: obj,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addUnit = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units/create", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateUnit = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/units/update", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Book demo
export const getAllSiteBookDemos = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/get-book-demo", obj);
        dispatch({
            type: SITE_BOOK_DEMOS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Site Enquiry
export const getAllSiteEnquiries = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/get-contact-us", obj);
        dispatch({
            type: SITE_ENQUIRIES,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

//Site terms

export const getWebConfig = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/term-conditions", obj);
        dispatch({
            type: SITE_CONFIG,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getConfig = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/appconfig/get", obj);
        dispatch({
            type: SITE_CONFIG,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addConfig = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/appconfig/create", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateConfig = (obj) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/store/appconfig/update", obj);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};