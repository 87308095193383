import { Box, Button, Container, Card, Tab, Tabs, Typography, Drawer, Backdrop, Divider, TextField, CardActionArea, ButtonBase, Fab, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import "./Home.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { autoPlay } from 'react-swipeable-views-utils';
import ItemList from '../../components/item-list/ItemList';
import AppButton from '../../components/app-button/AppButton';
import OffersCard from '../../components/offers-card/OffersCard';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import AppDialog from '../../components/app-dialog/AppDialog';
import Footer from '../../components/footer/Footer';
import Iconify from '../../components/iconify/Iconify';
import AppDialogSlide from '../../components/app-dialog-slide/AppDialogSlide';
import { useDispatch, useSelector } from 'react-redux';
import { APP_SHARE } from '../../../reduxStore/actions/site/StoreActionTypes';
import { IMAGE_URL, NO_IMAGE, NO_STORE_IMAGE } from '../../constants/Images';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Home(props) {
    const dispatch = useDispatch();
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;
    const storeUrl = site?.store_details?.url;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const isOfferSection = configs?.showOffers;
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const isLoading = storeSelector.appLoading;
    // const [site, setSite] = React.useState({});
    const [value, setValue] = React.useState(0);
    const [offerValue, setofferValue] = React.useState(0);
    const [isItemClick, setIsItemClick] = React.useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(!isDrawerOpen);
    };

    const preventToggle = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleShareDialogClickOpen = () => {
        dispatch({
            type: APP_SHARE,
            payload: true
        });
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleChangeOfferIndex = (index) => {
        setofferValue(index);
    };

    const onCloseDrawer = () => {
        setIsDrawerOpen(false);
    }

    const handleItemClickOpen = () => {
        navigate('/item-details');
    }
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };

    }

    const itemClick = async (row) => {
        setIsItemClick(true);
        navigate(`/${storeUrl}/item-details/${row.uid}`, { state: row });
    }

    useEffect(() => {
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className='v1-tab-box'>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const styles = {
        slide: {
            margin: 10
        },
        slide1: {
            background: '#FEA900'
        },
        slide2: {
            background: '#B3DC4A'
        },
        slide3: {
            background: '#6AC0FF'
        },
    };

    return (
        <Box>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <style>
                    {`.tabStyle.Mui-selected { background-color: ${configs?.themeBgColor}; color: ${configs?.themeColor} }`}
                </style>
                <title>{site?.store_details?.store_name ? `${site?.store_details?.store_name}` : 'TrueMenu : End User Portal'}</title>
                <meta property="og:type" content="Website" />
                <meta property="og:title" name="title" content={site?.store_details?.store_name ? `${site?.store_details?.store_name}` : 'TrueMenu : End User Portal'} />
                <meta property="og:site_name" content="Website" />
                <meta property="og:description" content={site?.store_details?.store_name ? `${site?.store_details?.store_name}` : 'TrueMenu : End User Portal'} />
                <meta name="identifier-url" content={site?.store_details?.web_url ? `${site?.store_details?.web_url}` : 'https://www.truemenu.in'} />
                <meta property="og:url" content={site?.store_details?.web_url ? `${site?.store_details?.web_url}` : 'https://www.truemenu.in'} />
                <meta property="og:image" content={site?.store_details?.logo ? `${IMAGE_URL}store/logo?id=${storeId}&img_id=${site?.store_details.logo}` : NO_IMAGE} />
                <meta property="og:image:secure_url" content={site?.store_details?.logo ? `${IMAGE_URL}store/logo?id=${storeId}&img_id=${site?.store_details.logo}` : NO_IMAGE} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

            </Helmet>
            <Box className="page-container home-page v1-store-page">
                <Box className="home-page-wrap">
                    {isOfferSection && site?.offers?.length > 0 &&
                        <Box className='home-page-offers-wrap'>
                            <Typography className='home-category-title'>{configs?.offersHeading}</Typography>
                            {site?.offers &&
                                <Box>
                                    <AutoPlaySwipeableViews>
                                        {site.offers.map((offer) => {
                                            return (
                                                <OffersCard
                                                    offer={offer}
                                                    key={offer.uid}
                                                    style={{
                                                        padding: 15,
                                                        textAlign: 'center',
                                                        background: `url(${process.env.PUBLIC_URL} /d-left.png) left top no-repeat`,
                                                        color: configs?.offersColor,
                                                        backgroundColor: configs?.offersBgColor,
                                                        backgroundPosition: 'inherit'
                                                    }} />
                                            )
                                        })}
                                    </AutoPlaySwipeableViews>
                                    <Box className='home-page-offers-view'>
                                        <Link to={`/${storeUrl}/offers`}>
                                            <AppButton style={{
                                                fontSize: 12,
                                                color: configs?.primaryButtonBgColor
                                            }}>View All</AppButton>
                                        </Link>
                                    </Box>
                                </Box>
                            }
                            {isLoading &&
                                <Box style={{
                                    margin: 10
                                }}>
                                    <Skeleton animation='wave' variant='rounded' width='100%' height={80}></Skeleton>
                                </Box>
                            }
                        </Box>
                    }
                    <Typography className='home-category-title'>{configs?.homeHeading}</Typography>
                    <Box>
                        <Box className='v1-store-tabs'>
                            <Box sx={{ width: '100%' }} className='home-category-tabs'>
                                <Box sx={{ borderColor: 'divider' }}>
                                    {site?.categories &&
                                        <Tabs
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            value={value}
                                            onChange={handleChange}
                                            TabIndicatorProps={{
                                                style: { background: "transparent" }
                                            }}
                                        >
                                            {site.categories.map((category, index) => {
                                                return (
                                                    <Tab
                                                        className='tabStyle'
                                                        key={category.uid}
                                                        label={category.name}
                                                        {...a11yProps(index)}
                                                    />
                                                )
                                            })}
                                        </Tabs>
                                    }
                                </Box>
                                {isLoading &&
                                    <Box style={{ height: 40, overflow: 'hidden', margin: 10 }}>
                                        <Box style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                            <Skeleton animation='wave' variant='rounded' width={80} height={32}></Skeleton>
                                        </Box>
                                    </Box>
                                }

                                {isLoading &&
                                    <Box style={{
                                        margin: 10
                                    }}>
                                        <Box>
                                            <Box style={{
                                                height: 100,
                                                width: '100%',
                                                display: 'flex',
                                                gap: 10,
                                                marginBottom: 20
                                            }}>
                                                <Box style={{
                                                    width: 100
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                                <Box style={{
                                                    flex: 1
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box style={{
                                                height: 100,
                                                width: '100%',
                                                display: 'flex',
                                                gap: 10,
                                                marginBottom: 20
                                            }}>
                                                <Box style={{
                                                    width: 100
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                                <Box style={{
                                                    flex: 1
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box style={{
                                                height: 100,
                                                width: '100%',
                                                display: 'flex',
                                                gap: 10,
                                                marginBottom: 20
                                            }}>
                                                <Box style={{
                                                    width: 100
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                                <Box style={{
                                                    flex: 1
                                                }}>
                                                    <Skeleton animation='wave' variant='rounded' width='100%' height={100}></Skeleton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {site?.categories &&
                                    <SwipeableViews
                                        index={value}
                                        onChangeIndex={handleChangeIndex}
                                    >
                                        {site?.categories?.map((category, index) => {
                                            return (
                                                <TabPanel
                                                    key={category.uid}
                                                    value={value}
                                                    index={index}>
                                                    {category?.items?.map((item) => {
                                                        return (
                                                            <ItemList
                                                                key={item.uid}
                                                                item={item}
                                                                itemClick={itemClick}
                                                            />
                                                        )
                                                    })}
                                                </TabPanel>
                                            )
                                        })}
                                    </SwipeableViews>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='fab-share-wrap'>
                    <Fab className="fab-share" style={{
                        background: configs?.themeBgColor,
                        color: configs?.themeColor
                    }} onClick={handleShareDialogClickOpen}>
                        <Iconify icon={'material-symbols:share'} />
                    </Fab>
                </Box>
            </Box>
        </Box>
    );
}

export default Home;