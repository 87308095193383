
import React, { useState } from 'react';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Switch,
} from '@mui/material';
import { filter } from 'lodash';
import TableListToolbar from '../../sections/TableListToolbar';
import Iconify from '../iconify/Iconify';
import Scrollbar from '../scrollbar';
import TableListHead from '../../sections/TableListHead';
import "./AppTable.css";
import { IMAGE_URL, NO_IMAGE, NO_USER_IMAGE } from '../../../constants/Images';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';


function AppTable({
    tableHead,
    tableRows,
    tableBody,
    searchPlaceholder,
    listType,
    toggleSwitchStatus,
    editActionClick,
    deleteActionClick,
    storeId,
    searchField = 'name'
}) {

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_row) => _row[searchField].toLowerCase().indexOf(query?.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const [popoverOpen, setPopoverOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('action');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRow, setSelectedRow] = useState({});

    const handleOpenMenu = (event, row) => {
        setPopoverOpen(event.currentTarget);
        setSelectedRow(row);
    };

    const handleCloseMenu = () => {
        setPopoverOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableRows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = tableRows && (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0);
    const filteredRows = tableRows && applySortFilter(tableRows, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredRows.length && !!filterName;

    const categoriesBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.name} src={row?.image_url ? `${IMAGE_URL}itemcategory/thumbnail?id=${storeId}&img_id=${row?.image_url}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
    const brandsBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.name} src={row?.image_path ? `${IMAGE_URL}brand/thumbnail?id=${storeId}&img_id=${row?.image_path}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const itemsBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.name} src={row?.all_iamges?.length > 0 ? `${IMAGE_URL}item/thumbnail?id=${storeId}&img_id=${row?.all_iamges[0]?.file_path}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {row?.item_category_name}
            </TableCell>
            <TableCell align="left">
                {row?.variants[0]?.original_price ? `Rs.${row?.variants[0]?.original_price}` : ''}
            </TableCell>
            <TableCell align="left">
                {row?.variants[0]?.discounted_price ? `Rs.${row?.variants[0]?.discounted_price}` : ''}
            </TableCell>
            <TableCell align="left">
                <Switch value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const storesBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.store_name} src={row?.logo_img_path ? `${IMAGE_URL}store/logo/thumbnail?id=${row?.uid}&img_id=${row?.logo_img_path}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.store_name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {row?.url}
            </TableCell>
            <TableCell align="left">
                {row?.cat_name}
            </TableCell>
            <TableCell align="left">
                {row?.contact_person_name}
            </TableCell>
            <TableCell align="left">
                {row?.pincode}
            </TableCell>
            <TableCell align="left">
                {row?.area}
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row?.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const usersBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.user_full_name}
                        src={row?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${row?.profile_pic}` : NO_USER_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.user_full_name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {row?.mobile}
            </TableCell>
            <TableCell align="left">
                {row?.role_name}
            </TableCell>
            <TableCell align="left">
                {row?.city}
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} />
                <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>
                    {row?.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const masterCategoriesBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.name} src={row?.image_path ? `${IMAGE_URL}category/thumbnail?img_id=${row?.image_path}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} />
                <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
    const postsBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.title} src={row?.images?.length ? `${IMAGE_URL}/store/post/thumbnail?id=${storeId}&img_id=${row?.images[0]?.image_path}` : NO_IMAGE} />
                    <Typography variant="subtitle2" noWrap>
                        {row?.title}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} />
                <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const enquiriesBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    {/* <Avatar variant="square" style={{
                        borderRadius: 5
                    }} alt={row?.name} src={row?.image_url ? `${IMAGE_URL}itemcategory/thumbnail?id=${storeId}&img_id=${row?.image_url}` : NO_IMAGE} /> */}
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            {/* <TableCell align="left">
                <Switch
                    value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell> */}
            <TableCell align="left">
                {row?.mobile_no}
            </TableCell>
            <TableCell align="left">
                {row?.email}
            </TableCell>
            <TableCell align="left">
                {row?.city}
            </TableCell>
            <TableCell align="left">
                {row?.message}
            </TableCell>
            <TableCell align="left">
                {row?.item_name}
            </TableCell>
            <TableCell align="left">
                {row?.created_at}
            </TableCell>
            {/* <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell> */}
        </TableRow>
    )

    const unitsBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Switch value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const siteBookDemosBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                        {row?.store_name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {row.contact_person_name}
            </TableCell>
            <TableCell align="left">
                {row.mobile_no}
            </TableCell>
            <TableCell align="left">
                {row.email}
            </TableCell>
            <TableCell align="left">
                {row.city}
            </TableCell>
            <TableCell align="left">
                <Switch disabled value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    const siteEnquiriesBody = (row, selectedTableRow) => (
        <TableRow hover key={row?.uid} tabIndex={-1} role="checkbox" selected={selectedTableRow}>
            <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                        {row?.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {row.city}
            </TableCell>
            <TableCell align="left">
                {row.mobile_no}
            </TableCell>
            <TableCell align="left">
                {row.message}
            </TableCell>
            <TableCell align="left">
                <Switch disabled value={row?.record_status}
                    checked={row?.record_status === 'A' ? true : false}
                    onChange={(event) => toggleSwitchStatus(event, row)} /> <Typography component='span' className={row?.record_status === 'A' ? 'record-active' : 'record-deactive'}>{row.record_status === 'A' ? 'Active' : 'Hidden'}</Typography>
            </TableCell>
            <TableCell align="left">
                {row.created_at}
            </TableCell>
            <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </TableCell>
        </TableRow>
    )


    return (
        <>
            <Card>
                <TableListToolbar numSelected={selected?.length} filterName={filterName} onFilterName={handleFilterByName} searchPlaceholder={searchPlaceholder} />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={tableHead}
                                rowCount={tableRows.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const selectedTableRow = selected.indexOf(row.name) !== -1;
                                    if (listType === 'categories') {
                                        return (categoriesBody(row, selectedTableRow));
                                    } else if (listType === 'brands') {
                                        return (brandsBody(row, selectedTableRow));
                                    } else if (listType === 'items') {
                                        return (itemsBody(row, selectedTableRow));
                                    } else if (listType === 'stores') {
                                        return (storesBody(row, selectedTableRow));
                                    } else if (listType === 'users') {
                                        return (usersBody(row, selectedTableRow));
                                    } else if (listType === 'masterCategories') {
                                        return (masterCategoriesBody(row, selectedTableRow));
                                    } else if (listType === 'posts') {
                                        return (postsBody(row, selectedTableRow));
                                    } else if (listType === 'enquiries') {
                                        return (enquiriesBody(row, selectedTableRow));
                                    } else if (listType === 'units') {
                                        return (unitsBody(row, selectedTableRow));
                                    } else if (listType === 'siteBookDemos') {
                                        return (siteBookDemosBody(row, selectedTableRow));
                                    } else if (listType === 'siteEnquiries') {
                                        return (siteEnquiriesBody(row, selectedTableRow));
                                    }
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={tableHead.length} />
                                    </TableRow>
                                )}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>

                                                <Typography variant="body2">
                                                    No results found for &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Try checking for typos or using complete words.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            <Popover
                open={Boolean(popoverOpen)}
                anchorEl={popoverOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={(event) => { handleCloseMenu(); editActionClick(event, selectedRow) }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={(event) => { handleCloseMenu(); deleteActionClick(event, selectedRow) }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
}

export default AppTable;