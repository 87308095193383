import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, Container, Card, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../components/logo/Logo';
import "./Login.css";
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Helmet } from 'react-helmet';
import AppButton from '../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { auth, sendOtp, setUserSession, updateName } from '../../reduxStore/actions/AuthActions';
import { IS_AUTH_LOADED, SHOW_LOADING } from '../../reduxStore/actions/AuthTypes';
import { useSnackbar } from 'notistack'
import { getStoreDetails } from '../../reduxStore/actions/StoreActions';

function Login(props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const authSelector = useSelector((state) => state.auth);
    const isLoading = authSelector.isLoading;
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState('');
    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [countryCode, setcountryCode] = useState('91');
    const dispatch = useDispatch();
    const otpReferenceId = authSelector.userLogin.otp_reference_id;
    const userRoleCode = authSelector.userDetails.user_role_code;
    const [timerCount, setTimer] = useState(60);

    const handleOtpChange = (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            //handleOtpClick();
        }
    }

    const handleLoginClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            setOtp('');
            const res = await dispatch(auth(countryCode, mobile));
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            setStep(2);
            setTimer(60);
            handleSetTimer();
            enqueueSnackbar('Otp send successfully', { variant: 'success' });
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleOtpClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            const res = await dispatch(sendOtp(countryCode, mobile, otp, otpReferenceId));
            await dispatch(setUserSession(res));
            if (res?.data?.primary_store_id) {
                await dispatch(getStoreDetails(res?.data?.primary_store_id));
            }
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            await dispatch({
                type: IS_AUTH_LOADED,
                payload: true
            });
            if (res?.data?.name && res.data.name !== '') {
                navigateUser(res.data.user_role_code);
                enqueueSnackbar('Successfully logged in.', { variant: 'success' });
            } else {
                setStep(3);
            }
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const navigateUser = (userRole) => {
        switch (userRole) {
            case 'user':
                return navigate('/', { replace: true });
            case 'owner':
                return navigate('/portal/dashboard', { replace: true });
            case 'agent':
                return navigate('/agent/dashboard', { replace: true });
            case 'super_admin':
                return navigate('/admin/dashboard', { replace: true });
        }
    }

    const handleNameClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            const res = await dispatch(updateName(name));
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            navigateUser(userRoleCode);
            enqueueSnackbar('Successfully logged in.', { variant: 'success' });
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleSetTimer = () => {
        let interval = setInterval(() => {
            setTimer(lastTimerCount => {
                lastTimerCount <= 1 && clearInterval(interval)
                return lastTimerCount - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }

    return (
        <>
            <Helmet>
                <title> Login | TrueMenu</title>
            </Helmet>
            <Box className='l-page'>
                <Box>
                    <Box className='login-wrap'>
                        <Card>
                            <Box>
                                {step === 1 &&
                                    <Box className='login-content'>
                                        <Logo color="rgb(0, 0, 0)" />
                                        <Typography className='l-heading'>Log in</Typography>
                                        <Box>
                                            <TextField
                                                autoComplete='off'
                                                inputProps={{
                                                    style: { fontSize: 16, fontWeight: 500 }
                                                }} fullWidth
                                                autoFocus
                                                value={mobile}
                                                label="Email or mobile number"
                                                variant="outlined"
                                                onChange={event => setMobile(event.target.value)}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton disabled={isLoading} isLoading={isLoading} onClick={handleLoginClick} fullWidth size='large' variant="contained">Continue</AppButton>
                                        </Box>
                                        <Box>
                                            <Typography className='l-policy-section'>
                                                By continuing, you agree to our <Link to="/terms" style={{
                                                    textDecoration: 'underline'
                                                }}>Terms of Use</Link> and <Link to="/privacy" style={{
                                                    textDecoration: 'underline'
                                                }}>Privacy Policy</Link>.
                                            </Typography>
                                        </Box>
                                    </Box>
                                }

                                {step === 2 &&
                                    <Box className='login-content'>
                                        <Logo color="rgb(0, 0, 0)" />
                                        {false &&
                                            <Typography className='l-error'>Incorrect OTP. Please try again.</Typography>
                                        }
                                        <Box>
                                            <Typography className='l-heading'>Verify code</Typography>
                                            <Typography>
                                                <span style={{
                                                    color: 'gray',
                                                    fontSize: 14
                                                }}>Enter verfication code sent to you at</span> +91-{mobile}
                                                <Link style={{
                                                    paddingLeft: 5,
                                                    fontSize: 14,
                                                    color: '#146eb4'
                                                }} onClick={() => setStep(1)}>Edit</Link>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box className='verify-help-text'>
                                                <Typography className='f-14'>Code</Typography>
                                                <Typography className='f-14'>
                                                    Didn't get the code?
                                                    <Link className='resend-link'
                                                        onClick={timerCount <= 0 && handleLoginClick}
                                                        style={{
                                                            color: timerCount > 0 && '#ccc',
                                                            cursor: timerCount > 0 && 'default',
                                                        }}
                                                    >
                                                        Resend OTP {timerCount > 0 && timerCount}
                                                    </Link>
                                                </Typography>
                                            </Box>

                                            <OtpInput
                                                shouldAutoFocus
                                                inputStyle='otp-input-style'
                                                focusStyle='otp-focus-style'
                                                value={otp}
                                                onChange={handleOtpChange}
                                                numInputs={6}
                                                isInputNum={true}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton disabled={isLoading} isLoading={isLoading} onClick={handleOtpClick} fullWidth size='large' variant="contained">Verify</AppButton>
                                        </Box>
                                        <Box>
                                        </Box>
                                    </Box>
                                }

                                {step === 3 &&
                                    <Box className='login-content'>
                                        <Logo color="rgb(0, 0, 0)" />
                                        <Box>
                                            <TextField
                                                autoComplete='off'
                                                inputProps={{
                                                    style: { fontSize: 16, fontWeight: 500 }
                                                }}
                                                fullWidth
                                                autoFocus
                                                value={name}
                                                label="Enter your full name"
                                                variant="outlined"
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton disabled={isLoading} isLoading={isLoading} onClick={handleNameClick} fullWidth size='large' variant="contained">Continue</AppButton>
                                        </Box>
                                    </Box>
                                }

                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Login;