import React, { useState, useEffect, useRef } from 'react';
import {
    Stack,
    Button,
    Container,
    Typography,
    Card,
    Box,
    IconButton,
    MenuItem,
    Popover,
    Divider,
    TextField,
    Autocomplete
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Iconify from '../../compoments/iconify/Iconify';
import "./Photos.css";
import { FiMoreVertical } from 'react-icons/fi';
import AppDialog from '../../../components/app-dialog/AppDialog';
import AppButton from '../../../components/app-button/AppButton';
import { addLabel, createAlbum, getAllLabels, labelAction, updateLabel } from '../../../reduxStore/actions/StoreActions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IMAGE_URL, NO_IMAGE } from '../../../constants/Images';
import { Helmet } from 'react-helmet';

function Photos(props) {
    const inputFile = useRef([]);
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [open, setOpen] = useState(null);
    const [labelDialogOpen, setLabelDialogOpen] = React.useState(false);
    const [labelSelectDialogOpen, setLabelSelectDialogOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [selectedRow, setSelectedRow] = React.useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [photoLabel, setPhotoLabel] = useState({});
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const allLabels = storeSelector.labels;
    const navigate = useNavigate();

    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setSelectedRow(row);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleDialogClickClose = () => {
        setLabelDialogOpen(false);
        setLabelSelectDialogOpen(false);
        setIsUpdate(false);
        setName('');
    };

    const handleDialogCancel = () => {
        setLabelDialogOpen(false);
        setLabelSelectDialogOpen(false);
        setIsUpdate(false);
        setName('');
    }

    const newLabelClick = () => {
        setLabelDialogOpen(true);
    }

    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        const filesArray = [];
        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            filesArray.push(file);
            return URL.createObjectURL(file);
        });
        setFiles([...imagesArray, ...files]);
        setFilesData([...filesArray, ...filesData]);
    }

    const handleFileRemove = (e, file, index) => {
        setFiles(files.filter((f) => f != file));
        setFilesData(filesData.splice(index, 1));
    }

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            console.log("filesData", filesData);
            if (filesData.length) {
                filesData.forEach((image_file) => {
                    formData.append('user_file', image_file);
                });
            }
            formData.append('form_data', JSON.stringify(
                {
                    store_id: storeId,
                    label_id: photoLabel.uid
                }
            ));
            const res = await dispatch(createAlbum(formData));
            setIsLoading(false);
            setPhotoLabel({});
            getLabels();
            setLabelSelectDialogOpen(false);
            setFiles([]);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const handleSelectLabel = () => {
        setLabelSelectDialogOpen(true);
    }

    const saveLabelClick = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                label: name,
                sequence_no: 1
            });
            const res = await dispatch(addLabel(obj));
            setIsLoading(false);
            setLabelDialogOpen(false);
            setName('');
            getLabels();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleLabelRename = async () => {
        setName(selectedRow?.label);
        setLabelDialogOpen(true);
        setIsUpdate(true);
    }

    const labelUpdate = async () => {
        try {
            setLabelDialogOpen(true);
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                label: name,
                sequence_no: 1,
                uid: selectedRow?.uid
            });
            const res = await dispatch(updateLabel(obj));
            setIsLoading(false);
            setLabelDialogOpen(false);
            setName('');
            getLabels();
            setIsUpdate(false);
            setSelectedRow({});
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const handleLabelAction = async () => {
        try {
            setIsLoading(true);
            const obj = {
                store_id: storeId,
                uid: selectedRow?.uid,
                record_status: selectedRow?.record_status === 'A' ? 'D' : 'A'
            };
            const res = await dispatch(labelAction(obj));
            setIsLoading(false);
            setSelectedRow({});
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getLabels = async () => {
        try {
            const obj = JSON.stringify({
                store_id: storeId
            });
            const res = await dispatch(getAllLabels(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleViewPhoto = async (event, row) => {
        navigate(`/portal/store/photos/view/${row.uid}`, { state: row });
    }

    useEffect(() => {
        getLabels();
    }, []);


    const dialogPhotoFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={handleDialogCancel}
                >
                    Cancel
                </AppButton>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={isUpdate ? labelUpdate : saveLabelClick}
                    variant="contained">
                    {isUpdate ? 'Update' : 'Save'}
                </AppButton>
            </Box>
        </>
    )
    const dialogSelectPhotoFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={handleDialogCancel}
                >
                    Cancel
                </AppButton>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={handleSaveClick}
                    variant="contained">
                    Upload
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <title> Photos | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Photos
                    </Typography>
                    <AppButton onClick={newLabelClick} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                        New Label
                    </AppButton>
                </Stack>
                <Card>
                    <Box style={{
                        padding: '30px 20px 20px 20px'
                    }}>
                        <input multiple onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box className='u-image-wrap'>
                                    <Link className='u-image' onClick={handleFileClick}>
                                        <Iconify sx={{
                                            width: 40
                                        }} icon="mdi:camera-plus" />
                                        <Typography style={{
                                            fontSize: 12
                                        }}>
                                            Upload image
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography className='c-image-caption'>
                                    Allowed *.jpeg, *.jpg, *.png
                                    max size of 1 MB
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {files.length > 0 &&
                        <Box className='p-images'>
                            <ul>
                                {files.map((file, index) => {
                                    return (
                                        <li key={file}>
                                            <img
                                                src={file}
                                                loading="lazy"
                                            />
                                            <Typography className='item-images-d'>
                                                <Iconify icon={'eva:trash-2-outline'} onClick={(e) => handleFileRemove(e, file, index)} />
                                            </Typography>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                            <Box style={{
                                padding: '20px 10px',
                                textAlign: 'left'
                            }}>
                                <AppButton
                                    variant="contained"
                                    startIcon={<Iconify icon="ic:round-file-upload" />}
                                    onClick={handleSelectLabel}
                                >
                                    Upload
                                </AppButton>
                            </Box>
                        </Box>
                    }
                    <Divider className='s-divider' />
                    <Box style={{
                        padding: '30px 20px'
                    }}>
                        <Typography style={{
                            fontSize: 15,
                            fontWeight: 700
                        }}>Uploaded Photos</Typography>
                        <Box>
                            <div className='sPhotoBody'>

                                {allLabels && allLabels.map((row) => {
                                    const backgroundImage = row?.images?.length > 0 ? `${IMAGE_URL}label/thumbnail?id=${storeId}&img_id=${row?.images[0].image_path}` : NO_IMAGE;
                                    return (
                                        <div
                                            key={row?.uid}
                                            style={{
                                                position: 'relative',
                                                borderRadius: 16,
                                                background: row.record_status === 'D' && '#ffe7b8'
                                            }}>
                                            <Box style={{ textDecoration: 'none', color: '#000' }} onClick={(event) => handleViewPhoto(event, row)}>
                                                <div className='sPhoto'
                                                    style={{
                                                        backgroundImage: `url(${backgroundImage})`
                                                    }}>
                                                    {row?.images.length > 0 &&
                                                        <div className='sPhotoCount'>
                                                            {row?.images.length ? `${row?.images?.length}+ Photos` : ''}
                                                        </div>
                                                    }
                                                </div>
                                                <div className='sPhotoName'>{row?.label}</div>
                                            </Box>
                                            <IconButton style={{
                                                position: 'absolute',
                                                top: 5,
                                                backgroundColor: 'rgba(0, 0, 0, .3)',
                                                color: '#fff',
                                                right: 5
                                            }} size="medium" color="inherit"
                                                onClick={(event) => handleOpenMenu(event, row)}>
                                                <Iconify icon={'eva:more-vertical-fill'} />
                                            </IconButton>
                                        </div>)
                                })}
                            </div>
                        </Box>

                    </Box>
                </Card >
            </Container >

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); handleLabelRename() }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Rename
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenu(); handleLabelAction() }}>
                    <Iconify icon={selectedRow?.record_status === 'A' ? 'ci:hide' : 'ci:show'} sx={{ mr: 2 }} />
                    {selectedRow?.record_status === 'A' ? 'Hide' : 'Activate'}
                </MenuItem>
            </Popover>

            {
                labelDialogOpen &&
                <AppDialog
                    maxWidth='xs'
                    isOpen={labelDialogOpen}
                    id={'store_label_sm'}
                    title={<>Add Label</>}
                    footer={dialogPhotoFooter}
                    handleClickClose={() => { handleCloseMenu(); handleDialogClickClose() }}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Name*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="name"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {
                labelSelectDialogOpen &&
                <AppDialog
                    maxWidth='xs'
                    isOpen={labelSelectDialogOpen}
                    id={'store_label_sm'}
                    title={<>Select Label</>}
                    footer={dialogSelectPhotoFooter}
                    handleClickClose={() => { handleDialogClickClose() }}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <Autocomplete
                                value={photoLabel}
                                options={allLabels}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                renderInput={(params) => <TextField fullWidth {...params} label="Select Label *" />}
                                onChange={(event, newInputValue) => {
                                    setPhotoLabel(newInputValue);
                                }}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default Photos;