import React, { useRef, useState, useEffect } from 'react';
import {
    Card,
    Box,
    Stack,
    Container,
    Typography,
    IconButton,
    TextField,
    Button,
    Autocomplete
} from '@mui/material';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppButton from '../../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addPost, updatePost } from '../../../reduxStore/actions/StoreActions';
import { IMAGE_URL } from '../../../constants/Images';
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_CLOUD_KEY } from '../../../constants/Site';

function AddPost(props) {

    const inputFile = useRef([]);
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const { state } = useLocation();
    const [uid, setUid] = useState('');
    const [deletedFiles, setDeletedFiles] = useState([]);
    const editorRef = useRef(null);

    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        const filesArray = [];
        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            filesArray.push(file);
            return URL.createObjectURL(file);
        });
        setFiles([...imagesArray, ...files]);
        setFilesData([...filesArray, ...filesData]);
    }

    const handleFileRemove = (e, file, index) => {
        setFiles(files.filter((f) => f != file));
        setFilesData(filesData.splice(index, 1));
    }

    const handlePostFileRemove = (file) => {
        setDeletedFiles([...deletedFiles, file.uid]);
        setUploadedFiles(uploadedFiles.filter(f => f.uid !== file.uid));
    }

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            console.log("filesData", filesData);
            if (filesData.length) {
                filesData.forEach((image_file) => {
                    formData.append('user_file', image_file);
                });
            }
            let desc = "";
            if (editorRef.current) {
                desc = editorRef.current.getContent();
            }

            formData.append('form_data', JSON.stringify(
                {
                    store_id: storeId,
                    title: title,
                    description: desc
                }
            ));
            const res = await dispatch(addPost(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/posts');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            console.log("filesData", filesData);
            if (filesData.length) {
                filesData.forEach((image_file) => {
                    formData.append('user_file', image_file);
                });
            }
            let desc = "";
            if (editorRef.current) {
                desc = editorRef.current.getContent();
            }
            formData.append('form_data', JSON.stringify(
                {
                    store_id: storeId,
                    uid: uid,
                    title: title,
                    description: desc
                }
            ));
            if (deletedFiles.length > 0) {
                const dFiles = [];
                deletedFiles.forEach((d) => {
                    if (d.length > 0) {
                        dFiles.push(d);
                    }
                });
                formData.append('deleted_files', JSON.stringify(dFiles));
            }
            const res = await dispatch(updatePost(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate('/portal/store/posts');
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        setUid(state?.uid);
        setTitle(state?.title);
        setDesc(state?.description);
        setUploadedFiles(state?.images);
    }, []);

    return (
        <>
            <Helmet>
                <title> Add Post | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        <Link style={{ textDecoration: 'none' }} to="/portal/store/posts">
                            <IconButton><Iconify width="25" icon="eva:arrow-back-outline" /></IconButton>
                        </Link>  {uid ? 'Update Post' : 'Add Post'}
                    </Typography>
                </Stack>

                <Box className='item-add-card'>
                    <Card className='item-add-card-wrap'>
                        <input multiple onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Post Images (Maximum 8 images)
                        </Typography>
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box className='c-image-wrap c-item-image-wrap'>
                                    <Link className='c-image c-item-image' onClick={handleFileClick}>
                                        <Iconify sx={{
                                            width: 40
                                        }} icon="mdi:camera-plus" />
                                        <Typography style={{
                                            fontSize: 12
                                        }}>
                                            Upload image
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography className='c-image-caption'>
                                    Allowed *.jpeg, *.jpg, *.png
                                    max size of 1 MB
                                </Typography>
                            </Box>
                            <Box className='item-images'>
                                <ul>
                                    {files && files.map((file, index) => {
                                        return (
                                            <li key={file}>
                                                <img
                                                    src={file}
                                                    loading="lazy"
                                                />
                                                <Typography className='item-images-d'>
                                                    <Iconify icon={'eva:trash-2-outline'} onClick={(e) => handleFileRemove(e, file, index)} />
                                                </Typography>
                                            </li>
                                        );
                                    })
                                    }
                                    {uploadedFiles && uploadedFiles.map((file, index) => {
                                        return (
                                            <li key={file}>
                                                <img
                                                    src={`${IMAGE_URL}store/post/thumbnail?id=${storeId}&img_id=${file.image_path}`}
                                                    loading="lazy"
                                                />
                                                <Typography className='item-images-d'>
                                                    <Iconify icon={'eva:trash-2-outline'} onClick={(e) => handlePostFileRemove(file)} />
                                                </Typography>
                                            </li>
                                        );
                                    })
                                    }
                                </ul>
                            </Box>
                        </Box>
                    </Card>
                    <Card className='item-add-card-wrap'>
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Post Info
                        </Typography>
                        <Stack spacing={3}>
                            <TextField
                                value={title}
                                autoFocus
                                autoComplete='off'
                                name="post_name"
                                label="Title *"
                                onChange={(event) => setTitle(event.target.value)}
                            />
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey={TINY_CLOUD_KEY}
                                initialValue={desc}
                                init={{
                                    placeholder: "Description (Optional)",
                                    branding: false,
                                    height: 300,
                                    plugins: 'link image code lists table',
                                    toolbar: 'backcolor forecolor | fontselect | lists formatselect | ' +
                                        'bold italic | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent '
                                }}
                            />

                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={uid ? handleUpdateClick : handleSaveClick}
                                fullWidth size='large'
                                variant="contained">
                                {uid ? "Update" : "Save"}
                            </AppButton>
                        </Stack>
                    </Card>
                </Box>
            </Container >
        </>
    );
}

export default AddPost;