import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import React from 'react';
import "./Items.css"
import { MuiChipsInput } from 'mui-chips-input'
import { SketchPicker } from 'react-color';
import Iconify from '../../compoments/iconify/Iconify';

function ItemOptions({ itemOption, index, deleteVariantClick, onOptionChange, handleChipChange }) {
    console.log("itemOption", itemOption);
    return (
        <Box className="add-variant-wrapper">
            <Box className="variant-option-group">
                <Autocomplete
                    value={itemOption.selectedOption}
                    options={itemOption.options}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    renderInput={(params) => <TextField {...params} label="Option name*" />}
                    onChange={(event, newInputValue) => {
                        onOptionChange(event, newInputValue, index);
                    }}
                />
            </Box>
            <Box className="variant-form-group">
                <Box className="variant-form-control">
                    <MuiChipsInput fullWidth hideClearAll value={itemOption.optionValues}
                        onChange={(chips) => handleChipChange(chips, index)} />
                </Box>
                <Box className="variant-action-icon">
                    <IconButton size="large" color="inherit" onClick={(event) => { deleteVariantClick(event, itemOption, index) }}>
                        <Iconify icon={'ph:trash'} />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default ItemOptions;