import { Box } from '@mui/material';
import React from 'react';
import Iconify from '../../compoments/iconify/Iconify';

function ItemVariantImageSelectionIcon({ file, selectedVariantImages, uploadedVariantImages }) {
    let isSelected = false;
    selectedVariantImages?.map(f => {
        if (f.item_image_id === file.item_image_id) {
            isSelected = true;
        }
    });
    uploadedVariantImages?.map(f => {
        if (f.id === file.id) {
            isSelected = true;
        }
    });
    return (
        <>
            {isSelected &&
                <Box className="uploaded-img-overlay-selected">
                    <Box className="white-color">
                        <Box className="item-selection-tick">
                            <Iconify sx={{
                                width: 40
                            }} icon="mdi:tick" />
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
}

export default ItemVariantImageSelectionIcon;