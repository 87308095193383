import { Autocomplete, Box, IconButton, MenuItem, Popover, TextField } from '@mui/material';
import React, { useState } from 'react';
import Iconify from '../../compoments/iconify/Iconify';

function ItemTableData({ variant, allUnits, index, handleFormValueChange, deleteVariantRowClick }) {
    const [popoverOpen, setPopoverOpen] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});
    const handleOpenMenu = (event, row) => {
        setPopoverOpen(event.currentTarget);
        setSelectedRow(row);
    };

    const handleCloseMenu = () => {
        setPopoverOpen(null);
    };

    return (
        <>
            <Box className="item-table-data-row">
                <Box className="item-col-price">
                    <TextField
                        value={variant?.original_price}
                        size="small"
                        autoComplete='off'
                        type='number'
                        name="original_price"
                        label="Price *"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className="item-col-discounted-price">
                    <TextField
                        value={variant?.discounted_price}
                        size="small"
                        autoComplete='off'
                        type='number'
                        name="discounted_price"
                        label="Discounted Price *"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className="item-col-sku">
                    <TextField
                        value={variant?.sku_code}
                        size="small"
                        autoComplete='off'
                        type='text'
                        name="sku_code"
                        label="SKU ID"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className="item-col-qty">
                    <TextField
                        value={variant?.combo_quantity}
                        size="small"
                        autoComplete='off'
                        type='number'
                        name="combo_quantity"
                        label="Quantity"
                        onChange={(event) => handleFormValueChange(event, index)}
                    />
                </Box>
                <Box className="item-col-unit">
                    <Box className="item-col-unit-text">
                        <TextField
                            value={variant?.unit_value}
                            size="small"
                            autoComplete='off'
                            type='number'
                            name="unit_value"
                            label="Unit *"
                            onChange={(event) => handleFormValueChange(event, index)}
                        />
                    </Box>
                    <Box className="item-col-unit-box">
                        <Autocomplete
                            size="small"
                            value={variant?.selected_unit}
                            options={allUnits}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                            renderInput={(params) => <TextField {...params} label="Unit *" />}
                            onChange={(event, newInputValue) => handleFormValueChange(event, index, newInputValue)}
                        />
                    </Box>
                </Box>
                <Box className="item-col-action">
                    <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, variant)}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </Box>
            </Box>

            <Popover
                open={Boolean(popoverOpen)}
                anchorEl={popoverOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem sx={{ color: 'error.main' }} onClick={(event) => { handleCloseMenu(); deleteVariantRowClick(event, variant, index) }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete variant
                </MenuItem>
            </Popover>
        </>
    );
}

export default ItemTableData;