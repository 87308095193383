import './App.css';
import Routers from './routers/Routers';
import ThemeProvider from './portal/theme';
import { Provider, useSelector } from 'react-redux';
import Store from './reduxStore/Store';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import Iconify from './site/components/iconify/Iconify';

function App() {

  const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <Iconify icon="ion:close-sharp" color="#fff"/>
      </IconButton>
    );
  }

  return (
    <Provider store={Store} >
      <ThemeProvider>
        <SnackbarProvider maxSnack={1} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
          action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
          <div className="App">
            <Routers />
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
