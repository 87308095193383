import React from 'react';
import { Link } from 'react-router-dom';
import "./Logo.css";

function Logo({ color }) {
    return (
        <>
            <div className='logo'>
                <Link to="/" style={{
                    color: color
                }}>
                    <span>T</span>
                    rue
                    <span style={{
                        color: 'rgb(245, 124, 0)'
                    }}>M</span>
                    enu
                </Link>
            </div>
        </>
    );
}

export default Logo;