import { Box, Card, Checkbox, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CirclePicker, TwitterPicker } from 'react-color';
import AppButton from '../../../components/app-button/AppButton';
import { getStoreDetails, updateStoreByField } from '../../../reduxStore/actions/StoreActions';

function ThemeConfig(props) {

    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const storeDetails = storeSelector?.details;
    let btnTxt = "";
    if (storeDetails?.is_booking === true) {
        btnTxt = "Book";
    } else if (storeDetails?.is_order === true) {
        btnTxt = "Add";
    } else {
        btnTxt = "Enquiry";
    }
    const [isLoading, setIsLoading] = useState(false);
    const [pageBackground, setPageBackground] = useState('#FFFFFF');
    const [themeBgColor, setThemeBgColor] = useState('#8BC34A');
    const [themeColor, setThemeColor] = useState('#FFFFFF');
    const [showOffers, setShowOffers] = useState(true);
    const [offersHeading, setOffersHeading] = useState("Today's Offers");
    const [offersBgColor, setOffersBgColor] = useState('#F78DA7');
    const [offersColor, setOffersColor] = useState('#FFFFFF');
    const [homeHeading, setHomeHeading] = useState('Categories');
    const [showPrimaryButton, setShowPrimaryButton] = useState(true);
    const [primaryButtonTitle, setPrimaryButtonTitle] = useState(btnTxt);
    const [primaryButtonBgColor, setPrimaryButtonBgColor] = useState('#FF9800');
    const [primaryButtonColor, setPrimaryButtonColor] = useState('#FFFFFF');
    const [isDenseMenu, setIsDenseMenu] = useState(false);
    const [showReviews, setShowReviews] = useState(true);
    const allThemeConfigs = storeDetails?.theme_config ? JSON.parse(storeDetails?.theme_config) : {};

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const twColors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#F78DA7", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000", "#ffffff"];

    const resetAll = async () => {
        await setPageBackground("#FFFFFF");
        await setThemeBgColor("#8BC34A");
        await setThemeColor("#FFFFFF");
        await setShowOffers(true);
        await setOffersHeading("Today's Offers");
        await setOffersBgColor("#F78DA7");
        await setOffersColor("#FFFFFF");
        await setHomeHeading("Categories");
        await setShowPrimaryButton(true);
        await setPrimaryButtonTitle(btnTxt);
        await setPrimaryButtonBgColor("#FF9800");
        await setPrimaryButtonColor("#ffffff")
        await setIsDenseMenu(false);
        await setShowReviews(true);
        const themeConfig = {
            pageBackground: "#FFFFFF",
            themeBgColor: "#8BC34A",
            themeColor: "#FFFFFF",
            showOffers: true,
            offersHeading: "Today's Offers",
            offersBgColor: "#F78DA7",
            offersColor: "#FFFFFF",
            homeHeading: "Categories",
            showPrimaryButton: true,
            primaryButtonTitle: btnTxt,
            primaryButtonBgColor: "#FF9800",
            primaryButtonColor: "#ffffff",
            isDenseMenu: false,
            showReviews: true
        }
        await handleSave(themeConfig);
    }

    const handlePageBackground = (color) => {
        setPageBackground(color.hex);
    }
    const handleThemeBgColor = (color) => {
        setThemeBgColor(color.hex);
    }
    const handleThemeColor = (color) => {
        setThemeColor(color.hex);
    }
    const handleOffersBgColor = (color) => {
        setOffersBgColor(color.hex);
    }
    const handleOffersColor = (color) => {
        setOffersColor(color.hex);
    }
    const handlePrimaryButtonBgColor = (color) => {
        setPrimaryButtonBgColor(color.hex);
    }
    const handlePrimaryButtonColor = (color) => {
        setPrimaryButtonColor(color.hex);
    }
    const handleShowReviews = (event) => {
        setShowReviews(!showReviews);
    }
    const handleShowOffers = (event) => {
        setShowOffers(!showOffers);
    }
    const handleShowPrimaryButton = (event) => {
        setShowPrimaryButton(!showPrimaryButton);
    }
    const handleIsDenseMenu = (event) => {
        setIsDenseMenu(!isDenseMenu);
    }

    const handleSave = async (defaultConfig) => {
        try {
            setIsLoading(true);
            const themeConfig = {
                pageBackground: pageBackground,
                themeBgColor: themeBgColor,
                themeColor: themeColor,
                showOffers: showOffers,
                offersHeading: offersHeading,
                offersBgColor: offersBgColor,
                offersColor: offersColor,
                homeHeading: homeHeading,
                showPrimaryButton: showPrimaryButton,
                primaryButtonTitle: primaryButtonTitle,
                primaryButtonBgColor: primaryButtonBgColor,
                primaryButtonColor: primaryButtonColor,
                isDenseMenu: isDenseMenu,
                showReviews: showReviews
            }
            console.log(themeConfig);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'theme_config',
                name: JSON.stringify(defaultConfig ? defaultConfig : themeConfig),
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setThemeValues();
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const setThemeValues = () => {
        if (Object.keys(allThemeConfigs).length !== 0) {
            setPageBackground(allThemeConfigs?.pageBackground);
            setThemeBgColor(allThemeConfigs?.themeBgColor);
            setThemeColor(allThemeConfigs?.themeColor);
            setShowOffers(allThemeConfigs?.showOffers);
            setOffersHeading(allThemeConfigs?.offersHeading);
            setOffersBgColor(allThemeConfigs?.offersBgColor);
            setOffersColor(allThemeConfigs?.offersColor);
            setHomeHeading(allThemeConfigs?.homeHeading);
            setShowPrimaryButton(allThemeConfigs?.showPrimaryButton);
            setPrimaryButtonTitle(allThemeConfigs?.primaryButtonTitle);
            setPrimaryButtonBgColor(allThemeConfigs?.primaryButtonBgColor);
            setPrimaryButtonColor(allThemeConfigs?.primaryButtonColor)
            setIsDenseMenu(allThemeConfigs?.isDenseMenu);
            setShowReviews(allThemeConfigs?.showReviews);
        }
    }

    React.useEffect(() => {
        setThemeValues();
    }, []);


    return (
        <>
            <Box>
                <Card>
                    <Box style={{ padding: 20 }}>
                        <Typography style={{
                            marginBottom: 20
                        }}>Store/ Business theme</Typography>
                        <Stack spacing={3}>
                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Page Background Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change the page background color of your store</Typography>
                                <TwitterPicker
                                    onChangeComplete={handlePageBackground}
                                    width='320px'
                                    color={pageBackground}
                                    colors={twColors} />
                            </Box>
                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Theme Background Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change the category active background color, fab button background color and others</Typography>
                                <TwitterPicker
                                    onChangeComplete={handleThemeBgColor}
                                    width='320px'
                                    color={themeBgColor}
                                    colors={twColors} />
                            </Box>

                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Theme Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change the category active color, fab button color and others</Typography>
                                <TwitterPicker
                                    onChangeComplete={handleThemeColor}
                                    width='320px'
                                    color={themeColor}
                                    colors={twColors} />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleShowOffers}
                                            value={showOffers}
                                            checked={showOffers}
                                        />}
                                    label="Show offers" />
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>Show offers on the store/business home page</Typography>
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    name="offers_heading"
                                    label="Offers heading"
                                    value={offersHeading}
                                    onChange={(event) => setOffersHeading(event.target.value)}
                                />
                            </Box>
                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Offer Background Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change offers card background color</Typography>
                                <TwitterPicker
                                    onChangeComplete={handleOffersBgColor}
                                    width='320px'
                                    color={offersBgColor}
                                    colors={twColors} />
                            </Box>
                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Offer Text Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change offers card color</Typography>
                                <TwitterPicker
                                    onChangeComplete={handleOffersColor}
                                    width='320px'
                                    color={offersColor}
                                    colors={twColors} />
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    name="homepage_heading"
                                    label="Homepage heading"
                                    value={homeHeading}
                                    onChange={(event) => setHomeHeading(event.target.value)}
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleShowPrimaryButton}
                                            value={showPrimaryButton}
                                            checked={showPrimaryButton}
                                        />
                                    }
                                    label="Show Primary Button" />
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>Show primary button on the store/business home page</Typography>
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    name="primary_btn_title"
                                    label="Primary Button Title"
                                    value={primaryButtonTitle}
                                    onChange={(event) => setPrimaryButtonTitle(event.target.value)}
                                />
                            </Box>
                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Primary Button Background Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change background color of button </Typography>
                                <TwitterPicker
                                    onChangeComplete={handlePrimaryButtonBgColor}
                                    width='320px'
                                    color={primaryButtonBgColor}
                                    colors={twColors} />
                            </Box>

                            <Box>
                                <Typography style={{
                                    fontSize: 15
                                }}>Primary Button Text Color</Typography>
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will change text color of button </Typography>
                                <TwitterPicker
                                    onChangeComplete={handlePrimaryButtonColor}
                                    width='320px'
                                    color={primaryButtonColor}
                                    colors={twColors} />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleIsDenseMenu}
                                            value={isDenseMenu}
                                            checked={isDenseMenu}
                                        />}
                                    label="Dense Menu" />
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>Dense menu will not show the images on the store/ business homepage</Typography>
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleShowReviews}
                                            value={showReviews}
                                            checked={showReviews}
                                        />}
                                    label="Show Reviews" />
                                <Typography style={{
                                    marginBottom: 12,
                                    fontSize: 12
                                }}>This will enable/disable show review on store/ business homepage</Typography>
                            </Box>

                            <Box className='theme-btn-wrap'>
                                <AppButton
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    fullWidth size='large'
                                    variant="contained"
                                    onClick={() => handleSave('')}
                                >
                                    Save
                                </AppButton>
                                <AppButton
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    fullWidth size='large'
                                    variant="text"
                                    onClick={resetAll}
                                >
                                    Reset
                                </AppButton>
                            </Box>
                        </Stack>
                    </Box>
                </Card>
            </Box>
        </>
    );
}

export default ThemeConfig;