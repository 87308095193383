import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { WEB_DOMAIN } from '../../constants/Images';
import Logo from '../logo/Logo';
import "./Footer.css";

function Footer(props) {
    return (
        <>
            <Box>
                <Box className="app-footer">
                    <Container>
                        <Typography style={{
                            fontSize: 14
                        }}><span style={{ fontSize: 13 }}>Powered by</span> <a href={WEB_DOMAIN} target='_blank' style={{
                            color: '#000',
                            fontSize: 15,
                            fontWeight: 600
                        }}>
                                <span>T</span>
                                rue
                                <span style={{
                                    color: 'rgb(245, 124, 0)'
                                }}>M</span>
                                enu
                            </a><sup>&reg;</sup> &copy; {(new Date().getFullYear())}.</Typography>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default Footer;