import {
    ALL_CATEGORIES,
    ALL_ITEMS,
    CATEGORY_ACTION,
    CATEGORY_DELETE,
    ITEM_ACTION,
    ALL_STORES,
    ALL_USERS,
    ALL_LABELS,
    LABEL_ACTION,
    ALL_MASTER_CATEGORIES,
    MASTER_CATEGORY_DELETE,
    MASTER_CATEGORY_ACTION,
    ITEM_DELETE,
    STORE_ACTION,
    STORE_DELETE,
    USER_ACTION,
    USER_DELETE,
    DETAILS,
    ALL_OFFERS,
    OFFER_ACTION,
    OFFER_DELETE,
    ALL_REVIEWS,
    REVIEW_REPLY,
    ALL_POSTS,
    POST_ACTION,
    POST_DELETE,
    ALL_REVIEWS_FILTER,
    ALL_ANALYTICS,
    ALL_ENQUIRIES,
    APP_QR_DOWNLOAD,
    ALL_UNITS,
    UNIT_ACTION,
    UNIT_DELETE,
    ALL_BRANDS,
    BRAND_ACTION,
    BRAND_DELETE,
    SITE_ENQUIRIES,
    SITE_BOOK_DEMOS,
    SITE_CONFIG
} from "../actions/StoreActionTypes";

const initialState = {
    isLoading: false,
    isAppQrDownload: false,
    details: {},
    categories: [],
    labels: [],
    items: [],
    stores: [],
    users: [],
    masterCategories: [],
    offers: [],
    reviews: [],
    posts: [],
    analytics: [],
    enquiries: [],
    bookings: [],
    orders: [],
    units: [],
    siteBookDemos: [],
    siteEnquiries: [],
    siteConfig: [],
};
function StoreReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DETAILS:
            return {
                ...state,
                details: payload
            };
        case ALL_BRANDS:
            return {
                ...state,
                brands: payload
            };
        case BRAND_ACTION:
            return {
                ...state,
                brands: state.brands.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case BRAND_DELETE:
            return {
                ...state,
                brands: state.brands.filter((item) => item.uid !== payload.uid)
            };
        case ALL_CATEGORIES:
            return {
                ...state,
                categories: payload
            };
        case CATEGORY_ACTION:
            return {
                ...state,
                categories: state.categories.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case CATEGORY_DELETE:
            return {
                ...state,
                categories: state.categories.filter((item) => item.uid !== payload.uid)
            };
        case ALL_ITEMS:
            return {
                ...state,
                items: payload
            };
        case ITEM_ACTION:
            return {
                ...state,
                items: state.items.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case ITEM_DELETE:
            return {
                ...state,
                items: state.items.filter((item) => item.uid !== payload.uid)
            };
        case ALL_MASTER_CATEGORIES:
            return {
                ...state,
                masterCategories: payload
            };
        case MASTER_CATEGORY_ACTION:
            return {
                ...state,
                masterCategories: state.masterCategories.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case MASTER_CATEGORY_DELETE:
            return {
                ...state,
                masterCategories: state.masterCategories.filter((item) => item.uid !== payload.uid)
            };
        case ALL_STORES:
            return {
                ...state,
                stores: payload
            };
        case STORE_ACTION:
            return {
                ...state,
                stores: state.stores.map((item) => {
                    return item.uid == payload.store_id ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case STORE_DELETE:
            return {
                ...state,
                stores: state.stores.filter((item) => item.uid != payload.store_id)
            };
        case ALL_USERS:
            return {
                ...state,
                users: payload
            };
        case USER_ACTION:
            return {
                ...state,
                users: state.users.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case USER_DELETE:
            return {
                ...state,
                users: state.users.filter((item) => item.uid !== payload.uid)
            };
        case ALL_LABELS:
            return {
                ...state,
                labels: payload
            };
        case LABEL_ACTION:
            return {
                ...state,
                labels: state.labels.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case ALL_OFFERS:
            return {
                ...state,
                offers: payload
            };
        case OFFER_ACTION:
            return {
                ...state,
                offers: state.offers.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case OFFER_DELETE:
            return {
                ...state,
                offers: state.offers.filter((item) => item.uid !== payload.uid)
            };
        case ALL_REVIEWS:
            return {
                ...state,
                reviews: payload
            };
        case REVIEW_REPLY:
            const allReviews = state?.reviews?.reviews?.map((item) => {
                return item.uid === payload.uid ?
                    {
                        ...item,
                        store_review: payload.store_review,
                        store_review_create_at: payload.store_review_create_at,
                    } : item;
            })
            return {
                ...state,
                reviews: {
                    ...state?.reviews,
                    reviews: allReviews,
                }
            };
        case ALL_REVIEWS_FILTER:
            return {
                ...state,
                reviews: {
                    ...state?.reviews,
                    reviews: payload?.reviews,
                }
            };
        case ALL_POSTS:
            return {
                ...state,
                posts: payload
            };
        case POST_ACTION:
            return {
                ...state,
                posts: state.posts.map((item) => {
                    return item.uid === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case POST_DELETE:
            return {
                ...state,
                posts: state.posts.filter((item) => item.uid !== payload.uid)
            };
        case ALL_ANALYTICS:
            return {
                ...state,
                analytics: payload
            };
        case ALL_ENQUIRIES:
            return {
                ...state,
                enquiries: payload
            };
        case APP_QR_DOWNLOAD:
            return {
                ...state,
                isAppQrDownload: payload
            };
        case ALL_UNITS:
            return {
                ...state,
                units: payload
            };
        case UNIT_ACTION:
            return {
                ...state,
                units: state.units.map((item) => {
                    return item.id === payload.uid ? { ...item, record_status: payload.record_status } : item;
                })
            };
        case UNIT_DELETE:
            return {
                ...state,
                units: state.units.filter((item) => item.id !== payload.uid)
            };
        case SITE_BOOK_DEMOS:
            return {
                ...state,
                siteBookDemos: payload
            };
        case SITE_ENQUIRIES:
            return {
                ...state,
                siteEnquiries: payload
            };
        case SITE_CONFIG:
            return {
                ...state,
                siteConfig: payload
            };
        default:
            return state;
    }

};

export default StoreReducer;