import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { account } from '../../_mock/account';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../reduxStore/actions/AuthActions';
import { IMAGE_URL, NO_USER_IMAGE } from '../../../constants/Images';

const MENU_OPTIONS = [
    {
        label: 'Profile',
        icon: 'eva:person-fill',
    },
];

export default function AccountPopover() {
    const auth = useSelector((state) => state.auth);
    const profileDetails = auth?.userDetails;
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (action) => {
        setOpen(null);
        switch (action) {
            case 'profile':
                navigate('/user/profile');
                break;
            case 'logout':
                dispatch(logout());
                navigate('/login');
                break;
        }

    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={profileDetails?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${profileDetails?.profile_pic}` : NO_USER_IMAGE} alt={profileDetails?.name} />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        position: 'relative',
                        p: 0,
                        mt: 0.90,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    }
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {profileDetails?.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClose('profile')}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={() => handleClose('logout')} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
