export const DETAILS = "DETAILS";
export const ALL_BRANDS = "ALL_BRANDS";
export const BRAND_ACTION = "BRAND_ACTION";
export const BRAND_DELETE = "BRAND_DELETE";

export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const CATEGORY_ACTION = "CATEGORY_ACTION";
export const CATEGORY_DELETE = "CATEGORY_DELETE";


export const ALL_ITEMS = "ALL_ITEMS";
export const ITEM_ACTION = "ITEM_ACTION";
export const ITEM_DELETE = "ITEM_DELETE";


export const ALL_USERS = "ALL_USERS";
export const USER_ACTION = "USER_ACTION";
export const USER_DELETE = "USER_DELETE";

export const ALL_STORES = "ALL_STORES";
export const STORE_ACTION = "STORE_ACTION";
export const STORE_DELETE = "STORE_DELETE";

export const ALL_LABELS = "ALL_LABELS";
export const LABEL_ACTION = "LABEL_ACTION";
export const LABEL_DELETE = "LABEL_DELETE";

export const ALL_MASTER_CATEGORIES = "ALL_MASTER_CATEGORIES";
export const MASTER_CATEGORY_ACTION = "MASTER_CATEGORY_ACTION";
export const MASTER_CATEGORY_DELETE = "MASTER_CATEGORY_DELETE";

export const ALL_OFFERS = "ALL_OFFERS";
export const OFFER_ACTION = "OFFER_ACTION";
export const OFFER_DELETE = "OFFER_DELETE";

export const ALL_REVIEWS = "ALL_REVIEWS";
export const REVIEW_REPLY = "REVIEW_REPLY";
export const ALL_REVIEWS_FILTER = "ALL_REVIEWS_FILTER";

export const ALL_POSTS = "ALL_POSTS";
export const POST_ACTION = "POST_ACTION";
export const POST_DELETE = "POST_DELETE";

export const ALL_ANALYTICS = "ALL_ANALYTICS";

export const ALL_ENQUIRIES = "ALL_ENQUIRIES";
export const APP_QR_DOWNLOAD = 'APP_QR_DOWNLOAD';

export const ALL_UNITS = "ALL_UNITS";
export const UNIT_ACTION = "UNIT_ACTION";
export const UNIT_DELETE = "UNIT_DELETE";

export const SITE_BOOK_DEMOS = "SITE_BOOK_DEMOS";
export const SITE_ENQUIRIES = "SITE_ENQUIRIES";

export const SITE_CONFIG = "SITE_CONFIG";