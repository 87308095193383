import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import StoreLayout from '../layouts/site/StoreLayout';
import AboutUs from '../pages/about-us/AboutUs';
import BookDemo from '../pages/book-demo/BookDemo';
import BusinessName from '../pages/business-name/BusinessName';
import ContactUs from '../pages/contact-us/ContactUs';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';
import Privacy from '../pages/privacy/Privacy';
import Store from '../pages/store/Store';
import StoreItemDetails from '../pages/store/StoreItemDetails';
import StoreItems from '../pages/store/StoreItems';
import StoreMenu from '../pages/store/StoreMenu';
import StoreOffers from '../pages/store/StoreOffers';
import StorePhotos from '../pages/store/StorePhotos';
import StoreReviews from '../pages/store/StoreReviews';
import Terms from '../pages/terms/Terms';
import UserBookings from '../pages/users/UserBookings';
import UserProfile from '../pages/users/UserProfile';
import PortalLayout from '../portal/layouts/portal/PortalLayout';
import Analytics from '../portal/pages/analytics/Analytics';
import Bookings from '../portal/pages/bookings/Bookings';
import AddCategory from '../portal/pages/categories/AddCategory';
import Categories from '../portal/pages/categories/Categories';
import Dashboard from '../portal/pages/dashboard/Dashboard';
import Enquiries from '../portal/pages/enquiries/Enquiries';
import AddItem from '../portal/pages/items/AddItem';
import Items from '../portal/pages/items/Items';
import AddMasterCategory from '../portal/pages/master-categories/AddMasterCategory';
import MasterCategories from '../portal/pages/master-categories/MasterCategories';
import Offers from '../portal/pages/offers/Offers';
import Orders from '../portal/pages/orders/Orders';
import Photos from '../portal/pages/photos/Photos';
import ViewPhotos from '../portal/pages/photos/ViewPhotos';
import AddPost from '../portal/pages/posts/AddPost';
import Posts from '../portal/pages/posts/Posts';
import Profile from '../portal/pages/profile/Profile';
import Reviews from '../portal/pages/reviews/Reviews';
import Settings from '../portal/pages/settings/Settings';
import AddStore from '../portal/pages/stores/AddStore';
import Stores from '../portal/pages/stores/Stores';
import AddUser from '../portal/pages/users/AddUser';
import Users from '../portal/pages/users/Users';
import { getUserSession } from '../reduxStore/actions/AuthActions';
import { IS_AUTH_LOADED, LOGGEDIN_USER_DETAILS, LOGOUT } from '../reduxStore/actions/AuthTypes';
import ProtectedRoutes from './ProtectedRoutes';
import WebSiteLayout from '../layouts/site/WebSiteLayout';
import SiteLayout from '../site/layouts/SiteLayout';
import SiteHome from '../site/pages/home/Home';
import SitePosts from '../site/pages/posts/Posts';
import SitePostDetails from '../site/pages/post-details/PostDetails';
import SiteOffers from '../site/pages/offers/Offers';
import SiteAbout from '../site/pages/about/About';
import SiteItemDetails from '../site/pages/item-details/ItemDetails';
import SiteSearch from '../site/pages/search/Search';
import SiteProfile from '../site/pages/users/UserProfile';
import { getStoreDetails } from '../reduxStore/actions/StoreActions';
import Units from '../portal/pages/units/Units';
import AddUnits from '../portal/pages/units/AddUnits';
import Brands from '../portal/pages/brands/Brands';
import AddBrand from '../portal/pages/brands/AddBrand';
import SiteEnquiries from '../portal/pages/site-enquiries/SiteEnquiries';
import SiteBookDemos from '../portal/pages/site-book-demos/SiteBookDemos';
import Config from '../portal/pages/config/Config';

function Routers(props) {
    const auth = useSelector((state) => state.auth);
    const isAuthLoaded = auth?.isAuthLoaded;
    const [authOk, setIsAuthOk] = React.useState(false);
    const dispatch = useDispatch();
    const loggedInDetails = async () => {
        const userDetails = await getUserSession();
        setIsAuthOk(true);
        if (!userDetails) {
            await dispatch({
                type: LOGOUT
            });
            return;
        }
        const userSession = await JSON.parse(userDetails);
        await dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: userSession
        });
        if (userSession?.primary_store_id) {
            await dispatch(getStoreDetails(userSession?.primary_store_id));
        }
        await dispatch({
            type: IS_AUTH_LOADED,
            payload: true
        });
    }

    useEffect(() => {
        loggedInDetails();
    }, [isAuthLoaded]);
    return (
        <Routes>
            <Route element={<WebSiteLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/book-a-demo" element={<BookDemo />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                {authOk &&
                    <Route element={<ProtectedRoutes allowedRoles={['user', 'owner', 'agent']} />}>
                        <Route path='/user/profile' element={<SiteProfile />} />
                        <Route path='/user/bookings' element={<UserBookings />} />
                    </Route>
                }
            </Route>
            <Route path='/:store_url' element={<SiteLayout />}>
                <Route path='' element={<SiteHome />} />
                <Route path="about" element={<SiteAbout />} />
                <Route path="offers" element={<SiteOffers />} />
                <Route path="posts" element={<SitePosts />} />
                <Route path="post/details/:url" element={<SitePostDetails />} />
            </Route>
            <Route path='/:store_url'>
                <Route path="item-details/:id" element={<SiteItemDetails />} />
                <Route path="search" element={<SiteSearch />} />
            </Route>

            {/* <Route path='/:store_name' element={<StoreLayout />}>
                <Route path='' element={<StoreMenu />} />
                <Route path='about' element={<Store />} />
                <Route path='offers' element={<StoreOffers />} />
                <Route path='photos' element={<StorePhotos />} />
                <Route path='reviews' element={<StoreReviews />} />
            </Route> */}
            {/* <Route path='/:store_name/items' element={<StoreItems />} />
            <Route path='/:store_name/item-details' element={<StoreItemDetails />} /> */}

            <Route path="/business-name" element={<BusinessName />} />
            <Route path='/login' element={<Login />} />
            {authOk &&
                <Route element={<ProtectedRoutes allowedRoles={['owner']} />}>
                    <Route path='/portal' element={<PortalLayout />} >
                        <Route path='/portal' element={<Navigate to="/portal/dashboard" replace />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='store/brands' element={<Brands />} />
                        <Route path='store/brands/add' element={<AddBrand />} />
                        <Route path='store/brands/edit/:id' element={<AddBrand />} />
                        <Route path='store/categories' element={<Categories />} />
                        <Route path='store/categories/add' element={<AddCategory />} />
                        <Route path='store/categories/edit/:id' element={<AddCategory />} />
                        <Route path='store/items' element={<Items />} />
                        <Route path='store/items/add' element={<AddItem />} />
                        <Route path='store/items/edit/:id' element={<AddItem />} />
                        <Route path='store/photos' element={<Photos />} />
                        <Route path='store/photos/view/:id' element={<ViewPhotos />} />
                        <Route path='store/offers' element={<Offers />} />
                        <Route path='store/posts' element={<Posts />} />
                        <Route path='store/posts/add' element={<AddPost />} />
                        <Route path='store/posts/edit/:id' element={<AddPost />} />
                        <Route path='store/bookings' element={<Bookings />} />
                        <Route path='store/orders' element={<Orders />} />
                        <Route path='store/enquiries' element={<Enquiries />} />
                        <Route path='store/settings' element={<Settings />} />
                        <Route path='store/analytics' element={<Analytics />} />
                        <Route path='store/reviews' element={<Reviews />} />
                        <Route path='profile' element={<Profile />} />
                    </Route>
                </Route>
            }
            {authOk &&
                <Route element={<ProtectedRoutes allowedRoles={['agent']} />}>
                    <Route path='/agent' element={<PortalLayout />} >
                        <Route path='/agent' element={<Navigate to="/agent/dashboard" replace />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='stores' element={<Stores />} />
                        <Route path='stores/add' element={<AddStore />} />
                        <Route path='stores/edit/:id' element={<AddStore />} />
                    </Route>
                </Route>
            }
            {authOk &&
                <Route element={<ProtectedRoutes allowedRoles={['super_admin']} />}>
                    <Route path='/admin' element={<PortalLayout />} >
                        <Route path='/admin' element={<Navigate to="/admin/dashboard" replace />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='categories' element={<MasterCategories />} />
                        <Route path='categories/add' element={<AddMasterCategory />} />
                        <Route path='categories/edit/:id' element={<AddMasterCategory />} />
                        <Route path='stores' element={<Stores />} />
                        <Route path='stores/add' element={<AddStore />} />
                        <Route path='stores/edit/:id' element={<AddStore />} />
                        <Route path='users' element={<Users />} />
                        <Route path='users/add' element={<AddUser />} />
                        <Route path='users/edit/:id' element={<AddUser />} />
                        <Route path='units' element={<Units />} />
                        <Route path='units/add' element={<AddUnits />} />
                        <Route path='units/edit/:id' element={<AddUnits />} />
                        <Route path='site-book-demos' element={<SiteBookDemos />} />
                        <Route path='site-enquiries' element={<SiteEnquiries />} />
                        <Route path='config' element={<Config />} />
                    </Route>
                </Route>
            }
            {authOk &&
                <Route path='*' element={<div>Not found</div>} />
            }
        </Routes>
    );
}

export default Routers;


//<Link to="/books/1">Link 1</Link>
//<Link to="{`/books/${number}`}">Link 1</Link>