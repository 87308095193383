// component
import SvgColor from '../svg-color';
import {
    IoSpeedometerOutline,
    IoGridOutline,
    IoReaderOutline,
    IoImagesOutline,
    IoSettingsOutline,
    IoStarOutline,
    IoBarChartOutline,
    IoGiftOutline,
    IoCalendarNumberOutline,
    IoPeopleOutline,
    IoListOutline,
    IoDocumentTextOutline,
    IoNewspaperOutline,
    IoCartOutline,
    IoClipboardOutline,
    IoPricetagsOutline
} from "react-icons/io5";

const config = (userRole) => {
    const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
    //Owner
    const ownerMenus = [
        {
            title: 'dashboard',
            path: '/portal/dashboard',
            icon: <IoSpeedometerOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'brands',
            path: '/portal/store/brands',
            icon: <IoPricetagsOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'categories',
            path: '/portal/store/categories',
            icon: <IoGridOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'items',
            path: '/portal/store/items',
            icon: <IoReaderOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'photos',
            path: '/portal/store/photos',
            icon: <IoImagesOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'offers',
            path: '/portal/store/offers',
            icon: <IoGiftOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'posts',
            path: '/portal/store/posts',
            icon: <IoDocumentTextOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'enquiries',
            path: '/portal/store/enquiries',
            icon: <IoNewspaperOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'orders',
            path: '/portal/store/orders',
            icon: <IoCartOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'bookings',
            path: '/portal/store/bookings',
            icon: <IoCalendarNumberOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'settings',
            path: '/portal/store/settings',
            icon: <IoSettingsOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'analytics',
            path: '/portal/store/analytics',
            icon: <IoBarChartOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'reviews',
            path: '/portal/store/reviews',
            icon: <IoStarOutline />,
            type: 'item',
            role: ['owner']
        }
    ];

    //Agent
    const agentMenus = [
        {
            title: 'dashboard',
            path: '/agent/dashboard',
            icon: <IoSpeedometerOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'stores',
            path: '/agent/stores',
            icon: <IoListOutline />,
            type: 'item',
            role: ['owner']
        }
    ]

    //admin
    const adminMenus = [
        {
            title: 'dashboard',
            path: '/admin/dashboard',
            icon: <IoSpeedometerOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'categories',
            path: '/admin/categories',
            icon: <IoGridOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'units',
            path: '/admin/units',
            icon: <IoClipboardOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'stores',
            path: '/admin/stores',
            icon: <IoListOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'users',
            path: '/admin/users',
            icon: <IoPeopleOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'Book Demos',
            path: '/admin/site-book-demos',
            icon: <IoCalendarNumberOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'Enquiries',
            path: '/admin/site-enquiries',
            icon: <IoNewspaperOutline />,
            type: 'item',
            role: ['owner']
        },
        {
            title: 'Config',
            path: '/admin/config',
            icon: <IoSettingsOutline />,
            type: 'item',
            role: ['owner']
        }
    ];

    switch (userRole) {
        case 'owner':
            return ownerMenus;
        case 'agent':
            return agentMenus;
        case 'super_admin':
            return adminMenus;
        default:
            break;
    }
}

export default config;
