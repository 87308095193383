import { Box, Button, Card, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../components/app-button/AppButton';
import Logo from '../../components/logo/Logo';
import { auth } from '../../reduxStore/actions/AuthActions';
import { SHOW_LOADING } from '../../reduxStore/actions/AuthTypes';
import "./BusinessName.css";

function BusinessName(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const dispatch = useDispatch();

    const [businessName, setBusinessName] = useState('');
    const handleBusinessNameClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            const res = await dispatch(auth(businessName));

            setTimeout(() => {

                navigate('/login');
                dispatch({
                    type: SHOW_LOADING,
                    payload: false
                });
            }, 3000);
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
        }
    }

    return (
        <>
            <Helmet>
                <title> Business Name | TrueMenu</title>
            </Helmet>
            <Box className='l-page'>
                <Box className='login-wrap'>
                    <Card>
                        <Box className='login-content'>
                            <Logo color="rgb(0, 0, 0)" />
                            <Typography className='l-heading'>Business Name</Typography>
                            <Box>
                                <TextField autoComplete='off' inputProps={{
                                    style: { fontSize: 16, fontWeight: 500 }
                                }} fullWidth autoFocus label="Enter Business/ Store Name" variant="outlined" />
                            </Box>
                            <Box>
                                <AppButton disabled={isLoading} isLoading={isLoading} onClick={handleBusinessNameClick} fullWidth size='large' variant="contained">Continue</AppButton>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

export default BusinessName;