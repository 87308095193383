import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { sendEnquiryOtp, sendStoreEnquiryWithOtp } from '../../../reduxStore/actions/site/StoreActions';
import { PRIMARY_ACTION } from '../../../reduxStore/actions/site/StoreActionTypes';
import AppButton from '../app-button/AppButton';
import AppDialog from '../app-dialog/AppDialog';
import EnquiryForm from '../enquiry-form/EnquiryForm';
import Iconify from '../iconify/Iconify';

function EnquiryFormDialog(props) {
    const authSelector = useSelector((state) => state.sAuth);
    const accessToken = authSelector?.userDetails?.access_token;
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;
    const action = storeSelector?.primaryAction;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpView, setIsOtpView] = useState(false);
    const [otp, setOtp] = useState('');
    const [formValues, setFormValues] = useState({
        name: '',
        mobile_no: '',
        city: '',
        email: '',
        message: '',
        store_id: storeId,
        item_id: action?.item?.uid,
        otp: '',
        otp_reference_id: ''
    });

    const handleChange = (event) => {
        formValues.store_id = storeId;
        formValues.item_id = action?.item?.uid;
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const sendEnquiry = async () => {
        if (formValues?.name === "") {
            enqueueSnackbar("Please enter your name.", { variant: 'error' });
            return;
        }
        if (formValues?.mobile_no === "") {
            enqueueSnackbar("Please enter mobile number.", { variant: 'error' });
            return;
        }
        if (formValues?.city === "") {
            enqueueSnackbar("Please enter city.", { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({ mobile_no: formValues.mobile_no });
            const res = await dispatch(sendEnquiryOtp(obj));
            setIsLoading(false);
            setIsOtpView(true);
            formValues.otp_reference_id = res?.data?.otp_reference_id;
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const sendEnquiryVerify = async () => {
        try {
            setIsLoading(true);
            formValues.otp = otp;
            const obj = JSON.stringify(formValues);
            const res = await dispatch(sendStoreEnquiryWithOtp(obj));
            setIsLoading(false);
            cancelEnquiry();
            setOtp('');
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const handleOtpChange = (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            //handleOtpClick();
        }
    }

    const handleDialogClickClose = () => {
        cancelEnquiry();
    };
    const handleEditClick = () => {
        setIsOtpView(false);
    };

    const cancelEnquiry = () => {
        emptyFields();
        setIsOtpView(false);
        setOtp('');
        dispatch({
            type: PRIMARY_ACTION,
            payload: { item: {}, actionType: '', showModal: false }
        });
    };

    const emptyFields = () => {
        setFormValues({
            name: '',
            mobile_no: '',
            city: '',
            email: '',
            message: '',
            store_id: storeId,
            item_id: ''
        })
    }


    const dialogEnqFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={cancelEnquiry}
                    style={{
                        color: configs?.primaryButtonBgColor
                    }}
                >
                    Cancel
                </AppButton>
                <AppButton
                    onClick={isOtpView ? sendEnquiryVerify : sendEnquiry}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="contained"
                    style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                        color: configs?.primaryButtonColor,
                        minWidth: 100
                    }}
                    size='medium'>
                    Submit
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            {action?.showModal && site?.store_details?.is_enquiry && site?.store_details?.is_enquiry !== 0 &&
                <AppDialog
                    isOpen={action?.showModal}
                    id={'enquiry_form_dialog'}
                    handleClickClose={handleDialogClickClose}
                    title={<Box>Send Enquiry</Box>}
                    footer={dialogEnqFooter}
                >
                    {isOtpView ?
                        <Box style={{
                            padding: 20
                        }}>
                            <Box>A verification code has beed sent to your mobile number {formValues.mobile_no} via SMS. Please enter the code below.</Box>
                            <Box style={{
                                marginTop: 25
                            }}>
                                <Typography style={{
                                    fontWeight: 600
                                }}>OTP is sent on number</Typography>
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 25,
                                        gap: 10
                                    }}
                                ><Typography style={{
                                    fontSize: 14
                                }}>91 - {formValues.mobile_no}</Typography>
                                    <Button
                                        onClick={() => handleEditClick()}
                                        size="small"
                                        variant="text"
                                        startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                            color: 'rgb(0, 171, 85)'
                                        }}>
                                        Edit
                                    </Button>
                                </Box>
                            </Box>
                            <Box>
                                <OtpInput
                                    inputStyle='otp-input-style'
                                    focusStyle='otp-focus-style'
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    isInputNum={true}
                                />
                            </Box>
                        </Box> :
                        <EnquiryForm formVals={formValues} handleChange={handleChange} />
                    }
                </AppDialog>
            }
        </>
    );
}

export default EnquiryFormDialog;