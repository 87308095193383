import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";


const ProtectedRoutes = ({ allowedRoles }) => {
    const auth = useSelector((state) => state.auth);
    const userRole = auth?.userDetails?.user_role_code;
    const isLogged = auth?.userDetails?.access_token;
    const location = useLocation();
    return (
        allowedRoles.find(role => role.includes(userRole))
            ? <Outlet />
            : isLogged
                ? <>Not Authorized</>
                : <Navigate to="/login" state={{ from: location }} replace />
    )
}
export default ProtectedRoutes;