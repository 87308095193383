import React, { useState } from 'react';
import { Box, Typography, Container, Card, FormControl, TextField, Button } from '@mui/material';
import "./ContactUs.css";
import Iconify from '../../portal/compoments/iconify/Iconify';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../components/app-button/AppButton';
import { contactUs } from '../../reduxStore/actions/StoreActions';
import { SITE_CONTACT } from '../../constants/Site';

function ContactUs(props) {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                name: name,
                mobile_no: mobile,
                city: city,
                message: message
            });
            const res = await dispatch(contactUs(obj));
            setIsLoading(false);
            clearFields();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const clearFields = () => {
        setName('');
        setMobile('');
        setCity('');
        setMessage('');
    }

    return (
        <>
            <Helmet>
                <title> Contact Us | TrueMenu</title>
            </Helmet>
            <Box>
                <Box className='contact-banner'>
                    <Box className='contact-overlay'>
                    </Box>
                    <Typography className='contact-banner-text'>
                        Contact Us
                    </Typography>
                </Box>
                <Box style={{
                    maxWidth: 800,
                    margin: '30px auto'
                }}>
                    <Card>
                        <Box className='contact-card'>
                            <Box className='contact-us-form'>
                                <Typography style={{
                                    fontSize: 15,
                                    fontWeight: 600
                                }}>Contact us</Typography>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        label="Enter your full name *"
                                        name="name"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        label="Enter Mobile No. *"
                                        name="mobile"
                                        value={mobile}
                                        onChange={(event) => setMobile(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        label="Enter your City *"
                                        name="city"
                                        value={city}
                                        onChange={(event) => setCity(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        label="Message *"
                                        name="message"
                                        value={message}
                                        onChange={(event) => setMessage(event.target.value)}
                                    />
                                </FormControl>
                                <AppButton
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    onClick={handleSaveClick}
                                    size='medium'
                                    variant="contained">
                                    Send
                                </AppButton>
                            </Box>
                            <Box className='contact-info'>
                                <Iconify icon={'ic:round-mail'} style={{
                                    height: 30,
                                    width: 30
                                }} />
                                <Typography>
                                    support@truemenu.in
                                </Typography>
                                <Iconify icon={'ic:baseline-call'} style={{
                                    height: 30,
                                    width: 30
                                }} />
                                <Typography>
                                    {SITE_CONTACT}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

export default ContactUs;