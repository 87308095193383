import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Lottie from "lottie-react";
import LoadingAnimation from "./loading.json";
import qrScanAnimation from "./qr-scan-code.json";
import "./Loader.css";
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AppDialog from '../app-dialog/AppDialog';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import Footer from '../footer/Footer';
import Typography from '../../pages/theme/overrides/Typography';
import { sendQrScanInfo } from '../../../reduxStore/actions/site/StoreActions';

function Loader(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const isLoading = storeSelector.appLoading;
    const configs = storeSelector.configs;
    const isMobile = useCheckMobileScreen();
    const site = storeSelector.site;
    const storeUrl = site?.store_details?.url;
    const storeName = site?.store_details?.store_name;

    const [searchParams, setSearchParams] = useSearchParams();
    const [isQr, setIsQr] = useState(false);
    const dispatch = useDispatch();

    const handleQrScanInfo = async () => {
        try {
            const obj = {
                store_url: storeUrl,
                device_type: "Website",
                location: "",
                device_id: ""
            }
            const res = await dispatch(sendQrScanInfo(obj));
            setTimeout(() => {
                setIsQr(false);
                searchParams.delete("qr");
                setSearchParams(searchParams);
            }, 1500);
        } catch (err) {
            setIsQr(false);
            searchParams.delete("qr");
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        if (searchParams.has("qr")) {
            //call QR Analytics api            
            setIsQr(true);
            if (storeUrl) {
                handleQrScanInfo();
            }
        }
    }, [storeUrl]);


    return (
        <Box>
            {true &&
                <Box>
                    {/* <Box className='loading-page'>
                        <Box className='loading-page-wrap'>
                            <Lottie style={{ height: 500 }} animationData={LoadingAnimation} loop={true} size={100} />
                        </Box>
                    </Box> */}
                    {isMobile && isQr &&
                        <AppDialog isOpen={true} id={'store_qr_animation_sm'} footer={
                            <Box>
                                <Footer />
                            </Box>}>
                            <Box style={{
                                maxWidth: 320,
                                margin: '0 auto'
                            }}>
                                <Lottie className='animation-scan' animationData={qrScanAnimation} loop={true} />
                                <p className='qr-scan-store-name'>{storeName}</p>
                            </Box>
                        </AppDialog>
                    }
                </Box>
            }
        </Box>
    );
}

export default Loader;