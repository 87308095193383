import React, { useState } from 'react';
import { Box, Typography, Button, Stack, IconButton, Card, TextField } from "@mui/material";
import Qr from '../../../components/qr/Qr';
import { IoShareSocialOutline, IoDownload, IoCopyOutline } from "react-icons/io5";
import "./Dashboard.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../../../components/app-button/AppButton';
import { createStoreUrl, getStoreDetails } from '../../../reduxStore/actions/StoreActions';
import { useSnackbar } from 'notistack';
import { SITE_NAME } from '../../../constants/Site';
import { Helmet } from 'react-helmet';
import { APP_SHARE } from '../../../reduxStore/actions/site/StoreActionTypes';
import { APP_QR_DOWNLOAD } from '../../../reduxStore/actions/StoreActionTypes';


function Dashboard(props) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const auth = useSelector((state) => state.auth);
    const userRole = auth?.userDetails?.user_role_code;
    const storeId = auth?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const storeDetails = storeSelector?.details;
    const [isLoading, setIsLoading] = useState(false);
    const [storeUrl, setStoreUrl] = useState('');
    let url = `${SITE_NAME}${storeDetails?.url}`;

    if (storeDetails?.is_customer_portal === 1) {
        url = storeDetails?.portal_link;
    }

    const addStoreUrl = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                store_url: storeUrl
            });
            const res = await dispatch(createStoreUrl(obj));
            if (storeId) {
                await dispatch(getStoreDetails(storeId));
            }
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const copyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            enqueueSnackbar('Copied!', { variant: 'success' });
        } catch (err) {
            enqueueSnackbar('Failed to copy!', { variant: 'error' });
        }
    };

    const handleShareDialogClickOpen = () => {
        dispatch({
            type: APP_SHARE,
            payload: true
        });
    };

    const handleQrDownload = () => {
        dispatch({
            type: APP_QR_DOWNLOAD,
            payload: true
        });
    }

    return (
        <>
            <Helmet>
                <title> Dashboard | TrueMenu</title>
            </Helmet>
            <Box className='page-dashboard'>
                <div style={{ padding: 24 }}>

                    {!storeDetails?.url && userRole === 'owner' &&
                        <Box className='d-b-username'>
                            <Box className='d-b-username-wrap'>
                                <Card>
                                    <Box className='d-b-username-container'>
                                        <Box>
                                            <Typography className='d-b-main-site'>truemenu.in/</Typography>
                                        </Box>
                                        <Box>
                                            <TextField
                                                autoComplete='off'
                                                name='store_url'
                                                value={storeUrl}
                                                inputProps={{
                                                    style: { fontSize: 16, fontWeight: 500 }
                                                }}
                                                fullWidth
                                                autoFocus
                                                label="Business/ Store url"
                                                variant="outlined"
                                                onChange={(event) => setStoreUrl(event.target.value)}
                                            />
                                            <Typography style={{
                                                fontSize: 12,
                                                color: '#000',
                                                marginTop: 5
                                            }}>Letters, numbers, underscore, period and dash allowed</Typography>
                                            <Box style={{
                                                marginTop: 15,
                                            }}>
                                                <AppButton
                                                    onClick={addStoreUrl}
                                                    fullWidth
                                                    disabled={isLoading}
                                                    isLoading={isLoading}
                                                    size='medium'
                                                    variant="contained">
                                                    Save
                                                </AppButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>
                            </Box>
                        </Box>
                    }

                    {storeDetails?.url && userRole === 'owner' ?
                        (
                            <Box>
                                <Stack className='v1-portal-dashboard'>
                                    <div style={{
                                        color: 'rgb(0, 82, 73)',
                                        borderRadius: 16,
                                        padding: 20,
                                        background: '#d6f5e2'
                                    }}>
                                        <Typography style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            color: 'rgb(0, 82, 73)'
                                        }}>Welcome Back {auth?.userDetails?.name}!</Typography>
                                        <Typography style={{
                                            fontSize: '15px'
                                        }}>We have created store page for you.
                                            To see the store please visit below url</Typography>
                                        <Box style={{
                                            padding: '10px',
                                            marginTop: '16px',
                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                            borderRadius: '8px',
                                            borderStyle: 'dashed',
                                            color: '#000',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            wordBreak: 'break-all'
                                        }}>
                                            <Link style={{
                                                textDecoration: 'none',
                                                color: '#000'
                                            }}>
                                                <Typography className='v1-dashboard-store-url'>
                                                    {url}
                                                </Typography>
                                            </Link>
                                            <IconButton style={{ fontSize: 20 }} onClick={copyToClipBoard}>
                                                <IoCopyOutline />
                                            </IconButton>
                                        </Box>
                                        <Box style={{
                                            marginTop: 20
                                        }}>
                                            <Button style={{
                                                backgroundColor: 'rgb(0, 171, 85)'
                                            }} href={url} target="_blank" variant="contained">
                                                Visit store
                                            </Button>
                                        </Box>
                                    </div>
                                    <div>
                                        <div className='qrwrap'>
                                            <div className='qrTitle'>QR code of {storeDetails?.store_name}</div>
                                            <div className='qrwrapInner'>
                                                <Qr size={220} url={url} />
                                            </div>
                                            <div className='qrAction'>
                                                <div>
                                                    <Button startIcon={<IoShareSocialOutline />} size="small" variant="outlined" onClick={handleShareDialogClickOpen}>Share QR</Button>
                                                </div>
                                                <div>
                                                    <Button startIcon={<IoDownload />} variant="contained" size="small" color="info" onClick={handleQrDownload}>Download</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                            </Box>
                        )
                        :
                        <Box>
                            {userRole !== 'owner' && (<Box>
                                <Typography>Dashboard</Typography>
                            </Box>)
                            }
                        </Box>
                    }
                </div>
            </Box>
        </>
    );
}

export default Dashboard;