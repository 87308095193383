import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import AccountPopover from './AccountPopover';
import { IoMenuOutline } from "react-icons/io5";
import { bgBlur } from '../../utils/cssStyles';
import Iconify from '../iconify/Iconify';
import AppDialogSlide from '../../../components/app-dialog-slide/AppDialogSlide';
import { APP_SHARE } from '../../../reduxStore/actions/site/StoreActionTypes';
import { ShareSocial } from 'react-share-social'
import { useDispatch, useSelector } from 'react-redux';
import { SITE_NAME } from '../../../constants/Site';
//import LanguagePopover from './LanguagePopover';
//import NotificationsPopover from './NotificationsPopover';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 75;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
    const sStoreSelector = useSelector((state) => state.sStore);
    const storeSelector = useSelector((state) => state.store);
    const shareDialog = sStoreSelector.isShare;
    const storeDetails = storeSelector.details;
    let url = `${SITE_NAME}${storeDetails?.url}`;

    if (storeDetails?.is_customer_portal === 1) {
        url = storeDetails?.portal_link;
    }

    const dispatch = useDispatch();
    const handleDialogClickClose = (index) => {
        dispatch({
            type: APP_SHARE,
            payload: false
        });
    };

    return (
        <>
            <StyledRoot>
                <StyledToolbar>
                    <IconButton
                        onClick={onOpenNav}
                        sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { lg: 'none' },
                        }}
                    >
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={{
                            xs: 0.5,
                            sm: 1,
                        }}
                    >
                        {/* <LanguagePopover />
                    <NotificationsPopover /> */}
                        <AccountPopover />
                    </Stack>
                </StyledToolbar>
            </StyledRoot>

            {shareDialog &&
                <AppDialogSlide
                    isOpen={shareDialog}
                    id={'home_share_d'}
                    title={<>Share</>}
                    footer={<></>}
                    handleClickClose={handleDialogClickClose}>
                    <Box>
                        <ShareSocial
                            url={url}
                            socialTypes={['whatsapp', 'facebook', 'twitter', 'telegram']}
                        />
                    </Box>
                </AppDialogSlide>
            }
        </>
    );
}
