import React, { useState, useEffect } from 'react';

import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    Box,
    IconButton,
    MenuItem,
    TextField,
    ThemeProvider
} from '@mui/material';
import Iconify from '../../compoments/iconify/Iconify';
import { Link } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./Offers.css";
import AppButton from '../../../components/app-button/AppButton';
import AppDialog from '../../../components/app-dialog/AppDialog';
import { createTheme } from '@material-ui/core/styles';
import { Colors } from '../../../constants/Colors';
import { lightBlue, lime } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { addOffer, getAllOffers, offerAction, offerDelete, updateOffer } from '../../../reduxStore/actions/StoreActions';
import { useSnackbar } from 'notistack';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import { Helmet } from 'react-helmet';

function Offers(props) {
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const allOffers = storeSelector.offers;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // Status: Active => Green, Hidden => Warning, Expired => Red
    const status = { Active: 'rgb(0, 171, 85)', Expired: 'rgb(255, 72, 66)', Hidden: 'rgb(255, 171, 0)' };
    const [open, setOpen] = useState(null);
    const [offerDialogOpen, setOfferDialogOpen] = React.useState(false);
    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [selectedEndDate, handleEndDateChange] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [title, setTitle] = useState('');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const newOfferClick = () => {
        setOfferDialogOpen(true);
    }
    const handleDialogCancel = () => {
        setOfferDialogOpen(false);
        setIsUpdate(false);
        setSelectedRecord({});
        setTitle('');
        handleStartDateChange(new Date());
        handleEndDateChange(new Date());

    }

    const materialTheme = createTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: lightBlue.A100,
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    // backgroundColor: lightBlue.A200,
                    // color: "white",
                },
            },
            MuiPickersDay: {
                day: {
                    color: lightBlue.A700,
                },
                daySelected: {
                    backgroundColor: lightBlue["400"],
                },
                dayDisabled: {
                    color: lightBlue["100"],
                },
                current: {
                    color: lightBlue["900"],
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: lightBlue["400"],
                },
            },
        },
    });

    const saveOfferClick = async () => {
        console.log("selectedStartDate", selectedStartDate);
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                title: title,
                start_date: selectedStartDate,
                end_date: selectedEndDate,
                sequence_no: 1
            }));
            const res = await dispatch(addOffer(formData));
            setIsLoading(false);
            setOfferDialogOpen(false);
            getOffers();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const editOfferClick = async () => {
        setOfferDialogOpen(true);
        setIsUpdate(true);
        setTitle(selectedRecord?.title);
        handleStartDateChange(selectedRecord?.start_date);
        handleEndDateChange(selectedRecord?.end_date);
    }

    const offerUpdate = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                uid: selectedRecord?.uid,
                title: title,
                start_date: selectedStartDate,
                end_date: selectedEndDate,
                sequence_no: 1
            }));
            const res = await dispatch(updateOffer(formData));
            setIsLoading(false);
            setIsUpdate(false);
            setOfferDialogOpen(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setSelectedRecord({});
            getOffers();
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getOffers = async () => {
        try {
            const obj = JSON.stringify({
                store_id: storeId
            });
            const res = await dispatch(getAllOffers(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getStatusColor = (row) => {
        if (row?.comming_soon === 0 && row?.offer_status === 0) {
            return { status: status?.Expired, text: 'Expired' };
        } else if (row?.comming_soon === 1 && row?.offer_status === 0) {
            return { status: status?.Hidden, text: 'Coming Soon' };
        } else if (row?.comming_soon === 1 && row?.offer_status === 1) {
            return { status: status?.Active, text: 'Running' };
        }
    }

    const deleteActionClick = async () => {
        setIsDeleteDialogOpen(true);
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = {
            store_id: storeId,
            uid: selectedRecord?.uid,
            record_status: 'R'
        }
        try {
            const res = await dispatch(offerDelete(obj));
            setSelectedRecord({});
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const hideActionClick = async () => {
        setIsDeleteDialogOpen(false);
        const obj = {
            store_id: storeId,
            uid: selectedRecord.uid,
            record_status: selectedRecord.record_status === 'A' ? 'D' : 'A'
        }
        try {
            const res = await dispatch(offerAction(obj));
            setSelectedRecord({});
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }

    }

    useEffect(() => {
        getOffers();
    }, []);

    const dialogOfferFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={isUpdate ? offerUpdate : saveOfferClick}
                    variant="contained">
                    {isUpdate ? 'Update' : 'Save'}
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <title> Offers | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Offers
                    </Typography>
                    <AppButton onClick={newOfferClick} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                        New Offer
                    </AppButton>
                </Stack>
                <Card>
                    <Stack className='o-card'>

                        {allOffers && allOffers.map((row) => {
                            return (
                                <Box
                                    key={row?.uid}
                                    className='o-card-wrap' style={{
                                        background: row?.record_status === 'D' && '#ffe7b8'
                                    }}>
                                    <IconButton size="medium" color="inherit" style={{
                                        marginLeft: 'auto',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }} onClick={(event) => { handleOpenMenu(event); setSelectedRecord(row) }}>
                                        <Iconify icon={'eva:more-vertical-fill'} />
                                    </IconButton>
                                    <Box className='o-card-content'>
                                        <Box style={{
                                            width: 30
                                        }}>
                                            <Iconify icon="ic:round-local-offer" style={{
                                                color: '#e46d47'
                                            }} />
                                        </Box>
                                        <Box style={{
                                            flex: 1
                                        }}>
                                            <Typography className='o-offer-heading'>{row?.title}</Typography>
                                            <Typography className='o-offer-date'>Valid till {row?.till_date}</Typography>
                                            <Box>
                                                <Box className='o-status-wrap'>
                                                    <Typography className='o-status-box' style={{
                                                        background: getStatusColor(row)?.status
                                                    }}></Typography>
                                                    <Typography className='o-status-text'
                                                        style={{
                                                            color: getStatusColor(row)?.status
                                                        }}
                                                    >
                                                        {getStatusColor(row)?.text}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>)
                        })}

                    </Stack>
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); editOfferClick() }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem sx={{ color: 'error.main' }} onClick={() => { handleCloseMenu(); deleteActionClick() }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenu(); hideActionClick() }}>
                    <Iconify icon={selectedRecord?.record_status === 'A' ? 'ci:hide' : 'ci:show'} sx={{ mr: 2 }} />
                    {selectedRecord?.record_status === 'A' ? 'Hide' : 'Activate'}
                </MenuItem>
            </Popover>

            {offerDialogOpen &&
                <AppDialog maxWidth='xs' isOpen={offerDialogOpen} id={'store_offer_sm'} title={<>{isUpdate ? "Edit Offer" : "Add Offer"}</>}
                    footer={dialogOfferFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField label="Title*"
                                fullWidth
                                placeholder='ex. 10% off on some item name'
                                autoComplete='off'
                                name="title"
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                            />
                            <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        fullWidth
                                        inputVariant='outlined'
                                        label="Start Date*"
                                        value={selectedStartDate}
                                        onChange={handleStartDateChange}
                                        animateYearScrolling
                                        format="dd-MMM-yy hh:mm a"
                                    />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    fullWidth
                                    inputVariant='outlined'
                                    label="End Date*"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    animateYearScrolling
                                    format="dd-MMM-yy hh:mm a"
                                    minDate={selectedStartDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_offer_dialog'} title={<>Delete Offer</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }
        </>
    );
}

export default Offers;