import React from 'react';
import { Helmet } from 'react-helmet';

function Orders(props) {
    return (
        <Helmet>
            <title> Orders | TrueMenu</title>
        </Helmet>
    );
}

export default Orders;