import { Avatar, Box, Button, Card, Container, Divider, IconButton, Link, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../../components/app-button/AppButton';
import AppDialog from '../../components/app-dialog/AppDialog';
import Iconify from '../../components/iconify/Iconify';
import { IMAGE_URL, NO_USER_IMAGE } from '../../constants/Images';
import { SHOW_LOGING_DIALOG } from '../../../reduxStore/actions/site/AuthTypes';
import { getUserDetails, updateProfile } from '../../../reduxStore/actions/site/StoreActions';
import "./UserProfile.css";

function UserProfile(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;    
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};    
    const authSelector = useSelector((state) => state.sAuth);
    const accessToken = authSelector?.userDetails?.access_token;
    const profileData = authSelector?.userDetails;
    const [isLoading, setIsLoading] = useState(false);
    const [editProfileDialog, setEditProfileDialog] = React.useState(false);
    const inputFile = useRef(null);
    const [file, setFile] = useState('');
    const [fileData, setFileData] = useState('');
    const [fileStatus, setFileStatus] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [uid, setUid] = useState('');

    console.log("accessToken", accessToken);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const editProfileHandle = async () => {
        if (!accessToken) {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: true
            });
            return;
        }
        setEditProfileDialog(true);
        setName(profileData?.name);
        setMobile(profileData?.mobile);
        setEmail(profileData?.email);
        setCity(profileData?.city);
        setPincode(profileData?.pincode);
        setBio(profileData?.profile_bio);
        setUid(profileData?.unique_id);
        setFile(profileData?.profile_pic);
    }
    const handleDialogClickClose = async () => {
        setEditProfileDialog(false);
    }
    const cancelProfileHandle = async () => {
        setEditProfileDialog(false);
    }
    const updateProfileHandle = async () => {
        if (accessToken) {
            try {
                setIsLoading(true);
                let formData = new FormData();
                if (fileData) {
                    formData.append('user_file', fileData);
                }
                formData.append('form_data', JSON.stringify({
                    file_status: fileStatus ? fileStatus : 'no_changes',
                    uid: uid,
                    name: name,
                    city: city,
                    pincode: pincode,
                    email: email,
                    about: bio,
                }));
                const res = await dispatch(updateProfile(formData));
                await dispatch(getUserDetails({ uid: uid }));
                setIsLoading(false);
                setEditProfileDialog(false);
                enqueueSnackbar(res.data.message, { variant: 'success' });
            } catch (err) {
                setIsLoading(false);
                if (err?.errors) {
                    enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
                }
            }
        } else {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: true
            });
        }
    };


    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileData(e.target.files[0]);
        setFileStatus('removed');
    }
    const handleFileRemove = () => {
        setFile('');
        setFileData('');
        inputFile.current.value = "";
        setFileStatus('removed');
    }

    const dialogAddReviewFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button
                    onClick={cancelProfileHandle}
                    size='medium'
                    style={{
                        color: configs?.primaryButtonBgColor
                    }}
                >
                    Cancel
                </Button>
                <AppButton
                    onClick={updateProfileHandle}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="contained"
                    style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                        color: configs?.primaryButtonColor,
                        minWidth: 100
                    }}
                    size='medium'>Submit</AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <title>{`User profile of ${profileData?.name}`}</title>
            </Helmet>
            <Box className='user-profile-page'>
                <Container>
                    <Box className='user-profile-wrap'>
                        <Card className='user-profile-card'>
                            <Typography className='user-profile-heading'>Profile Image</Typography>
                            <Box className='user-profile-av'>
                                <Avatar
                                    variant="circular"
                                    alt={profileData?.name}
                                    src={profileData?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${profileData?.profile_pic}` : NO_USER_IMAGE}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <Typography className='user-profile-img-caption'>Allowed *.jpeg, *.jpg, *.png max size of 1 MB</Typography>
                            </Box>
                            <Divider className='dashed-div' style={{
                                marginTop: 20,
                                marginBottom: 20
                            }} />
                            <Box className='user-profile-info'>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                    <Typography className='user-profile-heading'>Profile Info</Typography>
                                    <AppButton
                                        onClick={editProfileHandle}
                                        size="small"
                                        variant="text"
                                        startIcon={<Iconify icon="eva:edit-fill" />}
                                        style={{
                                            color: configs?.primaryButtonBgColor
                                        }}>
                                        Edit
                                    </AppButton>
                                </Stack>
                                <Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>Name</Typography>
                                        <Typography className='user-profile-field'>{profileData?.name}</Typography>
                                    </Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>Mobile No.</Typography>
                                        <Typography className='user-profile-field'>+91-{profileData?.mobile}</Typography>
                                    </Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>Email</Typography>
                                        <Typography className='user-profile-field'>{profileData?.email}</Typography>
                                    </Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>About</Typography>
                                        <Typography className='user-profile-field'>{profileData?.profile_bio}</Typography>
                                    </Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>City</Typography>
                                        <Typography className='user-profile-field'>{profileData?.city}</Typography>
                                    </Box>
                                    <Box className='user-profile-d'>
                                        <Typography className='user-profile-label'>Pincode</Typography>
                                        <Typography className='user-profile-field'>{profileData?.pincode}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Container>

                {editProfileDialog &&
                    <AppDialog
                        isOpen={editProfileDialog}
                        id={'edit_profile_dialog'}
                        handleClickClose={handleDialogClickClose}
                        title={<>Edit Profile</>}
                        footer={dialogAddReviewFooter}
                    >
                        <Box style={{
                            marginTop: 30,
                            marginBottom: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 15
                        }}>
                            <Box>
                                <input onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                                <Typography style={{
                                    fontSize: 14,
                                    color: '#000',
                                    marginBottom: 30
                                }}>
                                </Typography>
                                <Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Box className='user-c-image-wrap'>
                                            {!file
                                                ? <Link className='user-c-image' onClick={handleFileClick}>
                                                    <Iconify sx={{
                                                        width: 40
                                                    }} icon="mdi:camera-plus" />
                                                    <Typography style={{
                                                        fontSize: 14
                                                    }}>
                                                        Upload image
                                                    </Typography>
                                                </Link>
                                                :
                                                <>
                                                    <img src={profileData?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${profileData?.profile_pic}` : file}
                                                        alt="img"
                                                        style={{
                                                            height: "100%",
                                                            width: "100%"
                                                        }} />
                                                    <IconButton onClick={handleFileRemove} className='user-close-image-ico'><Iconify width={15} icon="material-symbols:close" /></IconButton>
                                                </>
                                            }
                                        </Box>
                                        <Typography className='user-c-image-caption'>
                                            Allowed *.jpeg, *.jpg, *.png
                                            max size of 1 MB
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <TextField
                                    autoComplete='off'
                                    value={name}
                                    name='name'
                                    label="Name*"
                                    fullWidth
                                    onChange={(event) => setName(event.target.value)} />
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    label="Mobile No*"
                                    name="mobile_no"
                                    fullWidth
                                    value={mobile}
                                    disabled
                                    onChange={(event) => setMobile(event.target.value)} />

                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    label="Email (Optional)"
                                    name="email"
                                    fullWidth
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)} />
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    label="City (Optional)"
                                    name="city"
                                    fullWidth
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)} />
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    label="Pincode (Optional)"
                                    name="message"
                                    fullWidth
                                    value={pincode}
                                    onChange={(event) => setPincode(event.target.value)} />
                            </Box>
                            <Box>
                                <TextField
                                    autoComplete='off'
                                    label="About you (Optional)"
                                    name="bio"
                                    multiline rows={2}
                                    fullWidth
                                    value={bio}
                                    onChange={(event) => setBio(event.target.value)} />
                            </Box>
                        </Box>
                    </AppDialog>
                }

            </Box>
        </>
    );
}

export default UserProfile;