import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountSm from '../account-sm/AccountSm';
import Logo from '../logo/Logo';
import "./Header.css";

function Header(props) {

    const auth = useSelector((state) => state.auth);
    const accessToken = auth?.userDetails?.access_token;

    return (
        <div className='page-header'>
            <div className='page-header-content'>
                <Logo color="rgb(0, 0, 0)" />
                <div className='h-right-wrap'>
                    <ul>
                        <li className='header-contact-sm'>
                            <Link to='/contact-us'>Contact Us</Link>
                        </li>
                        <li className='header-book-demo-sm'>
                            <Link to='/book-a-demo'>Book a Demo</Link>
                        </li>
                        <li>
                            {!accessToken ?
                                <Link to='/login'>
                                    <Button variant="contained" style={{
                                        backgroundColor: 'rgb(0, 171, 85)'
                                    }}>
                                        Login
                                    </Button>
                                </Link>
                                :
                                <AccountSm />
                            }
                        </li>
                    </ul>
                    {/* <div className='profile-icon'></div> */}
                </div>
            </div>
        </div>

    );
}

export default Header;