import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import StoreReducer from "./StoreReducer";
import SAuthReducer from "./site/SAuthReducer";
import SStoreReducer from "./site/SStoreReducer";

export default combineReducers({
    auth: AuthReducer,
    store: StoreReducer,
    sAuth: SAuthReducer,
    sStore: SStoreReducer
});