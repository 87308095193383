import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EnquiryFormDialog from '../../components/enquiry-form-dialog/EnquiryFormDialog';
import Iconify from '../../components/iconify/Iconify';
import ItemList from '../../components/item-list/ItemList';
import "./Search.css";
import { getStoreData } from '../../../reduxStore/actions/site/StoreActions';
import { APP_LOADING } from '../../../reduxStore/actions/site/StoreActionTypes';


function Search(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;
    const storeUrl = site?.store_details?.url;
    const [allItems, setAllItems] = React.useState([]);
    const [allItemsList, setAllItemsList] = React.useState([]);
    const [isItemClick, setIsItemClick] = React.useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const store_url = params?.store_url;

    const setSearch = async () => {
        const searchData = [];
        if (site?.categories?.length > 0) {
            await site?.categories?.forEach(element => {
                if (element?.items.length > 0) {
                    element?.items.forEach(item => {
                        searchData.push(item);
                    });
                }
            });
            setAllItems(searchData);
            setAllItemsList(searchData);
        }
    }

    const itemClick = async (row) => {
        setIsItemClick(true);
        navigate(`/${storeUrl}/item-details/${row.uid}`, { state: row });
    }

    const searchItems = async (value) => {
        const allSearchData = await allItemsList?.filter(item => item?.search_field.toLowerCase().includes(value.toLowerCase()));
        setAllItems(allSearchData);
    }

    const getStore = async () => {
        try {
            dispatch({
                type: APP_LOADING,
                payload: true
            });
            await dispatch(getStoreData(store_url));
            dispatch({
                type: APP_LOADING,
                payload: false
            });
        } catch (err) {
            dispatch({
                type: APP_LOADING,
                payload: false
            });
        }
    }


    useEffect(() => {
        setSearch();
    }, [])

    useEffect(() => {
        setSearch();
    }, [site])

    useEffect(() => {
        if (!site?.store_details?.url) {
            getStore(store_url);
        }
    }, [store_url]);

    return (
        <Box>
            <Box className='search-page-header'>
                <Box className='search-page-header-wrap'>
                    <Box className='search-page-header-content'>
                        {storeUrl &&
                            <Link className='search-back' style={{ textDecoration: 'none' }} to={`/${storeUrl}`}>
                                <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                                <span className='search-back-btn'>Back</span>
                            </Link>
                        }
                        <Box className='search-page-txt-wrap'>
                            <Iconify color="#000" icon={'carbon:search'} className='search-ico' />
                            <input onKeyUp={(event) => searchItems(event.target.value)} autoFocus type="text" className='search-page-txt' placeholder='Search...' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className='search-page-body'>
                <Box className='search-page-body-wrap'>

                    {allItems.map((item) => {
                        return (
                            <ItemList
                                key={item.uid}
                                item={item}
                                itemClick={itemClick}
                            />
                        )
                    })}

                    <Box style={{
                        paddingBottom: 50
                    }}></Box>

                </Box>
            </Box>
            <EnquiryFormDialog />
        </Box>
    );
}

export default Search;