import {
    ADD_NAME,
    LOGGEDIN_USER_DETAILS,
    LOGIN, LOGOUT,
    SHOW_LOADING,
    SHOW_LOGING_DIALOG,
    VERIFY_OTP
} from "../../actions/site/AuthTypes";

const initialState = {
    isLoading: false,
    isLoginDialog: false,
    userLogin: {},
    userOtp: {},
    userName: {},
    userDetails: {}
};

function SAuthReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN:
            return {
                ...state,
                userLogin: payload
            };
        case VERIFY_OTP:
            return {
                ...state,
                userOtp: payload
            };
        case ADD_NAME:
            return {
                ...state,
                userName: payload
            };
        case LOGGEDIN_USER_DETAILS:
            return {
                ...state,
                userDetails: payload
            };
        case SHOW_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        case SHOW_LOGING_DIALOG:
            return {
                ...state,
                isLoginDialog: payload
            };
        case LOGOUT:
            return {
                ...state,
                userDetails: []
            };
        default:
            return state;
    }

};

export default SAuthReducer;