import React, { useEffect } from 'react';
import FsLightbox from 'fslightbox-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import "./ItemDetails.css";
import { Box, IconButton, Typography } from '@mui/material';
import Iconify from '../../components/iconify/Iconify';
import AppButton from '../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_URL, NO_IMAGE } from '../../constants/Images';
import { APP_LOADING, PRIMARY_ACTION } from '../../../reduxStore/actions/site/StoreActionTypes';
import EnquiryFormDialog from '../../components/enquiry-form-dialog/EnquiryFormDialog';
import { WHATSAPP_URL } from '../../../constants/Site';
import ItemVariant from './ItemVariant';
import { getItemDetails, getStoreData } from '../../../reduxStore/actions/site/StoreActions';
import { useSnackbar } from 'notistack';


function ItemDetails(props) {
    const { enqueueSnackbar } = useSnackbar();
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;
    const storeUrl = site?.store_details?.url;
    const [itemName, setItemName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [brandName, setBrandName] = React.useState('');
    const [originalPrice, setOriginalPrice] = React.useState('');
    const [discountedPrice, setDiscountedPrice] = React.useState('');
    const [images, setImages] = React.useState([]);
    const [allAttributes, setAllAttributes] = React.useState([]);
    const [allVariantKeysArr, setAllVariantKeysArr] = React.useState([]);
    const [lightImages, setLightImages] = React.useState([]);
    const [image, setImage] = React.useState('');
    const [toggler, setToggler] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [selectedVariants, setSelectedVariants] = React.useState({});
    const [discount, setDiscount] = React.useState('');
    const { state } = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const store_url = params?.store_url;
    const itemId = params?.id;

    const itemAction = (item) => {
        if (site?.store_details?.is_what_app_enquiry === 1) {
            const actionMsg = `${site?.store_details?.action_btn_msg} ${item.name}`;
            window.open(`${WHATSAPP_URL}?phone=${site?.store_details?.whatsapp_no}&text=${actionMsg}`, '_blank');
        } else {
            dispatch({
                type: PRIMARY_ACTION,
                payload: { item: item, showModal: true, isOtpMandatory: true }
            });
        }
    }

    let per_variant = '';
    if (selectedVariants) {
        per_variant = `${selectedVariants?.variant_value} ${selectedVariants?.unit_name}`;
    }

    const handleVariants = async (state) => {
        setSelectedVariants(state?.variants?.[0]);
        const variants = state?.variants?.[0];
        const allSavedVariants = state?.variants;
        if (variants?.attributes?.length > 0) {
            const allSavedAttributes = [];
            allSavedVariants?.map(item => {
                allSavedAttributes.push(...item?.attributes);
            });
            if (allSavedAttributes?.length > 0) {
                const attrArr = [];
                allSavedAttributes?.map((item) => {
                    if (!attrArr.includes(item.attr_name)) {
                        attrArr.push(item.attr_name);
                    }
                });
                setAllAttributes([...attrArr]);
                if (attrArr?.length > 0) {
                    const variantKeyArr = [];
                    attrArr?.map((option) => {
                        let variantKeyObj = {};
                        let variantKeyValues = [];
                        allSavedAttributes?.map((item) => {
                            if (option == item?.attr_name) {
                                if (!variantKeyValues.includes(item?.attr_value)) {
                                    variantKeyValues.push(item?.attr_value);
                                    variantKeyObj['name'] = option;
                                    variantKeyObj['selectedOption'] = '';
                                    variantKeyObj['optionValues'] = variantKeyValues;
                                }
                            }
                        });
                        if (Object.keys(variantKeyObj).length) {
                            variantKeyArr.push(variantKeyObj);
                        }
                    })
                    if (variantKeyArr.length > 0) {
                        variantKeyArr?.map((item) => {
                            state?.variants?.[0]?.attributes?.map((attr) => {
                                if (item?.name === attr?.attr_name) {
                                    item.selectedOption = attr?.attr_value;
                                }
                            })
                        })
                    }
                    setAllVariantKeysArr([...variantKeyArr]);
                }
            }
        }
    }


    const variantItemSelection = async (variant, index) => {
        const allVariantItems = allVariantKeysArr;
        allVariantItems[index].selectedOption = variant;
        setAllVariantKeysArr([...allVariantItems]);
        const selected_vars = [];
        allAttributes?.map(item => {
            allVariantKeysArr?.map((variants) => {
                if (item === variants?.name) {
                    selected_vars.push(variants?.selectedOption);
                }
            })
        });
        row?.variants?.map((variant) => {
            const attribute_arr = [];
            variant?.attributes?.map((attribute) => {
                allAttributes?.map(item => {
                    if (item === attribute?.attr_name) {
                        attribute_arr.push(attribute?.attr_value);
                    }
                });
                if (attribute_arr?.toString() === selected_vars?.toString()) {
                    setSelectedVariants(variant);
                }
            });
        });
    }

    const getAllItemDetails = async () => {
        try {
            dispatch({
                type: APP_LOADING,
                payload: true
            });
            const res = await dispatch(getItemDetails(itemId));
            setItemDetails(res?.data?.store_item);
            dispatch({
                type: APP_LOADING,
                payload: false
            });
        } catch (err) {
            dispatch({
                type: APP_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const setItemDetails = async (item) => {
        setItemName(item?.name);
        setDescription(item?.description);
        setBrandName(item?.brand_name);
        setOriginalPrice(item?.variants[0]?.original_price);
        setDiscountedPrice(item?.variants[0]?.discounted_price);
        setImages(item?.all_iamges);
        setDiscount(item?.variants[0]?.discount);
        setRow(item);
        handleVariants(item);
    }

    const setImagePaths = async () => {
        const lightImages = [];
        if (images?.length) {
            images?.forEach((img) => {
                const path = `${IMAGE_URL}item/?id=${storeId}&img_id=${img.image_path}`
                lightImages.push(path);
            })
            setLightImages([...lightImages]);
        }
        const image = images?.length
            ? `${IMAGE_URL}item/?id=${storeId}&img_id=${images[0]?.image_path}`
            : NO_IMAGE;
        setImage(image);
    }

    const getStore = async () => {
        try {
            dispatch({
                type: APP_LOADING,
                payload: true
            });
            await dispatch(getStoreData(store_url));
            dispatch({
                type: APP_LOADING,
                payload: false
            });
        } catch (err) {
            dispatch({
                type: APP_LOADING,
                payload: false
            });
        }
    }


    useEffect(() => {
        setItemDetails(state);
    }, []);

    useEffect(() => {
        if (storeId) {
            setImagePaths();
        }
    }, [site, images]);

    useEffect(() => {
        if (itemId && state?.uid === undefined) {
            getAllItemDetails();
        }
    }, [itemId, state]);

    useEffect(() => {
        if (store_url && state?.uid === undefined || !site?.store_details?.url) {
            getStore(store_url);
        }
    }, [store_url]);

    return (
        <Box>
            <Box className="v1-store-items-d-page">
                <Box className="v1-store-items-d-header">
                    <Typography gutterBottom>
                        {storeUrl &&
                            <Link style={{ textDecoration: 'none' }} to={`/${storeUrl}`}>
                                <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                            </Link>
                        }  {itemName}
                    </Typography>
                </Box>
                <Box className="v1-store-items-d-body">
                    <Box className='v1-store-item-d-wrap'>
                        <Box className="v1-store-items-d-img">
                            <Box className="v1-store-items-d-img-bg"
                                style={{
                                    backgroundImage: `url(${image})`
                                }}
                                onClick={() => images?.length ? setToggler(!toggler) : undefined}
                            >
                                {selectedVariants?.discount ?
                                    <Box className='list-item-offer'>{selectedVariants?.discount}</Box>
                                    :
                                    <></>
                                }
                                {images?.length > 0 &&
                                    <Box className='sPhotoCount'>
                                        {images?.length}+ Photos
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box className="v1-store-items-d-desc">
                            <Typography className='v1-store-item-d-name'>
                                {itemName}
                            </Typography>
                            <Typography style={{
                                fontSize: 14
                            }}>
                                {per_variant}
                            </Typography>
                            {brandName &&
                                <Box><span className='list-item-brand-d' style={{
                                    background: configs?.themeBgColor,
                                    color: configs?.themeColor
                                }}>{brandName}</span></Box>
                            }
                            <Box style={{
                                fontSize: 15
                            }} dangerouslySetInnerHTML={{ __html: description }} />
                            <Box className="v1-store-items-d-price">
                                <Box className='v1-store-items-d-actual'
                                    style={{
                                        textDecoration: selectedVariants?.discounted_price && 'line-through'
                                    }}>
                                    {selectedVariants?.original_price ? `Rs. ${selectedVariants?.original_price}` : ''}
                                </Box>
                                {selectedVariants?.discounted_price &&
                                    <Box className='v1-store-items-d-discounted'>
                                        {selectedVariants?.discounted_price ? `Rs. ${selectedVariants?.discounted_price}` : ''}
                                    </Box>
                                }
                            </Box>
                            <Box className='item-d-variants'>

                                {allVariantKeysArr && allVariantKeysArr.map((item, index) => {
                                    return (
                                        <Box key={index}>
                                            <Box className='item-d-variant-name'>Select {item?.name === 'color picker' ? 'color' : item?.name}</Box>
                                            <ItemVariant item={item} index={index} variantItemSelection={variantItemSelection} />
                                        </Box>
                                    )
                                })
                                }

                            </Box>
                            <Box style={{
                                marginTop: 40
                            }}>
                                {configs?.showPrimaryButton &&
                                    <AppButton onClick={() => itemAction(row)} style={{
                                        backgroundColor: configs?.primaryButtonBgColor,
                                        color: configs?.primaryButtonColor
                                    }} size='large' variant='contained' fullWidth>
                                        {configs?.primaryButtonTitle}
                                    </AppButton>
                                }

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {lightImages.length > 0 &&
                <FsLightbox
                    toggler={toggler}
                    sources={lightImages}
                />
            }

            <EnquiryFormDialog />
        </Box>
    );
}

export default ItemDetails;