import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    Box,
    IconButton,
    MenuItem,
    Tab,
    Tabs,
    Divider,
} from '@mui/material';
import { IoAnalyticsOutline, IoChatboxEllipsesOutline } from "react-icons/io5";
import Iconify from '../../compoments/iconify/Iconify';
import "./Analytics.css";
import { getAnalytics } from '../../../reduxStore/actions/StoreActions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { SITE_CONTACT } from '../../../constants/Site';

function Analytics(props) {

    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const allAnalytics = storeSelector.analytics;
    console.log("allAnalytics", allAnalytics);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const getAllAnalytics = async () => {
        try {
            const obj = JSON.stringify({
                store_id: storeId
            });
            const res = await dispatch(getAnalytics(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        getAllAnalytics();
    }, []);



    return (
        <>
            <Helmet>
                <title> Analytics | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Analytics
                    </Typography>
                    {/* <IconButton>
                        <Iconify icon={'ic:baseline-settings'} />
                    </IconButton> */}
                </Stack>
                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                                value={value} onChange={handleChange}>
                                <Tab icon={<IoAnalyticsOutline size={20} />} className="s-tabs" iconPosition="start" label="Traffic" {...a11yProps(0)} />
                                {/* <Tab icon={<IoChatboxEllipsesOutline size={20} />} className="s-tabs" iconPosition="start" label="Feedbacks" {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Stack direction="row" spacing={2} style={{
                                textAlign: 'center'
                            }} className='analytics-card-wrap'>
                                <Card className='a-card'>
                                    <Typography className='a-card-heading'>Total QR Scanned</Typography>
                                    <Box className='a-card-body'>
                                        <Typography className='a-card-count'>{allAnalytics?.qr_scanned_count}</Typography>
                                    </Box>
                                </Card>
                                
                                <Card className='a-card'>
                                    <Typography className='a-card-heading'>Recommended</Typography>
                                    <Box className='a-card-body'>
                                        <Typography className='a-card-count'>{allAnalytics?.recommended_count}</Typography>
                                    </Box>
                                </Card>

                                <Card className='a-card'>
                                    <Typography className='a-card-heading'>Total Store Visits</Typography>
                                    <Box className='a-card-body'>
                                        <Typography className='a-card-count'>{allAnalytics?.visited_count}</Typography>
                                    </Box>
                                </Card>

                                <Card className='a-card'>
                                    <Typography className='a-card-heading'>Enquiries</Typography>
                                    <Box className='a-card-body'>
                                        <Typography className='a-card-count'>{allAnalytics?.enquirie_count}</Typography>
                                    </Box>
                                </Card>

                                <Card className='a-card'>
                                    <Typography className='a-card-heading'>Reviews</Typography>
                                    <Box className='a-card-body'>
                                        <Typography className='a-card-count'>{allAnalytics?.reviewed_count}</Typography>
                                    </Box>
                                </Card>
                            </Stack>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Card>
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Feedback Info
                                        </Typography>
                                    </Stack>
                                    <Box>

                                        <Box style={{
                                            fontSize: 14
                                        }}>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Name: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>Rajendra Antarkar</span>
                                            </Box>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Mobile: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>{SITE_CONTACT}</span>
                                            </Box>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Suggestion: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>This is good to have and lot of services</span>
                                            </Box>
                                            <Typography className='f-posted-date'>02 Jun 2023</Typography>
                                        </Box>
                                        <Divider className='s-divider' />

                                        <Box style={{
                                            fontSize: 14
                                        }}>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Name: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>Rajendra Antarkar</span>
                                            </Box>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Mobile: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>{SITE_CONTACT}</span>
                                            </Box>
                                            <Box style={{
                                                marginBottom: 2
                                            }}>
                                                <span style={{
                                                    color: 'rgb(99, 115, 129)',
                                                    width: 80,
                                                    display: 'inline-block'
                                                }}>Suggestion: </span>
                                                <span style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    fontSize: 15
                                                }}>This is good to have and lot of services</span>
                                            </Box>
                                            <Typography className='f-posted-date'>02 Jun 2023</Typography>
                                        </Box>
                                        <Divider className='s-divider' />

                                    </Box>
                                </Box>
                            </Card>
                        </TabPanel>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default Analytics;