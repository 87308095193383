import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IMAGE_URL, NO_USER_IMAGE } from '../../constants/Images';
import Iconify from '../../portal/compoments/iconify/Iconify';
import { logout } from '../../reduxStore/actions/AuthActions';
import { LOGOUT } from '../../reduxStore/actions/AuthTypes';
import "./AccountSm.css";

function AccountSm(props) {
    const auth = useSelector((state) => state.auth);
    const userRole = auth?.userDetails?.user_role_code;
    const profileDetails = auth?.userDetails;
    const accessToken = auth?.userDetails?.access_token;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        setAnchorEl(null);
        switch (action) {
            case 'portal':
                if (userRole === 'owner') {
                    navigate('/portal/dashboard');
                } else if (userRole === 'agent') {
                    navigate('/agent/dashboard');
                } else if (userRole === 'super_admin') {
                    navigate('/admin/dashboard');
                }
                break;
            case 'profile':
                navigate('/user/profile');
                break;
            case 'bookings':
                navigate('/user/bookings');
                break;
            case 'logout':
                dispatch(logout());
                break;
            default:
                break;
        }

    };

    return (
        <>
            <IconButton id="acc-button"
                aria-controls={open ? 'acc-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                {!accessToken ?
                    <Iconify color="#000" icon={'carbon:user-avatar'} style={{
                        height: 25,
                        width: 25
                    }} />
                    :
                    <Avatar
                        variant="circular"
                        alt={profileDetails?.name}
                        src={profileDetails?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${profileDetails?.profile_pic}` : NO_USER_IMAGE}
                        className='v1-header-av'
                    />
                }
            </IconButton>
            <Menu
                id="acc-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(event) => handleClose(event, '')}
                MenuListProps={{
                    'aria-labelledby': 'acc-button',
                }}
            >

                {
                    accessToken && userRole !== 'user' &&
                    <MenuItem className='acc-menuitem acc-menuitem-portal' onClick={(event) => handleClose(event, 'portal')}>Go to portal</MenuItem>
                }
                {
                    accessToken &&
                    <MenuItem className='acc-menuitem' onClick={(event) => handleClose(event, 'profile')}>Profile</MenuItem>
                }
                {
                    // accessToken &&
                    // <MenuItem className='acc-menuitem' onClick={(event) => handleClose(event, 'bookings')}>Your Bookings</MenuItem>
                }
                {
                    accessToken &&
                    <MenuItem className='acc-menuitem' onClick={(event) => handleClose(event, 'logout')}>Logout</MenuItem>
                }
                {
                    !accessToken &&
                    <MenuItem className='acc-menuitem' onClick={(event) => handleClose(event, 'login')}>Login</MenuItem>
                }

            </Menu>
        </>
    );
}

export default AccountSm;