import React, { useRef, useState, useEffect } from 'react';
import {
    Card,
    Box,
    Stack,
    Container,
    Typography,
    IconButton,
    TextField,
    Button,
    Autocomplete,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
    FormGroup
} from '@mui/material';
import Iconify from '../../compoments/iconify/Iconify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import AppButton from '../../../components/app-button/AppButton';
import { addStore, getAllMasterCategories, updateStore } from '../../../reduxStore/actions/StoreActions';
import { IMAGE_URL } from '../../../constants/Images';
import { Helmet } from 'react-helmet';

function AddStore(props) {
    const auth = useSelector((state) => state.auth);
    const userRole = auth?.userDetails?.user_role_code;
    const storeSelector = useSelector((state) => state.store);
    const storeDetails = storeSelector?.details;
    const inputFile = useRef(null);
    const [file, setFile] = useState('');
    const [fileData, setFileData] = useState('');
    const [fileStatus, setFileStatus] = useState('');
    const [uid, setUid] = useState('');
    const [row, setRow] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [storeCategory, setStoreCategory] = useState({});
    const [name, setName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [contactPersonMobile, setContactPersonMobile] = useState('');
    const [address, setAddress] = useState('');
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [about, setAbout] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { state } = useLocation();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const [isCategoryRender, setIsCategoryRender] = useState(false);
    const [isOrder, setIsOrder] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [isEnquiry, setIsEnquiry] = useState(false);
    const [isBrand, setIsBrand] = useState(false);
    const [buttonType, setButtonType] = useState('enquiry');
    const themeConfig = {
        pageBackground: "#FFFFFF",
        themeBgColor: "#8BC34A",
        themeColor: "#FFFFFF",
        showOffers: true,
        offersHeading: "Today's Offers",
        offersBgColor: "#F78DA7",
        offersColor: "#FFFFFF",
        homeHeading: "Categories",
        showPrimaryButton: true,
        primaryButtonTitle: "Enquiry",
        primaryButtonBgColor: "#FF9800",
        primaryButtonColor: "#ffffff",
        isDenseMenu: false,
        showReviews: true
    }

    const backAction = userRole === 'agent' ? '/agent/stores' : '/admin/stores';

    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileData(e.target.files[0]);
        setFileStatus('removed');
    }
    const handleFileRemove = () => {
        setFile('');
        setFileData('');
        inputFile.current.value = "";
        setFileStatus('removed');
    }
    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('user_file', fileData);
            formData.append('form_data', JSON.stringify({
                name: name,
                contact_person_name: contactPerson,
                contact_no: contactPersonMobile,
                address: address,
                area: area,
                city: city,
                pincode: pincode,
                about: about,
                category_id: storeCategory?.uid,
                theme_config: JSON.stringify(themeConfig),
                is_booking: isBooking,
                is_order: isOrder,
                is_enquiry: isEnquiry,
                is_brand: isBrand
            }));
            const res = await dispatch(addStore(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate(backAction);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const handleUpdateClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            if (fileData) {
                formData.append('user_file', fileData);
            }
            formData.append('form_data', JSON.stringify({
                file_status: fileStatus ? fileStatus : 'no_changes',
                store_id: uid,
                name: name,
                contact_person_name: contactPerson,
                contact_no: contactPersonMobile,
                address: address,
                area: area,
                city: city,
                pincode: pincode,
                about: about,
                category_id: storeCategory?.uid,
                is_booking: isBooking,
                is_order: isOrder,
                is_enquiry: isEnquiry,
                is_brand: isBrand
            }));
            const res = await dispatch(updateStore(formData));
            setIsLoading(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            navigate(backAction);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const getCategories = async () => {
        try {
            const obj = JSON.stringify({
                page_number: 1,
                page_limit: 1
            });
            const res = await dispatch(getAllMasterCategories(obj));
            setCategories(res.data);
            setIsCategoryRender(true);
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleTypeChange = async (event) => {
        setButtonType(event.target.value);
        setIsEnquiry(false);
        setIsBooking(false);
        setIsOrder(false);
        if (event.target.value === 'booking') {
            setIsBooking(true);
            themeConfig.primaryButtonTitle = "Book";
        } else if (event.target.value === 'order') {
            setIsOrder(true);
            themeConfig.primaryButtonTitle = "Add";
        } else if (event.target.value === 'enquiry') {
            setIsEnquiry(true);
            themeConfig.primaryButtonTitle = "Enquiry";
        }
    }

    const setButtonValue = (state) => {
        if (state?.is_booking) {
            setButtonType('booking');
            themeConfig.primaryButtonTitle = "Book";
        } else if (state?.is_order) {
            setButtonType('order');
            themeConfig.primaryButtonTitle = "Add";
        } else if (state?.is_enquire) {
            setButtonType('enquiry');
            themeConfig.primaryButtonTitle = "Enquiry";
        }
    }

    const handleBrandChange = () => {
        const isBrandAvail = !isBrand;
        setIsBrand(isBrandAvail);
    }

    useEffect(() => {
        getCategories();
        setName(state?.store_name);
        setContactPerson(state?.contact_person_name);
        setContactPersonMobile(state?.contact_no);
        setAddress(state?.address);
        setArea(state?.area);
        setCity(state?.city);
        setPincode(state?.pincode);
        setAbout(state?.about);
        setFile(state?.logo_img_path);
        setCategoryId(state?.category_id);
        setUid(state?.uid);
        setIsEnquiry(state?.is_enquiry ? true : false);
        setIsBooking(state?.is_booking ? true : false);
        setIsOrder(state?.is_order ? true : false);
        setIsBrand(state?.is_brand ? true : false);
        setRow(state);
        setButtonValue(state);
    }, []);

    useEffect(() => {
        if (isCategoryRender && uid) {
            const cateArr = categories.find((category) => category.uid === categoryId);
            setStoreCategory(cateArr);
        }
    }, [isCategoryRender]);

    return (
        <>
            <Helmet>
                <title> Add Store | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        <Link style={{ textDecoration: 'none' }} to={backAction}><IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton></Link>
                        {uid ? "Edit Store" : "Add Store"}
                    </Typography>
                </Stack>

                <Box className='category-add-card'>
                    <Card className='category-add-card-wrap'>
                        <input
                            onChange={(event) => handleFileChange(event)}
                            accept="image/*"
                            type="file"
                            ref={inputFile}
                            style={{ display: 'none' }} />
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Store Image
                        </Typography>
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box className='c-image-wrap'>
                                    {!file
                                        ? <Link className='c-image' onClick={handleFileClick}>
                                            <Iconify sx={{
                                                width: 40
                                            }} icon="mdi:camera-plus" />
                                            <Typography style={{
                                                fontSize: 14
                                            }}>
                                                Upload image
                                            </Typography>
                                        </Link>
                                        :
                                        <>
                                            <img src={row?.logo_img_path ? `${IMAGE_URL}store/logo/thumbnail?id=${row?.uid}&img_id=${row?.logo_img_path}` : file}
                                                alt="img" style={{
                                                    height: "100%",
                                                    width: "100%"
                                                }} />
                                            <IconButton onClick={handleFileRemove} className='close-image-ico'><Iconify width={15} icon="material-symbols:close" /></IconButton>
                                        </>
                                    }
                                </Box>
                                <Typography className='c-image-caption'>
                                    Allowed *.jpeg, *.jpg, *.png
                                    max size of 1 MB
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card className='category-add-card-wrap'>
                        <Typography style={{
                            fontSize: 14,
                            color: '#000',
                            marginBottom: 30
                        }}>
                            Store Info
                        </Typography>
                        <Stack spacing={3}>
                            <Autocomplete
                                value={storeCategory}
                                options={categories}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderInput={(params) => <TextField fullWidth {...params} label="Store Category *" />}
                                onChange={(event, newInputValue) => {
                                    setStoreCategory(newInputValue);
                                }}
                            />
                            <TextField
                                name="store_name"
                                label="Store Name *"
                                value={name}
                                onChange={(event) => setName(event.target.value)} />
                            <TextField
                                name="contact_person_name"
                                label="Contact Person Name *"
                                value={contactPerson}
                                onChange={(event) => setContactPerson(event.target.value)}
                            />
                            <TextField
                                name="contact_person_mobile"
                                label="Contact Person Mobile *"
                                value={contactPersonMobile}
                                onChange={(event) => setContactPersonMobile(event.target.value)}
                            />
                            <TextField
                                name="store_addess"
                                label="Store Address *"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                            />
                            <TextField
                                name="store_area"
                                label="Store Area *"
                                value={area}
                                onChange={(event) => setArea(event.target.value)}
                            />
                            <TextField
                                name="store_city"
                                label="Store City *"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                            />
                            <TextField
                                name="store_pincode"
                                label="Store Pincode *"
                                value={pincode}
                                onChange={(event) => setPincode(event.target.value)}
                            />
                            <TextField
                                name="store_about"
                                label="About Store(Optional)"
                                value={about}
                                multiline rows={2}
                                onChange={(event) => setAbout(event.target.value)}
                            />
                            <FormControl>
                                <FormLabel>Button Type</FormLabel>
                                <RadioGroup
                                    defaultValue={buttonType}
                                    name="button-type"
                                    value={buttonType}
                                    onChange={handleTypeChange}
                                >
                                    <FormControlLabel value="enquiry" control={<Radio />} label="Enquiry" />
                                    <FormControlLabel value="booking" control={<Radio />} label="Booking" />
                                    <FormControlLabel value="order" control={<Radio />} label="Order" />
                                </RadioGroup>
                            </FormControl>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={isBrand}
                                        onChange={handleBrandChange} />} label="Brand" /> {isBrand}
                                </FormGroup>
                            </Box>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={uid ? handleUpdateClick : handleSaveClick}
                                fullWidth size='large'
                                variant="contained">
                                {uid ? "Update" : "Save"}
                            </AppButton>
                        </Stack>
                    </Card>
                </Box>
            </Container >
        </>
    );
}

export default AddStore;