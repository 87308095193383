import React, { useState } from 'react';
import {
    Box,
    Card,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    Button,
    TextField,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreDetails, updateStoreByField } from '../../../reduxStore/actions/StoreActions';
import FaqList from '../../compoments/faq-list/FaqList';
import Iconify from '../../compoments/iconify/Iconify';
import AppButton from '../../../components/app-button/AppButton';
import AppDialog from '../../../components/app-dialog/AppDialog';
import { cloneDeep, isArray } from 'lodash';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';

function Faq(props) {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const [faqDialogOpen, setFaqDialogOpen] = useState(false);
    const details = storeSelector.details;
    const [isLoading, setIsLoading] = useState(false);
    const [faqQuestion, setFaqQuestion] = useState('');
    const [faqAnswer, setFaqAnswer] = useState('');
    const [open, setOpen] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({});
    const allFaqs = details?.faqs ? JSON.parse(details?.faqs) : [];
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    console.log("allFaqs", allFaqs);
    const handleDialogCancel = () => {
        setFaqDialogOpen(false);
        setSelectedRecord({});
        setFaqQuestion('');
        setFaqAnswer('');
    }

    //Faq
    const handleFaqClick = async () => {
        setFaqDialogOpen(true);
    };

    const saveFaqClick = async () => {
        if (faqQuestion === "") {
            enqueueSnackbar("Question can not be empty.", { variant: 'error' });
            return;
        }
        if (faqAnswer === "") {
            enqueueSnackbar("Answer can not be empty.", { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            const addFaqs = cloneDeep(allFaqs);
            addFaqs.push({
                id: new Date().valueOf(),
                question: faqQuestion,
                answer: faqAnswer,
                sequence: 1
            })
            console.log("addFaqs", addFaqs);
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'faq',
                name: JSON.stringify(addFaqs),
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const updateFaqClick = async () => {
        if (faqQuestion === "") {
            enqueueSnackbar("Question can not be empty.", { variant: 'error' });
            return;
        }
        if (faqAnswer === "") {
            enqueueSnackbar("Answer can not be empty.", { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            const addFaqs = allFaqs?.map((item) => {
                return item.id === selectedRecord.id ?
                    { ...item, question: faqQuestion, answer: faqAnswer } : item;
            })
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'faq',
                name: JSON.stringify(addFaqs),
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const editFaqClick = async () => {
        setFaqQuestion(selectedRecord?.question);
        setFaqAnswer(selectedRecord?.answer);
        setFaqDialogOpen(true);
    };

    const deleteFaqClick = async () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        try {
            let formData = new FormData();
            const addFaqs = allFaqs?.filter((row) => row.id !== selectedRecord.id);
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'faq',
                name: JSON.stringify(addFaqs),
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            setSelectedRecord({});
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };


    return (
        <>
            <Card>
                <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: 20,
                    paddingRight: 20
                }}>
                    <AppButton
                        onClick={handleFaqClick}
                        size='medium'
                        variant="contained">
                        Add FAQ
                    </AppButton>
                </Box>
                <Box style={{
                    padding: '0 10px'
                }}>

                    {allFaqs?.length > 0 && allFaqs.map((row) => {
                        return (
                            <Box
                                key={row?.id}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Box style={{
                                    flex: 1
                                }}>
                                    <FaqList row={row} />
                                </Box>
                                <Box>
                                    <IconButton size="medium" color="inherit" style={{
                                        marginLeft: 'auto',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                        onClick={(event) => { handleOpenMenu(event); setSelectedRecord(row) }}>
                                        <Iconify icon={'eva:more-vertical-fill'} />
                                    </IconButton>
                                </Box>
                            </Box>
                        )
                    })}

                    {allFaqs?.length === 0 &&
                        <Box style={{
                            padding: '15px 15px 25px 15px',
                            fontSize: 14
                        }}>
                            No FAQs added.
                        </Box>
                    }

                </Box>
            </Card>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); editFaqClick() }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem sx={{ color: 'error.main' }} onClick={() => { handleCloseMenu(); deleteFaqClick() }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>


            {faqDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={faqDialogOpen}
                    id={'faq_dialog_'}
                    title={<>{selectedRecord?.id ? "Edit FAQ" : "Add FAQ"}</>}
                    footer={<>
                        <Box style={{
                            display: 'flex',
                            gap: 15,
                        }}>
                            <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth size='medium'
                                onClick={selectedRecord?.id ? updateFaqClick : saveFaqClick}
                                variant="contained">
                                {selectedRecord?.id ? 'Update' : 'Save'}
                            </AppButton>
                        </Box>
                    </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Question*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="faq_question"
                                value={faqQuestion}
                                onChange={(event) => setFaqQuestion(event.target.value)}
                            />

                            <TextField
                                multiline rows={3}
                                autoFocus
                                label="Answer*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="faq_answer"
                                value={faqAnswer}
                                onChange={(event) => setFaqAnswer(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_faq_dialog'} title={<>Delete Faq</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }

        </>
    );
}

export default Faq;