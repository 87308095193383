import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Stack,
    Button,
    Container,
    Typography,
    Tab,
    Tabs,
    Box,
    Card,
    Divider,
    TextField,
    IconButton,
    ListItemButton,
    ListItemText,
    List,
    Collapse,
    MenuItem,
    Popover
} from '@mui/material';
import {
    IoReaderOutline,
    IoShareSocialOutline,
    IoCallOutline,
    IoHelpCircleOutline,
    IoQrCodeOutline,
    IoColorPaletteOutline
} from "react-icons/io5";
import "./Settings.css";
import Iconify from '../../compoments/iconify/Iconify';
import { useDispatch, useSelector } from 'react-redux';
import AppDialog from '../../../components/app-dialog/AppDialog';
import AppButton from '../../../components/app-button/AppButton';
import { IMAGE_URL } from '../../../constants/Images';
import { useSnackbar } from 'notistack';
import {
    getStoreDetails,
    updateStoreAddress,
    updateStoreByField
} from '../../../reduxStore/actions/StoreActions';
import Faq from './Faq';
import SocialLinks from './SocialLinks';
import ContactInfo from './ContactInfo';
import { Helmet } from 'react-helmet';
import QrConfig from './QrConfig';
import ThemeConfig from './ThemeConfig';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_CLOUD_KEY } from '../../../constants/Site';

function Settings(props) {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const details = storeSelector.details;
    const [isLoading, setIsLoading] = useState(false);
    const [editBusinessDialog, setEditBusinessDialog] = useState(false);
    const [editBusinessAddressDialog, setEditBusinessAddressDialog] = useState(false);
    const [editBusinessAboutDialog, setEditBusinessAboutDialog] = useState(false);
    const [editBusinessTimingDialog, setEditBusinessTimingDialog] = useState(false);
    const [editBusinessLogoDialog, setEditBusinessLogoDialog] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [businessAbout, setBusinessAbout] = useState('');
    const [address, setAddress] = useState('');
    const [landmark, setLandmark] = useState('');
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [value, setValue] = React.useState(0);
    const inputFile = useRef(null);
    const [file, setFile] = useState('');
    const [fileData, setFileData] = useState('');
    const [fileStatus, setFileStatus] = useState('');
    const [businessLogo, setBusinessLogo] = useState('');
    const editorRef = useRef(null);
    //const storeTiming = details?.store_timing ? JSON.parse(details?.store_timing) : [];

    const storeTiming = [
        {
            day: "Monday",
            isClosed: false,
            isOpen24: false,
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' },
            { open_time: '03:30 pm', close_time: '05:45 pm' }]
        },
        {
            day: "Tuesday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        },
        {
            day: "Wednesday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        },
        {
            day: "Thursday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        },
        {
            day: "Friday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        },
        {
            day: "Saturday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        },
        {
            day: "Sunday",
            isClosed: '',
            isOpen24: '',
            timing: [{ open_time: '12:30 am', close_time: '01:45 pm' }]
        }
    ];

    const handleFileClick = () => {
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileData(e.target.files[0]);
        setFileStatus('removed');
        setBusinessLogo('');
    }

    const handleFileRemove = () => {
        setFile('');
        setFileData('');
        inputFile.current.value = "";
        setFileStatus('removed');
    }

    const handleDialogCancel = () => {
        setEditBusinessDialog(false);
        setEditBusinessAddressDialog(false);
        setEditBusinessAboutDialog(false);
        setEditBusinessLogoDialog(false);
        setEditBusinessTimingDialog(false);
    }

    //For tab
    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    //Business name
    const handleEditBusinessName = () => {
        setEditBusinessDialog(true);
        setBusinessName(details?.store_name);
    };

    const updateBusinessNameClick = async () => {
        if (businessName === "") {
            enqueueSnackbar('Please enter business name.', { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'name',
                name: businessName,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Business Address
    const handleEditBusinessAddress = async () => {
        setEditBusinessAddressDialog(true);
        setAddress(details?.address);
        setLandmark(details?.landmark);
        setArea(details?.area);
        setCity(details?.city);
        setPincode(details?.pincode);
    };

    const updateBusinessAddressClick = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                address: address,
                landmark: landmark,
                area: area,
                city: city,
                pincode: pincode
            });
            const res = await dispatch(updateStoreAddress(obj));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Business About
    const handleEditBusinessAbout = async () => {
        setBusinessAbout(details?.about);
        setEditBusinessAboutDialog(true);
    };

    const updateBusinessAboutClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            let businessAbout = "";
            if (editorRef.current) {
                businessAbout = editorRef.current.getContent();
            }
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'about',
                name: businessAbout,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Business Timing
    const handleTimingBusiness = async () => {
        setEditBusinessTimingDialog(true);
    };

    const updateBusinessTimingClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'about',
                about: businessAbout,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Business Logo
    const handleBusinessLogo = async () => {
        setBusinessLogo(details?.logo);
        setFile(details?.logo);
        setEditBusinessLogoDialog(true);
    };

    const updateBusinessLogoClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            if (fileData) {
                formData.append('user_file', fileData);
            }
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'img',
                name: fileStatus,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
    }, []);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="tab-setting">
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const dialogEditBusinessAboutFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={updateBusinessAboutClick}
                    variant="contained">
                    Save
                </AppButton>
            </Box>
        </>
    )

    const dialogEditBusinessFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={updateBusinessNameClick}
                    variant="contained">
                    Save
                </AppButton>
            </Box>
        </>
    )

    const dialogEditAddressFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={updateBusinessAddressClick}
                    variant="contained">
                    Save
                </AppButton>
            </Box>
        </>
    )

    const dialogEditTimingFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={updateBusinessTimingClick}
                    variant="contained">
                    Save
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <title> Settings | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Settings
                    </Typography>
                </Stack>
                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                                value={value} onChange={handleChange}>
                                <Tab icon={<IoReaderOutline size={20} />} className="s-tabs" iconPosition="start" label="About" {...a11yProps(0)} />
                                <Tab icon={<IoCallOutline size={20} />} className="s-tabs" iconPosition="start" label="Contact Info" {...a11yProps(1)} />
                                <Tab icon={<IoShareSocialOutline size={20} />} className="s-tabs" iconPosition="start" label="Social Links" {...a11yProps(2)} />
                                <Tab icon={<IoHelpCircleOutline size={20} />} className="s-tabs" iconPosition="start" label="FAQs" {...a11yProps(3)} />
                                <Tab icon={<IoQrCodeOutline size={20} />} className="s-tabs" iconPosition="start" label="QR Configuration" {...a11yProps(4)} />
                                <Tab icon={<IoColorPaletteOutline size={20} />} className="s-tabs" iconPosition="start" label="Theme Configuration" {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Card>
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Business Name
                                        </Typography>
                                        <Button
                                            onClick={handleEditBusinessName}
                                            size="small"
                                            variant="text"
                                            startIcon={<Iconify icon="eva:edit-fill" />}
                                            style={{
                                                color: 'rgb(0, 171, 85)'
                                            }}>
                                            Edit
                                        </Button>
                                    </Stack>
                                    <Box>
                                        <Typography style={{
                                            fontSize: 18,
                                            fontWeight: 700
                                        }}>
                                            {details?.store_name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider className='s-divider' />
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Location
                                        </Typography>
                                        <Button
                                            size="small"
                                            variant="text"
                                            startIcon={<Iconify icon="eva:edit-fill" />}
                                            onClick={handleEditBusinessAddress}
                                            style={{
                                                color: 'rgb(0, 171, 85)'
                                            }}>
                                            Edit
                                        </Button>
                                    </Stack>
                                    <Box>
                                        {true ?
                                            <Box>
                                                <Typography style={{
                                                    fontSize: 14
                                                }}>
                                                    {details?.address}, {details?.area}, {details?.city} {details?.pincode}
                                                </Typography>
                                                <Typography style={{
                                                    fontSize: 13,
                                                    color: '#6a5e5e'
                                                }}>
                                                    {details?.landmark ? `Landmark: ${details?.landmark}` : ''}
                                                </Typography>
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleEditBusinessAddress}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:plus-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Add Address
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                                <Divider className='s-divider' />
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            About
                                        </Typography>
                                        {details?.about &&
                                            <Button
                                                onClick={handleEditBusinessAbout}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:edit-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Edit
                                            </Button>
                                        }
                                    </Stack>
                                    <Box>
                                        {details?.about ?
                                            <Box style={{
                                                fontSize: 14
                                            }} dangerouslySetInnerHTML={{ __html: details?.about }}>
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleEditBusinessAbout}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:plus-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Add About
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                                <Divider className='s-divider' />
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Business Logo
                                        </Typography>
                                        {details?.logo &&
                                            <Button
                                                onClick={handleBusinessLogo}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:edit-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Edit
                                            </Button>
                                        }
                                    </Stack>
                                    <Box>
                                        <Box>
                                            {details?.logo ?
                                                <Box sx={{ mb: 2 }}>
                                                    <Box className='c-image-wrap c-item-image-wrap'>
                                                        <img src={details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${details.logo}` : file}
                                                            alt="img"
                                                            style={{
                                                                height: "100%",
                                                                width: "100%"
                                                            }} />
                                                    </Box>
                                                </Box>
                                                :
                                                <Button
                                                    onClick={handleBusinessLogo}
                                                    size="small"
                                                    variant="text"
                                                    startIcon={<Iconify icon="eva:plus-fill" />}
                                                    style={{
                                                        color: 'rgb(0, 171, 85)'
                                                    }}>
                                                    Add Logo
                                                </Button>
                                            }
                                        </Box>
                                    </Box>
                                </Box>

                                {/* <Divider className='s-divider' />
                                <Box style={{ padding: 20 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                                        <Typography gutterBottom style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase',
                                            color: ' rgb(99, 115, 129)',
                                            fontWeight: 700
                                        }}>
                                            Business Timings
                                        </Typography>
                                        {storeTiming.length > 0 &&
                                            <Button
                                                onClick={handleTimingBusiness}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Edit
                                            </Button>
                                        }
                                    </Stack>
                                    <Box>
                                        {storeTiming.length > 0 ?
                                            <Box>
                                                {storeTiming && storeTiming.map((row) => {
                                                    return (
                                                        <Stack
                                                            key={row.day}
                                                            direction="row"
                                                            spacing={3}
                                                            style={{
                                                                fontSize: 14,
                                                                display: 'flex',
                                                                gap: 10,
                                                                marginBottom: 10
                                                            }}>
                                                            <Box style={{
                                                                width: 70
                                                            }}>{row.day}</Box>
                                                            <Box style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 5
                                                            }}>

                                                                {row.isClosed && !row.isOpen24 && <Box>Closed</Box>}
                                                                {!row.isClosed && row.isOpen24 && <Box>Open 24 hours</Box>}
                                                                {!row.isClosed && !row.isOpen24 && row.timing && (row.timing.length > 0) && row.timing.map((time, index) => {
                                                                    return (
                                                                        <Box key={`time${index}`} style={{
                                                                            display: 'flex',
                                                                            gap: 25
                                                                        }}>
                                                                            <Box>{time.open_time}</Box>
                                                                            <Box>{time.close_time}</Box>
                                                                        </Box>
                                                                    )
                                                                })
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    )
                                                })}
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleTimingBusiness}
                                                size="small"
                                                variant="text"
                                                startIcon={<Iconify icon="eva:plus-fill" />}
                                                style={{
                                                    color: 'rgb(0, 171, 85)'
                                                }}>
                                                Add Business Timings
                                            </Button>
                                        }
                                    </Box>
                                </Box> */}

                            </Card>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ContactInfo />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <SocialLinks />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Faq />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <QrConfig />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <ThemeConfig />
                        </TabPanel>
                    </Box>
                </Box>

            </Container>

            {editBusinessDialog &&
                <AppDialog maxWidth='xs' isOpen={editBusinessDialog} id={'edit_business_name_dialog'} title={<>{"Business Name"}</>}
                    footer={dialogEditBusinessFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Business Name*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="businessName"
                                value={businessName}
                                onChange={(event) => setBusinessName(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {editBusinessAddressDialog &&
                <AppDialog maxWidth='xs'
                    isOpen={editBusinessAddressDialog}
                    id={'edit_business_address_dialog'}
                    title={<>{"Business Address"}</>}
                    footer={dialogEditAddressFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                multiline rows={2}
                                autoFocus
                                label="Address*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="address"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                            />
                            <TextField
                                autoFocus
                                label="Landmark (Optional)"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="landmark"
                                value={landmark}
                                onChange={(event) => setLandmark(event.target.value)}
                            />
                            <TextField
                                autoFocus
                                label="Area*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="area"
                                value={area}
                                onChange={(event) => setArea(event.target.value)}
                            />
                            <TextField
                                autoFocus
                                label="City*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="city"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                            />
                            <TextField
                                autoFocus
                                label="Pincode*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="pincode"
                                value={pincode}
                                onChange={(event) => setPincode(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {editBusinessAboutDialog &&
                <AppDialog maxWidth='xs'
                    isOpen={editBusinessAboutDialog}
                    id={'edit_business_about_dialog'}
                    title={<>{"About"}</>}
                    footer={dialogEditBusinessAboutFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey={TINY_CLOUD_KEY}
                                initialValue={businessAbout}
                                init={{
                                    placeholder: "Business About",
                                    branding: false,
                                    height: 300,
                                    plugins: 'link image code lists table',
                                    toolbar: 'backcolor forecolor | fontselect | lists formatselect | ' +
                                        'bold italic | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent '
                                }}
                            />

                            {/* <TextField
                                multiline rows={4}
                                autoFocus
                                label="Business About"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="businessAbout"
                                value={businessAbout}
                                onChange={(event) => setBusinessAbout(event.target.value)}
                            /> */}
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {editBusinessTimingDialog &&
                <AppDialog maxWidth='xs'
                    isOpen={editBusinessTimingDialog}
                    id={'edit_business_timing_dialog'}
                    title={<>{"Business Timing"}</>}
                    footer={dialogEditTimingFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>

                        </Stack>
                    </Box>
                </AppDialog>
            }
            {editBusinessLogoDialog &&
                <AppDialog maxWidth='xs'
                    isOpen={editBusinessLogoDialog}
                    id={'edit_business_logo_dialog'}
                    title={<>{"Business Logo"}</>}
                    footer={
                        <>
                            <Box style={{
                                display: 'flex',
                                gap: 15,
                            }}>
                                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                                <AppButton
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    fullWidth size='medium'
                                    onClick={updateBusinessLogoClick}
                                    variant="contained">
                                    Save
                                </AppButton>
                            </Box>
                        </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <Box className='c-image-wrap c-item-image-wrap'>
                                <input onChange={(event) => handleFileChange(event)} accept="image/*" type="file" ref={inputFile} style={{ display: 'none' }} />
                                {!file
                                    ?
                                    <Box className='c-image c-item-image' onClick={handleFileClick}>
                                        <Iconify sx={{
                                            width: 40
                                        }} icon="mdi:camera-plus" />
                                        <Typography style={{
                                            fontSize: 12
                                        }}>
                                            Upload image
                                        </Typography>
                                    </Box>
                                    :
                                    <>
                                        <img src={businessLogo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${businessLogo}` : file}
                                            alt="img"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }} />
                                        <IconButton onClick={handleFileRemove} className='close-image-ico'>
                                            <Iconify width={15} icon="material-symbols:close" />
                                        </IconButton>
                                    </>
                                }
                            </Box>
                            <Typography className='c-image-caption'>
                                Allowed *.jpeg, *.jpg, *.png
                                max size of 1 MB
                            </Typography>
                        </Stack>
                    </Box>
                </AppDialog>
            }

        </>
    );
}

export default Settings;