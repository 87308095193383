import React, { useEffect, useState } from 'react';
import {
    Stack,
    Button,
    Container,
    Typography,
    Card,
    Box,
    IconButton
} from '@mui/material';
import Review from '../../../components/review/Review';
import Ratings from '../../../components/ratings/Ratings';
import { IoFilterOutline, IoMenu, IoStar } from 'react-icons/io5';
import "./Reviews.css";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { filterStoreReview, getAllReviews } from '../../../reduxStore/actions/StoreActions';
import ReviewFilters from '../../compoments/review-filters/ReviewFilters';
import { Helmet } from 'react-helmet';

function Reviews(props) {

    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const allReviews = storeSelector.reviews;
    const [activeFilter, setActiveFilter] = useState('all');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const getReviews = async () => {
        try {
            const obj = JSON.stringify({
                store_id: storeId
            });
            const res = await dispatch(getAllReviews(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    //Review Filters
    const filterReviews = async (value) => {
        console.log("value", value);
        setActiveFilter(value);
        let filterParams;
        switch (value) {
            case 'all':
                filterParams = "5,4,3,2,1";
                break;
            case 'positive':
                filterParams = "5,4";
                break;
            case 'critical':
                filterParams = "1,2";
                break;
            case '5':
                filterParams = "5";
                break;
            case '4':
                filterParams = "4";
                break;
            case '3':
                filterParams = "3";
                break;
            case '2':
                filterParams = "2";
                break;
            case '1':
                filterParams = "1";
                break;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                filter_para: filterParams
            });
            const res = await dispatch(filterStoreReview(obj));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }


    useEffect(() => {
        getReviews();
    }, []);


    return (
        <>
            <Helmet>
                <title> Reviews | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Reviews
                    </Typography>
                </Stack>
                <Card>
                    <Box style={{
                        padding: 20
                    }}>
                        <Ratings />
                        <ReviewFilters activeFilter={activeFilter} filterReviews={filterReviews} />
                        {allReviews?.reviews?.map((review) => {
                            return (
                                <Review
                                    review={review}
                                    key={review.uid}
                                />
                            )
                        })}
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default Reviews;