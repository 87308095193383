import { Box, Button, Rating, Stack } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { IoStar } from 'react-icons/io5';
import { useSelector } from 'react-redux';

function ReviewFilters({activeFilter, filterReviews}) {
    const storeSelector = useSelector((state) => state.store);

    return (
        <>
            <Stack direction='row' justifyContent="space-between"
                alignItems="center">
                <Box className='re-fil-type'>
                    <ul>
                        <li className={activeFilter === 'all' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('all')}
                            >
                                All
                            </Button>
                        </li>
                        <li className={activeFilter === 'positive' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('positive')}
                            >
                                Positive
                            </Button>
                        </li>
                        <li className={activeFilter === 'critical' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('critical')}
                            >
                                Critical
                            </Button>
                        </li>
                        <li className={activeFilter === '5' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('5')}
                            >
                                5 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '4' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('4')}
                            >
                                4 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '3' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('3')}
                            >
                                3 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '2' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('2')}
                            >
                                2 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '1' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('1')}
                            >
                                1 <IoStar />
                            </Button>
                        </li>
                    </ul>
                </Box>
                {/*                             
                            <IconButton>
                                <IoFilterOutline size={18} color='#000' />
                            </IconButton> */}
            </Stack>
        </>
    );
}

export default ReviewFilters;