import React, { useState, useEffect } from 'react';
import {
    Stack,
    Container,
    Typography,
    IconButton,
    Card,
    Box,
    MenuItem,
    Popover,
    Button,
    Autocomplete,
    TextField
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Iconify from '../../compoments/iconify/Iconify';
import "./Photos.css";
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deleteAlbumPhoto, moveAlbumPhoto } from '../../../reduxStore/actions/StoreActions';
import AppButton from '../../../components/app-button/AppButton';
import AppDialog from '../../../components/app-dialog/AppDialog';
import { IMAGE_URL } from '../../../constants/Images';
import { Helmet } from 'react-helmet';

function ViewPhotos(props) {
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const allLabels = storeSelector.labels;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { state } = useLocation();
    const [open, setOpen] = useState(null);
    const [row, setRow] = useState({});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [labelMoveDialogOpen, setLabelMoveDialogOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [label, setLabel] = useState({});
    const [newLabel, setNewLabel] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);


    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleDialogClickClose = () => {
        setLabelMoveDialogOpen(false);
    };

    const handleDialogCancel = () => {
        setLabelMoveDialogOpen(false);
    }


    const deleteActionClick = async () => {
        setIsDeleteDialogOpen(true);
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        let formData = new FormData();
        const deletedPhotos = [];
        formData.append('form_data', JSON.stringify(
            {
                store_id: storeId,
                label_id: row?.uid,
                uid: selectedRecord?.uid,
            }
        ));
        deletedPhotos.push(selectedRecord?.uid);
        formData.append('deleted_files', JSON.stringify(deletedPhotos));
        try {
            const res = await dispatch(deleteAlbumPhoto(formData));
            const allPhotos = images.filter(item => item.uid !== selectedRecord?.uid);
            setImages(allPhotos);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleMoveSaveClick = async () => {
        setIsLoading(true);
        const obj = JSON.stringify(
            {
                store_id: storeId,
                targeted_label_id: newLabel?.uid,
                uid: selectedRecord?.uid,
            }
        );
        try {
            const res = await dispatch(moveAlbumPhoto(obj));
            setLabelMoveDialogOpen(false);
            const allPhotos = images.filter(item => item.uid !== selectedRecord?.uid);
            setImages(allPhotos);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setIsLoading(false);
        } catch (err) {
            if (err?.errors) {
                setIsLoading(false);
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const handleMoveTo = async () => {
        const labelsArr = allLabels.find((label) => label.uid === row.uid);
        setLabel(labelsArr);
        setLabelMoveDialogOpen(true);
    }


    useEffect(() => {
        setRow(state);
        setImages(state?.images);
    }, []);

    useEffect(() => {
        if (allLabels) {
            const labelsArr = allLabels.find((label) => label.uid === row.uid);
            setLabel(labelsArr);
        }
    }, [allLabels]);

    const dialogMovePhotoFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={handleDialogCancel}
                >
                    Cancel
                </AppButton>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={handleMoveSaveClick}
                    variant="contained">
                    Move
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <title> View Photos | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        <Link style={{ textDecoration: 'none' }} to="/portal/store/photos"><IconButton>
                            <Iconify width="25" icon="eva:arrow-back-outline" /></IconButton></Link>
                        {row?.label} <span style={{
                            fontSize: 14,
                            fontWeight: 500
                        }}>({images?.length > 0 ? `${images.length} Photos` : '0 Photos'})</span>
                    </Typography>
                </Stack>
                <Card style={{
                    padding: 20
                }}>
                    <Stack className='p-container'>
                        {images?.length > 0 && images.map((item) => {
                            return (<Box
                                key={item?.uid}
                                className='p-wrap'>
                                <img src={`${IMAGE_URL}/label/thumbnail?id=${storeId}&img_id=${item?.image_path}`} />
                                <IconButton style={{
                                    position: 'absolute',
                                    top: 5,
                                    backgroundColor: 'rgba(0, 0, 0, .3)',
                                    color: '#fff',
                                    right: 5
                                }} size="medium" color="inherit" onClick={(event) => { handleOpenMenu(event); setSelectedRecord(item) }}>
                                    <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                            </Box>)
                        })}
                    </Stack>

                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); handleMoveTo() }}>
                    <Iconify icon={'ic:outline-move-up'} sx={{ mr: 2 }} />
                    Move to
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenu(); deleteActionClick() }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_photo_dialog'} title={<>Delete Photo</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }

            {labelMoveDialogOpen &&
                <AppDialog
                    maxWidth='xs'
                    isOpen={labelMoveDialogOpen}
                    id={'store_move_photo_sm'}
                    title={<>Move Photo</>}
                    footer={dialogMovePhotoFooter}
                    handleClickClose={() => { handleDialogClickClose() }}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <Autocomplete
                                disabled
                                value={label}
                                options={allLabels}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                renderInput={(params) => <TextField fullWidth {...params} label="Current Label *" />}
                                onChange={(event, newInputValue) => {
                                    setLabel(newInputValue);
                                }}
                            />
                            <Autocomplete
                                value={newLabel}
                                options={allLabels}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                renderInput={(params) => <TextField fullWidth {...params} label="New Label *" />}
                                onChange={(event, newInputValue) => {
                                    setNewLabel(newInputValue);
                                }}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default ViewPhotos;