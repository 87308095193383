import React from 'react';
import { Helmet } from 'react-helmet';

function Profile(props) {
    return (
        <Helmet>
            <title> Profile | TrueMenu</title>
        </Helmet>
    );
}

export default Profile;