import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Iconify from '../../compoments/iconify/Iconify';
import { IMAGE_URL } from '../../../constants/Images';
import { useSelector } from 'react-redux';

function ItemTableMetaV1({ variant, selectVariantImages, index, removeVariantImage, removeSelectedImage, deleteVariantRowClick }) {
    const attributeArr = [];
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const uploadedImages = variant?.uploaded_images;
    const selectedImages = variant?.selected_images;
    console.log("ffff", variant);
    variant?.attributes?.map((attribute, i) => {
        attributeArr.push(attribute.value);
    });
    return (
        <>
            <Box>
                <Box className="item-variant-header-new">
                    <Typography style={{
                        fontWeight: 600,
                        fontSize: 15,
                        color: '#000'
                    }}> {attributeArr?.join(' | ')}
                    </Typography>
                    <Box>
                        <IconButton onClick={(event) => { deleteVariantRowClick(event, variant, index) }} size="large" color="inherit">
                            <Iconify icon={'ph:trash'} />
                        </IconButton>
                    </Box>
                </Box>
                <Box style={{
                    paddingBottom: 20
                }}>
                    <Divider></Divider>
                </Box>
            </Box>
            <Box>
                <Box className="new-item-variant-body">
                    <Box className="new-item-choose-variant-image new-item-img">
                        <Link className='new-item-choose-img' onClick={(event) => selectVariantImages(event, variant, index)}>
                            <Iconify sx={{
                                width: 40
                            }} icon="mdi:camera-plus" />
                        </Link>
                    </Box>
                    {uploadedImages?.length > 0 && uploadedImages.map((image, i) => {
                        return (
                            <Box className="new-item-img">
                                <img
                                    src={`${IMAGE_URL}store/image?img_id=${image?.file_path}`}
                                    loading="lazy"
                                />
                                <Box>
                                    <IconButton className='item-uploaded-overlay-close-btn' onClick={(event) => removeVariantImage(event, variant, index, i)}>
                                        <Iconify sx={{
                                            width: 15,
                                            color: '#000'
                                        }} icon="material-symbols:close" />
                                    </IconButton>
                                </Box>
                            </Box>
                        )
                    })
                    }
                    {selectedImages?.length > 0 && selectedImages.map((image, i) => {
                        return (
                            <Box className="new-item-img">
                                <img
                                    src={`${IMAGE_URL}item/thumbnail?id=${storeId}&img_id=${image.file_path}`}
                                    loading="lazy"
                                />
                                <Box>
                                    <IconButton className='item-uploaded-overlay-close-btn' onClick={(event) => removeSelectedImage(event, image, index, i)}>
                                        <Iconify sx={{
                                            width: 15,
                                            color: '#000'
                                        }} icon="material-symbols:close" />
                                    </IconButton>
                                </Box>
                            </Box>
                        )
                    })
                    }
                </Box>
            </Box>
        </>
    );
}

export default ItemTableMetaV1;