import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppDialogDelete from '../../../components/app-dialog-delete/AppDialogDelete';
import AppTable from '../../compoments/app-table/AppTable';
import {
    Stack,
    Button,
    Container,
    Typography,
    Box,
    TextField
} from '@mui/material';
import { Helmet } from 'react-helmet';
import Iconify from '../../compoments/iconify/Iconify';
import AppButton from '../../../components/app-button/AppButton';
import { addUnit, getUnits, unitAction, unitDelete, updateUnit } from '../../../reduxStore/actions/StoreActions';
import AppDialog from '../../../components/app-dialog/AppDialog';

function Units(props) {

    const tableHead = [
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'created_at', label: 'Created At', alignRight: false },
        { id: '' },
    ];

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const storeSelector = useSelector((state) => state.store);
    const authSelector = useSelector((state) => state.auth);
    const userRole = authSelector?.userDetails?.user_role_code;
    const storeId = authSelector?.userDetails?.primary_store_id;
    const tableRows = storeSelector.units;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [unitDialogOpen, setUnitDialogOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [unit, setUnit] = useState('');

    const getAllUnits = async () => {
        try {
            const obj = {
                page_number: 1,
                page_limit: 10

            }
            const res = await dispatch(getUnits(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }
    const toggleStatus = async (event, row) => {
        const obj = {
            uid: parseInt(row.id),
            record_status: event.target.value === 'A' ? 'D' : 'A'
        }
        try {
            const res = await dispatch(unitAction(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const saveUnitClick = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                name: unit
            });
            const res = await dispatch(addUnit(obj));
            setIsLoading(false);
            setUnitDialogOpen(false);
            getAllUnits();
            setUnit("");
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const editActionClick = async (event, row) => {
        setUnitDialogOpen(true);
        setIsUpdate(true);
        setSelectedRecord(row);
        setUnit(row?.name);
    }

    const unitUpdate = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                uid: selectedRecord?.id,
                name: unit,
            });
            const res = await dispatch(updateUnit(obj));
            setIsLoading(false);
            setIsUpdate(false);
            setUnitDialogOpen(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setSelectedRecord({});
            setUnit("");
            getAllUnits();
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const deleteActionClick = async (event, row) => {
        setIsDeleteDialogOpen(true);
        setSelectedRecord(row);
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = {
            uid: parseInt(selectedRecord.id),
            record_status: 'R'
        }
        try {
            const res = await dispatch(unitDelete(obj));
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }

    }

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const newUnitClick = () => {
        setUnitDialogOpen(true);
    }

    const handleDialogCancel = () => {
        setUnitDialogOpen(false);
        setIsUpdate(false);
        setSelectedRecord({});
        setUnit('');
    }


    useEffect(() => {
        getAllUnits();
    }, []);

    const dialogUnitFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                <AppButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    fullWidth size='medium'
                    onClick={isUpdate ? unitUpdate : saveUnitClick}
                    variant="contained">
                    {isUpdate ? 'Update' : 'Save'}
                </AppButton>
            </Box>
        </>
    )

    return (
        <>
            <Helmet>
                <title> Units | TrueMenu</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5" gutterBottom>
                        Units
                    </Typography>
                    <AppButton onClick={newUnitClick} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                        New Unit
                    </AppButton>
                </Stack>
                <Box>
                    <AppTable
                        tableHead={tableHead}
                        tableRows={tableRows}
                        listType='units'
                        searchPlaceholder="Search Unit ..."
                        toggleSwitchStatus={toggleStatus}
                        editActionClick={editActionClick}
                        deleteActionClick={deleteActionClick}
                        storeId={storeId}
                        searchField={'name'}
                    />
                </Box>

            </Container>

            {unitDialogOpen &&
                <AppDialog maxWidth='xs' isOpen={unitDialogOpen} id={'store_unit_sm'} title={<>{isUpdate ? "Edit Unit" : "Add Unit"}</>}
                    footer={dialogUnitFooter}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField label="Unit*"
                                fullWidth
                                placeholder='Unit'
                                autoComplete='off'
                                name="unit"
                                value={unit}
                                onChange={(event) => setUnit(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_store_dialog'} title={<>Delete Unit</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete record?</Typography>
                </AppDialogDelete>
            }
        </>
    );
}

export default Units;