import React, { useState, useRef, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import QRCodeStyling from "qr-code-styling";
import "./Qr.css";
import { useDispatch, useSelector } from 'react-redux';
import { QR_CODE_IMAGE, WEB_DOMAIN } from '../../constants/Images';
import { QR_DOWNLOAD, QR_HEADER_DOWNLOAD } from '../../reduxStore/actions/site/StoreActionTypes';
import { APP_QR_DOWNLOAD } from '../../reduxStore/actions/StoreActionTypes';

function Qr({ size = 220, style }) {
    const storeSelector = useSelector((state) => state.store);
    const site = storeSelector?.details;
    console.log("site", site);
    const qrDownload = storeSelector.isAppQrDownload;
    const dispatch = useDispatch();
    const qrCode = new QRCodeStyling({
        width: size,
        height: size,
        image: QR_CODE_IMAGE,
        dotsOptions: {
            color: "#603100",
            type: "extra-rounded"
        },
        cornersDotOptions: {
            color: "#000",
            type: "extra-rounded"
        },
        cornersSquareOptions: {
            color: "#000",
            type: "extra-rounded"
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 10
        }
    });

    let url = `${WEB_DOMAIN}${site?.url}?qr`;

    if (site?.is_customer_portal === 1) {
        url = `${site?.portal_link}?qr`;
    }

    const [fileExt, setFileExt] = useState("jpeg");
    const ref = useRef(null);

    const onExtensionChange = (event) => {
        setFileExt(event.target.value);
    };

    const onDownloadClick = () => {
        if (qrDownload) {
            qrCode.download({
                extension: fileExt
            });
            dispatch({
                type: APP_QR_DOWNLOAD,
                payload: false
            });
        }
    };

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
        onDownloadClick();
    }, [qrDownload]);

    return (
        <div className='qr-code-comp'>
            <div ref={ref} />
            {/* <QRCodeCanvas
                id="qr_code"
                value={"http://truemenu.in/sss"}
                size={size}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                style={style}
                includeMargin={false}
                imageSettings={{
                    src: "https://static.zpao.com/favicon.png",
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    excavate: true,
                }}
            /> */}
        </div>
    );
}

export default Qr;