import React, { useState } from 'react';
import {
    Box,
    Card,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    Button,
    TextField,
    Typography,
    Divider
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreDetails, updateStoreByField } from '../../../reduxStore/actions/StoreActions';
import FaqList from '../../compoments/faq-list/FaqList';
import Iconify from '../../compoments/iconify/Iconify';
import AppButton from '../../../components/app-button/AppButton';
import AppDialog from '../../../components/app-dialog/AppDialog';

function ContactInfo(props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const storeId = authSelector?.userDetails?.primary_store_id;
    const storeSelector = useSelector((state) => state.store);
    const [contactDialogOpen, setContactDialogOpen] = useState(false);
    const [whatsappDialogOpen, setWhatsappDialogOpen] = useState(false);
    const [websiteDialogOpen, setWebsiteDialogOpen] = useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const details = storeSelector.details;
    const [isLoading, setIsLoading] = useState(false);
    const [contactNumber, setContactNumber] = useState('');
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [selectedRecord, setSelectedRecord] = useState({});

    function validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleDialogCancel = () => {
        setContactDialogOpen(false);
        setWhatsappDialogOpen(false);
        setWebsiteDialogOpen(false);
        setEmailDialogOpen(false);
    }

    //Contact
    const handleContactClick = () => {
        setContactNumber(details?.contact_no);
        setContactDialogOpen(true);
    };

    const updateContactNumberClick = async () => {
        if (contactNumber === "") {
            enqueueSnackbar('Please enter contact number.', { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'contact',
                name: contactNumber,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Whatsapp
    const handleWhatsAppClick = () => {
        setWhatsAppNumber(details?.whatsapp_no);
        setWhatsappDialogOpen(true);
    };

    const updateWhatsAppNumberClick = async () => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'whatapp',
                name: whatsAppNumber,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Email
    const handleEmailClick = () => {
        setEmail(details?.email);
        setEmailDialogOpen(true);
    };

    const updateEmailClick = async () => {
        if (email) {
            if (!validateEmail(email)) {
                enqueueSnackbar('Email is not valid.', { variant: 'error' });
                return;
            }
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'email',
                name: email,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    //Website
    const handleWebsiteClick = () => {
        setWebsite(details?.website_url);
        setWebsiteDialogOpen(true);
    };

    const updateWebsiteClick = async () => {
        if (website) {
            if (!validateUrl(website)) {
                enqueueSnackbar('Website url is not valid.', { variant: 'error' });
                return;
            }
        }
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('form_data', JSON.stringify({
                store_id: storeId,
                field_name: 'website',
                name: website,
            }));
            const res = await dispatch(updateStoreByField(formData));
            await dispatch(getStoreDetails(storeId));
            setIsLoading(false);
            handleDialogCancel();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    return (
        <>
            <Card>
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Login Number
                        </Typography>
                        {/* <Button size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                            color: 'rgb(0, 171, 85)'
                        }}>
                            Edit
                        </Button> */}
                    </Stack>
                    <Box>
                        <Typography style={{
                            fontSize: 18,
                            fontWeight: 700
                        }}>
                            +91-{details?.contact_no}
                        </Typography>
                    </Box>
                </Box>
                <Divider className='s-divider' />

                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Contact Number
                        </Typography>
                        <Button onClick={handleContactClick} size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                            color: 'rgb(0, 171, 85)'
                        }}>
                            Edit
                        </Button>
                    </Stack>
                    <Box>
                        <Typography style={{
                            fontSize: 18,
                            fontWeight: 700
                        }}>
                            +91-{details?.contact_no}
                        </Typography>
                    </Box>
                </Box>
                <Divider className='s-divider' />
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            WhatsApp Number
                        </Typography>
                        {details?.whatsapp_no &&
                            <Button onClick={handleWhatsAppClick} size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Edit
                            </Button>
                        }
                    </Stack>
                    <Box>
                        {details?.whatsapp_no ?
                            <Typography style={{
                                fontSize: 18,
                                fontWeight: 700
                            }}>
                                +91-{details?.whatsapp_no}
                            </Typography>
                            :
                            <Button onClick={handleWhatsAppClick} size="small" variant="text" startIcon={<Iconify icon="eva:plus-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Add WhatsApp Number
                            </Button>
                        }
                    </Box>
                </Box>
                <Divider className='s-divider' />
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Email
                        </Typography>
                        {details?.email &&
                            <Button onClick={handleEmailClick} size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Edit
                            </Button>
                        }
                    </Stack>
                    <Box>
                        {details?.email ?
                            <Typography style={{
                                fontSize: 18,
                                fontWeight: 700
                            }}>
                                {details?.email}
                            </Typography>
                            :
                            <Button onClick={handleEmailClick} size="small" variant="text" startIcon={<Iconify icon="eva:plus-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Add Email Address
                            </Button>
                        }
                    </Box>
                </Box>
                <Divider className='s-divider' />
                <Box style={{ padding: 20 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography gutterBottom style={{
                            fontSize: 12,
                            textTransform: 'uppercase',
                            color: ' rgb(99, 115, 129)',
                            fontWeight: 700
                        }}>
                            Website
                        </Typography>
                        {details?.website_url &&
                            <Button onClick={handleWebsiteClick} size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Edit
                            </Button>
                        }
                    </Stack>
                    <Box>
                        {details?.website_url ?
                            <Typography style={{
                                fontSize: 18,
                                fontWeight: 700
                            }}>
                                <a style={{
                                    textDecoration: 'none',
                                    color: '#000'
                                }} href={details?.website_url} target="_blank">{details?.website_url}</a>
                            </Typography>
                            :
                            <Button onClick={handleWebsiteClick} size="small" variant="text" startIcon={<Iconify icon="eva:plus-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Add Website
                            </Button>
                        }
                    </Box>
                </Box>
            </Card>

            {contactDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={contactDialogOpen}
                    id={'contact_number_dialog'}
                    title={<>{"Contact Number"}</>}
                    footer={<>
                        <Box style={{
                            display: 'flex',
                            gap: 15,
                        }}>
                            <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth size='medium'
                                onClick={updateContactNumberClick}
                                variant="contained">
                                Save
                            </AppButton>
                        </Box>
                    </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Contact Number*"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="contact_number"
                                value={contactNumber}
                                onChange={(event) => setContactNumber(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {whatsappDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={whatsappDialogOpen}
                    id={'whats_app_dialog'}
                    title={<>{"WhatsApp Number"}</>}
                    footer={<>
                        <Box style={{
                            display: 'flex',
                            gap: 15,
                        }}>
                            <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth size='medium'
                                onClick={updateWhatsAppNumberClick}
                                variant="contained">
                                Save
                            </AppButton>
                        </Box>
                    </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="WhatsApp Number"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="whatsapp_number"
                                value={whatsAppNumber}
                                onChange={(event) => setWhatsAppNumber(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {emailDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={emailDialogOpen}
                    id={'email_dialog'}
                    title={<>{"Email"}</>}
                    footer={<>
                        <Box style={{
                            display: 'flex',
                            gap: 15,
                        }}>
                            <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth size='medium'
                                onClick={updateEmailClick}
                                variant="contained">
                                Save
                            </AppButton>
                        </Box>
                    </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                autoFocus
                                label="Email"
                                fullWidth
                                placeholder=''
                                autoComplete='off'
                                name="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }

            {websiteDialogOpen &&
                <AppDialog maxWidth='xs'
                    isOpen={websiteDialogOpen}
                    id={'website_dialog'}
                    title={<>{"Website"}</>}
                    footer={<>
                        <Box style={{
                            display: 'flex',
                            gap: 15,
                        }}>
                            <Button size='medium' onClick={handleDialogCancel}>Cancel</Button>
                            <AppButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth size='medium'
                                onClick={updateWebsiteClick}
                                variant="contained">
                                Save
                            </AppButton>
                        </Box>
                    </>}
                >
                    <Box>
                        <Stack spacing={3} style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                            <TextField
                                placeholder="Ex. https://www.my-website.com"
                                autoFocus
                                label="Website"
                                fullWidth
                                autoComplete='off'
                                name="website"
                                value={website}
                                onChange={(event) => setWebsite(event.target.value)}
                            />
                        </Stack>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default ContactInfo;