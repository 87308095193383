import { Box, Dialog, DialogContent, Drawer, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountSm from '../../../components/account-sm/AccountSm';
import QrSm from '../../../components/qr-sm/QrSm';
import Iconify from '../../../portal/compoments/iconify/Iconify';
import Sidebar from '../sidebar/Sidebar';
import "./HeaderSm.css";

function HeaderSm(props) {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [smDrawer, setSmDrawer] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSmDrawer(!smDrawer);
    };

    const listItemClick = (event, index, page) => {
        setSelectedIndex(index);
        navigate(page);
        setSmDrawer(!smDrawer);
    };

    return (
        <>
            <Box className='store-header-sm-wrap'>
                <Box className='store-header-sm-content'>
                    <Box className='store-header-sm-left'>
                        <IconButton onClick={toggleDrawer(smDrawer)} >
                            <Iconify color="#000" icon={'bx:menu-alt-right'} style={{
                                height: 25,
                                width: 25
                            }} />
                        </IconButton>
                        <Typography className='store-header-sm-name'>Avantara Spa & Services</Typography>
                    </Box>
                    <Box className='store-header-sm-right'>
                        <QrSm />
                        <AccountSm />
                    </Box>

                </Box>
            </Box>

            <Drawer
                anchor='left'
                variant="temporary"
                sx={{
                    width: 280,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 280,
                        boxSizing: 'border-box',
                    },
                }}
                open={smDrawer}
                onClose={toggleDrawer(smDrawer)}
            >
                <Sidebar selectedIndex={selectedIndex} handleListItemClick={listItemClick} />
            </Drawer>

        </>
    );
}

export default HeaderSm;