import { Box, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { PRIVACY } from '../../constants/Site';
import { getWebConfig } from '../../reduxStore/actions/StoreActions';
import "./Privacy.css";

function Privacy(props) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const storeSelector = useSelector((state) => state.store);
    const configData = storeSelector.siteConfig ? storeSelector.siteConfig : [];
    const [privacy, setPrivacy] = useState("");

    const getAllConfig = async () => {
        try {
            const obj = { store_id: 0 }
            const res = await dispatch(getWebConfig(obj));
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        getAllConfig();
    }, []);

    useEffect(() => {
        if (configData.length > 0) {
            configData?.forEach(element => {
                if (element.key_name === PRIVACY) {
                    setPrivacy(element);
                }
            });
        }
    }, [configData]);

    return (
        <>
            <Helmet>
                <title> Privacy Policy | TrueMenu</title>
            </Helmet>
            <Box className='privacy-page'>
                <Box className='privacy-container'>
                    {/* <Typography className='privacy-heading'>
                        Privacy Policy
                    </Typography> */}
                    <Box>
                        <Box>
                            <Box dangerouslySetInnerHTML={{ __html: privacy?.value_data }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Privacy;