import React from 'react';
import "./NotFound.css";
import { Box, IconButton, Typography } from '@mui/material';
import Iconify from '../../components/iconify/Iconify';
import AppButton from '../../components/app-button/AppButton';
import { useNavigate } from 'react-router-dom';
import { SITE_CONTACT, WHATSAPP_URL } from '../../../constants/Site';

function NotFound(props) {
    const navigate = useNavigate();
    const needHelp = () => {
        window.open(`${WHATSAPP_URL}?phone=${SITE_CONTACT}&text=Hi`, '_blank');
    }
    const goToHome = () => {
        navigate('/');
    }

    return (
        <>
            <Box className="not-found-wrap">
                <Box className="not-found-header">
                    <Box className='not-need-help'>
                        <Box onClick={needHelp} className="not-need-help-wrap">
                            <Iconify width="25" icon="simple-icons:whatsapp" />
                            <Box>Need help?</Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='not-found-body'>
                    <Typography className='title'>Sorry, Page Not Found!</Typography>
                    <Box>
                        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.
                    </Box>
                    <Box>
                        <img className='not-found-img' src={'/404-error.svg'} />
                    </Box>
                    <Box style={{ marginTop: 10 }}><AppButton onClick={goToHome} variant='contained'>Go to Home</AppButton></Box>
                </Box>
            </Box>
        </>
    );
}

export default NotFound;