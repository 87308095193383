import { Box, Card, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import "./UserBookings.css";

function UserBookings(props) {
    return (
        <>
            <Helmet>
                <title> Bookings | TrueMenu</title>
            </Helmet>
            <Box className='user-booking-wrap'>
                <Box>
                    <Container>
                        <Box className='user-booking-container'>
                            <Typography className='user-booking-heading'>Your Bookings</Typography>
                            <Card>
                                <Box className='user-booking-one'>
                                    <Box className='user-booking-one-head'>
                                        <Box className='user-booking-flex'>
                                            <Typography className='user-booking-title'>Booking ID: </Typography>
                                            <Typography className='bold-user-booking'>xs098784</Typography>
                                        </Box>
                                        <Box className='user-booking-flex'>
                                            <Typography className='user-booking-title'>Booking Status: </Typography>
                                            <Typography className='bold-user-booking user-booking-status'>Confirmed</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='user-booking-one-body'>
                                        <Box>
                                            <Typography className='user-booking-title'>Item Name:</Typography>
                                            <Box className='user-booking-one-body-d'>
                                                <Typography className='f-bold'>Dip Tisse</Typography>
                                                <Typography className='f-13'>60 min</Typography>
                                                <Typography className='f-13' style={{
                                                    color: 'rgb(245, 124, 0)'
                                                }}>Avantar Spa</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography className='user-booking-title'>Booking Date & Time</Typography>
                                            <Typography className='user-booking-one-body-d f-14'>12 jun 1989 02:30pm</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className='user-booking-title'>Booking Amount</Typography>
                                            <Typography className='user-booking-one-body-d f-14'>Rs .500</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Typography style={{
                                            fontSize: 12,
                                            color: '#000'
                                        }}>Booked On: 12 Jun 2009</Typography>
                                    </Box>
                                </Box>
                                <Divider className='booking-div' />

                                <Box className='user-booking-one'>
                                    <Box className='user-booking-one-head'>
                                        <Box className='user-booking-flex'>
                                            <Typography className='user-booking-title'>Booking ID: </Typography>
                                            <Typography className='bold-user-booking'>xs098784</Typography>
                                        </Box>
                                        <Box className='user-booking-flex'>
                                            <Typography className='user-booking-title'>Booking Status: </Typography>
                                            <Typography className='bold-user-booking user-booking-status'>Confirmed</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='user-booking-one-body'>
                                        <Box>
                                            <Typography className='user-booking-title'>Item Name:</Typography>
                                            <Box className='user-booking-one-body-d'>
                                                <Typography className='f-bold'>Dip Tisse</Typography>
                                                <Typography className='f-13'>60 min</Typography>
                                                <Typography className='f-13' style={{
                                                    color: 'rgb(245, 124, 0)'
                                                }}>Avantar Spa</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography className='user-booking-title'>Booking Date & Time</Typography>
                                            <Typography className='user-booking-one-body-d f-14'>12 jun 1989 02:30pm</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className='user-booking-title'>Booking Amount</Typography>
                                            <Typography className='user-booking-one-body-d f-14'>Rs .500</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Typography style={{
                                            fontSize: 12,
                                            color: '#000'
                                        }}>Booked On: 12 Jun 2009</Typography>
                                    </Box>
                                </Box>

                            </Card>
                        </Box>
                    </Container>
                </Box>

            </Box>
        </>
    );
}

export default UserBookings;