import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Collapse } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import NavCollapse from '../nav-collapse/NavCollapse';
import { useSelector } from 'react-redux';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

export function NavItem({ item }) {
  const storeSelector = useSelector((state) => state.store);
  const storeDetails = storeSelector?.details;

  const { title, path, icon, info } = item;

  if (title === 'bookings' && (storeDetails && !storeDetails?.is_booking)) {
    return;
  }
  if (title === 'orders' && (storeDetails && !storeDetails?.is_order)) {
    return;
  }
  if (title === 'brands' && (storeDetails && !storeDetails?.is_brand)) {
    return;
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          color: 'rgb(0, 171, 85)',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
          backgroundColor: 'rgba(0, 171, 85, 0.08)',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
