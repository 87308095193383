import React from 'react';
import { Helmet } from 'react-helmet';

function Bookings(props) {
    return (
        <>
            <Helmet>
                <title> Bookings | TrueMenu</title>
            </Helmet>
        </>
    );
}

export default Bookings;