import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getUserSession } from '../../reduxStore/actions/site/AuthActions';
import { LOGGEDIN_USER_DETAILS, LOGOUT } from '../../reduxStore/actions/site/AuthTypes';
import { getStoreData } from '../../reduxStore/actions/site/StoreActions';
import { APP_LOADING, CONFIG } from '../../reduxStore/actions/site/StoreActionTypes';
import AppSmTabs from '../components/app-sm-tabs/AppSmTabs';
import EnquiryFormDialog from '../components/enquiry-form-dialog/EnquiryFormDialog';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SignIn from '../components/sign-in/SignIn';
import "./SiteLayout.css";
import Loader from '../components/loader/Loader';
import { WEB_DOMAIN } from '../constants/Images';
import NotFound from '../pages/not-found/NotFound';

function SiteLayout(props) {
    const authSelector = useSelector((state) => state.sAuth);
    const loginDialog = authSelector.isLoginDialog;
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { store_url } = useParams();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);
    const [portalLink, setPortalLink] = React.useState('');
    //Call store api
    const getStore = async () => {
        if (store_url) {
            try {
                dispatch({
                    type: APP_LOADING,
                    payload: true
                });
                await dispatch(getStoreData(store_url));
                setIsLoaded(true);
                dispatch({
                    type: APP_LOADING,
                    payload: false
                });
            } catch (err) {
                dispatch({
                    type: APP_LOADING,
                    payload: false
                });
                if (err?.errors) {
                    enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
                }
            }
        } else {
            //enqueueSnackbar('Store config is missing', { variant: 'error' });
        }
    }

    const loggedInDetails = async () => {
        const userDetails = await getUserSession();
        if (!userDetails) {
            dispatch({
                type: LOGOUT
            });
            return;
        }
        const userSession = JSON.parse(userDetails);
        dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: userSession
        });
    }

    useEffect(() => {
        loggedInDetails();
    }, []);

    useEffect(() => {
        if (store_url && !site?.store_details?.url) {
            getStore();
        }
    }, [store_url]);

    useEffect(() => {
        if (site.constructor === Object && Object.keys(site).length === 0) {
            setIsValid(false);
        } else {
            setIsValid(true);
            setPortalLink(site?.store_details?.portal_link);
        }
    }, [site]);

    return (
        <div>
            {isLoaded && !isValid ?
                <NotFound />
                :
                <>
                    <Header />
                    {isLoaded && portalLink !== '' ?
                        <>
                            <Outlet />
                            <Box className='footer-site'>
                                <Footer />
                            </Box>
                            <AppSmTabs />
                        </>
                        :
                        <>
                            <Outlet />
                            <Box className='footer-site'>
                                <Footer />
                            </Box>
                            <AppSmTabs />
                        </>
                    }
                    {loginDialog &&
                        <SignIn />
                    }
                    <EnquiryFormDialog />
                    <Loader />
                </>
            }
        </div>
    );
}

export default SiteLayout;