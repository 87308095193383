import { Box, Typography } from '@mui/material';
import React from 'react';
import "./BottomSheet.css";

function BottomSheet({ isShow, closeBottomSheet, children }) {
    if (isShow) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }
    return (
        <>
            <Box className={`bottom-sheet-wrap ${isShow ? 'bottom-sheet-wrap-active' : ''}`} onClick={closeBottomSheet}>
                <Box className='bottom-sheet-container'>
                    <Box className='bottom-sheet-inner' onClick={(event) => event.stopPropagation()}>
                        {children}
                    </Box>
                </Box>
            </Box>

        </>
    );
}

export default BottomSheet;